import { SharedService } from 'src/app/services/shared.service';
import { KeyValue } from '../../../app/model/KeyValue';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-multi-select-dropwdown-prime',
  templateUrl: './multi-select-dropwdown-prime.component.html',
  styleUrls: ['./multi-select-dropwdown-prime.component.scss']
})
export class MultiSelectDropwdownPrimeComponent implements OnInit{
  @Input()   clientFilterModel : KeyValue[];
  @Input() sourceData: any;
  @Input() optionLable: any;
  @Input() optionValue: any;
  @Input() optionHeader: any;
  selectedValue: any = null;
  @Output() onItemValueChange = new EventEmitter<{source: string, value: number }>(); 
  selectAll = false;
  dynamicData: any = {};
  dynamicDataValue: any = {};
  selectAllSelected: boolean = false;
  @Input() resetValue: string;
  constructor(private sharedService: SharedService
  
  ){
    this.sharedService.subject.subscribe((data) => {
     // console.log('shared service',data);
     if (data == "reset"){
      this.selectedValue = [];
     }
  });
  }
  ngOnInit(){
    
  }

  onItemChange(item: any){
 
    if (this.checkSelectAllClicked(item)){
      this.SelectDeselectAll(item);
    }
    else{
      this.getSelectedValue(item); 
      const data = { source: this.sourceData, value: this.selectedValue };
      this.onItemValueChange.emit(data);
    }
   
  }
  
  checkSelectAllClicked(item: any){
    var selectClicked = false;
   if (this.sourceData == 'clientName'){
         if (item.itemValue.key == '-1' ){
           selectClicked = true;
       }
    }
    if (this.sourceData == 'industry'){
       if (item.itemValue.industryTypeId == '-1' ){
         selectClicked = true;
     }
    }
    if (this.sourceData == 'clientStatus'){
       if (item.itemValue.clientStatusId == '-1' ){
         selectClicked = true;
       }
      }
    if (this.sourceData == 'clientProgram'){
       if (item.itemValue.clientProgramId == '-1' ){
           selectClicked = true;
       }
    }
    if (this.sourceData == 'structEntity'){
       if (item.itemValue.structEntityId == '-1' ){
         selectClicked = true;
       }
    }
    return selectClicked;
  }

   SelectDeselectAll(item: any){
    if (this.sourceData == 'clientName'){
      if (item.itemValue.key == '-1' ){
        var indexCheck = item.value.indexOf('-1');
        if (indexCheck > -1){
          this.SelectAllValue();
        }else{
          this.DeselactAllValue();
        }
      }
    }
    if (this.sourceData == 'industry'){
      if (item.itemValue.industryTypeId == -1 ){
        var indexCheck = item.value.indexOf(-1);
        if (indexCheck > -1){
          this.SelectAllValue();
        }else{
          this.DeselactAllValue();
        }
      }
    }
    if (this.sourceData == 'clientStatus'){
      if (item.itemValue.clientStatusId == -1 ){
        var indexCheck = item.value.indexOf(-1);
        if (indexCheck > -1){
          this.SelectAllValue();
        }else{
          this.DeselactAllValue();
        }
      }
    }
    if (this.sourceData == 'clientProgram'){
      if (item.itemValue.clientProgramId == -1 ){
        var indexCheck = item.value.indexOf(-1);
        if (indexCheck > -1){
          this.SelectAllValue();
        }else{
          this.DeselactAllValue();
        }
      }
    }
    if (this.sourceData == 'structEntity'){
      if (item.itemValue.structEntityId == -1 ) {
        var indexCheck = item.value.indexOf(-1);
        if (indexCheck > -1){
          this.SelectAllValue();
        }else{
          this.DeselactAllValue();
        }
      }
    }
    
   }

   SelectAllValue(){
        if (this.sourceData == 'clientName'){
          this.selectedValue = this.clientFilterModel.map(x => x.key);
          const data = { source: this.sourceData, value: this.selectedValue };
          this.onItemValueChange.emit(data);
          }
          if (this.sourceData == 'industry'){
            this.dynamicData = this.clientFilterModel;
            this.selectedValue = this.dynamicData.map(x => x.industryTypeId);
            const data = { source: this.sourceData, value: this.selectedValue };
            this.onItemValueChange.emit(data);
          }
          if (this.sourceData == 'clientStatus'){
            this.dynamicData = this.clientFilterModel;
            this.selectedValue = this.dynamicData.map(x => x.clientStatusId);
            const data = { source: this.sourceData, value: this.selectedValue };
            this.onItemValueChange.emit(data);
          }
          if (this.sourceData == 'clientProgram'){
            this.dynamicData = this.clientFilterModel;
            this.selectedValue = this.dynamicData.map(x => x.clientProgramId);
            const data = { source: this.sourceData, value: this.selectedValue };
            this.onItemValueChange.emit(data);
          }
          if (this.sourceData == 'structEntity'){
            this.dynamicData = this.clientFilterModel;
            this.selectedValue = this.dynamicData.map(x => x.structEntityId);
            const data = { source: this.sourceData, value: this.selectedValue };
          this.onItemValueChange.emit(data);
          }
   }

   DeselactAllValue(){
      this.selectedValue = [];
      const data = { source: this.sourceData, value: this.selectedValue };
      this.onItemValueChange.emit(data);
   }

   getSelectedValue(item: any){
    if (this.sourceData == 'clientName'){
      var indexInserOrDelete = item.value.indexOf(item.itemValue.key);
      if (indexInserOrDelete > -1){
        this.insertIntoExistingData(item);
      }
      else{
        this.deleteIntoExistingData(item);
      }
    }
    if (this.sourceData == 'industry'){
      var indexInserOrDelete = item.value.indexOf(item.itemValue.industryTypeId);
      if (indexInserOrDelete > -1){
        this.insertIntoExistingData(item);
      }
      else{
        this.deleteIntoExistingData(item);
      }
    }
    if (this.sourceData == 'clientStatus'){
      var indexInserOrDelete = item.value.indexOf(item.itemValue.clientStatusId);
      if (indexInserOrDelete > -1){
        this.insertIntoExistingData(item);
      }
      else{
        this.deleteIntoExistingData(item);
      }
    }
    if (this.sourceData == 'clientProgram'){
      var indexInserOrDelete = item.value.indexOf(item.itemValue.clientProgramId);
      if (indexInserOrDelete > -1){
        this.insertIntoExistingData(item);
      }
      else{
        this.deleteIntoExistingData(item);
      }
    }
    if (this.sourceData == 'structEntity'){
      var indexInserOrDelete = item.value.indexOf(item.itemValue.structEntityId);
      if (indexInserOrDelete > -1){
        this.insertIntoExistingData(item);
      }
      else{
        this.deleteIntoExistingData(item);
      }
    }
  }

  insertIntoExistingData(item){ 
    if (this.sourceData == 'clientName'){
    var indexCheck = item.value.indexOf('-1');
    if (indexCheck > -1){
      if (item.value.length == this.clientFilterModel.length){
        this.selectedValue = this.clientFilterModel.map(x => x.key);
       }
    }else{
      if (item.value.length == this.clientFilterModel.length-1){
         this.selectedValue = this.clientFilterModel.map(x => x.key);
       }else{
      this.selectedValue = item.value;
       }
    }
  }
     else{
        var indexCheck = item.value.indexOf(-1);
        if (indexCheck > -1){
          if (item.value.length == this.clientFilterModel.length){
            this.valueAssignwithMapped();
           }
        }else{
          if (item.value.length == this.clientFilterModel.length-1){
            this.valueAssignwithMapped();
           }else{
          this.selectedValue = item.value;
           }
        }
     }

  }

  deleteIntoExistingData(item){
    if (this.sourceData == 'clientName'){
      var indexCheck = item.value.indexOf('-1');
      if (indexCheck > -1){
        this.selectedValue.splice(indexCheck,1);
      }
    }else{
      var indexCheck = item.value.indexOf(-1);
      if (indexCheck > -1){
        this.selectedValue.splice(indexCheck,1);
      }
    }
  
  }

  valueAssignwithMapped(){
    this.dynamicData = {};
    if (this.sourceData == 'industry'){
      this.dynamicData = this.clientFilterModel;
      this.selectedValue = this.dynamicData.map(x => x.industryTypeId);
    }
    if (this.sourceData == 'clientStatus'){
      this.dynamicData = this.clientFilterModel;
      this.selectedValue = this.dynamicData.map(x => x.clientStatusId);
    }
    if (this.sourceData == 'clientProgram'){
      this.dynamicData = this.clientFilterModel;
      this.selectedValue = this.dynamicData.map(x => x.clientProgramId);
    }
    if (this.sourceData == 'structEntity'){
      this.dynamicData = this.clientFilterModel;
      this.selectedValue = this.dynamicData.map(x => x.structEntityId);
    }
  }
}
