<app-loading-screen></app-loading-screen>
<div class="row pageShortcutParent" *ngIf="iShortcutCount > 6">
  <div class="col-md-12">
    <app-loading-screen></app-loading-screen>
    <app-shortcut></app-shortcut>
  </div>
</div>

<div class="row pb-3 mx-0 mt-3" [class.pageTitleParentExtraMargin]="iShortcutCount > 6"
  [class.pageTitleParent]="iShortcutCount <= 6">
  <div class="col-md-10 pl-0">
    <h2 class="pageTitle largerFontSize2">Continuance</h2>
  </div>
  <div class="col-md-2 pr-0" *ngIf="iShortcutCount <= 6">
    <app-shortcut></app-shortcut>
  </div>
</div>

<div class="bg-edp-body card-container position-relative">
  <!-- List View main container start -->
  <!--ListView Design Start-->
  <div class="Eng-ListView-Div">
    <div class="d-flex p-2">
      <div class="mr-auto ml-1">
        <div class="d-flex flex-row bd-highlight">
          <a id="filterContinuanceListView" class="anchoroutline filter-tab"
             (click)="ShowHideListFilterPanel('Show')" data-placement="bottom" target="_blank">
            Filter
          </a>
          <a id="showAllContinuanceListView" class="anchoroutline showall-tab"
            (click)="ShowAllContinuanceWithNoFilters()" data-placement="bottom" target="_blank">
            Show all
          </a>
        </div>
      </div>
    </div>
    <!-- Accordian view start -->
    <section id="listViewFilterSection" class="filter-panel" style="display: none;">
      <div class="row m-0 bg-edp-body">
        <div class="col-md-12">
          <div class="row m-0 pb-4">
            <div class="col-lg-4 col-md-4 filter-partition">
              <label class="font-weight-bold mb-1">Client name</label>
              <div class="selectdiv">
                <select id="ddListClients" [(ngModel)]="continuanceListViewFilterModel.clientId"
                  class="dropdown rounded-0 form-control" name="ddListClients">
                  <option value="0" disabled hidden>Select client</option>
                  <option *ngFor="let clients of clientFilterModel" [value]="clients.key">
                    {{clients.value}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 filter-partition">
              <label class="font-weight-bold mb-1">Engagement PPMD</label>
              <div class="selectdiv ">
                <select id="ddListEngagementPartner" [(ngModel)]="continuanceListViewFilterModel.engagementPartner"
                  class="dropdown rounded-0 form-control" name="ddListEngagementPartner">
                  <option value="" disabled hidden>Select Engagement PPMD</option>
                  <option *ngFor="let engagementPartner of engagementPartnerList"
                    [value]="engagementPartner.engagementPartner">
                    {{engagementPartner.engagementPartnerName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-4 col-md-4">
              <label class="font-weight-bold mb-1">Primary solution code</label>
              <div class="selectdiv ">
                <select id="ddListPrimarySolutionCode" [(ngModel)]="continuanceListViewFilterModel.solutionCodeId"
                  class="dropdown rounded-0 form-control" name="ddListPrimarySolutionCode">
                  <option value="0" disabled hidden>Select Primary solution code</option>
                  <option *ngFor="let solutionCode of solutionCodeList" [value]="solutionCode.solutionCodeId">
                    {{solutionCode.solutionCodeDesc}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row align-self-end text-right m-0 pr-2">
          <div class="col-lg-8 col-md-4 filter-partition">
            <button class="SecondaryButton filter-button-cont" id="resetContinuanceListViewFiliter"
              (click)="ShowHideListFilterPanel('Reset')" type="button">
              <span class="d-inline-block align-middle">Reset</span></button>
          </div>
          <div class="col-lg-2 col-md-4 mt-md-0 mt-lg-0 px-3">
            <button class="SecondaryButton filter-button-cont" style="margin-right: 20px;" id="cancelContinuanceListViewFiliter"
              (click)="ShowHideListFilterPanel('Hide')" type="button">
              <span class="d-inline-block align-middle">Cancel</span></button>
          </div>
          <div class="col-lg-2 col-md-4 mt-md-0 mt-lg-0 pl-2">
            <button class="PrimaryButton filter-button-cont mr-2" id="applyContinuanceListViewFiliter"
              (click)="GetAllContinuanceListByFilters()" type="button">
              <span class="d-inline-block align-middle">Apply</span></button>
          </div>
        </div>      
      </div>
      </section>
    <div class="MainContainer ml-3 mr-2 my-3">
      
      <div class="tableData1">   
        <div class="tableData2 tableContinuance">
          <table class="listViewDataTable ResponsiveDataTable continuance-list-view">
            <thead>
              <tr>
                <th scope="col" class="table-sorted-desc t-md-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[0].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[0].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="HeaderSort(headerModelList[0].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-md-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[1].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[1].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="HeaderSort(headerModelList[1].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-sm-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[2].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[2].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="HeaderSort(headerModelList[2].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-sm-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[3].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[3].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="HeaderSort(headerModelList[3].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-sm-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[4].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[4].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="HeaderSort(headerModelList[4].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-md-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[5].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[5].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="HeaderSort(headerModelList[5].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-md-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[6].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[6].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="HeaderSort(headerModelList[6].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-sm-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-12 p-0 m-0">
                      {{headerModelList[7].headerName}}
                    </div>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody class="display" *ngIf="reacceptanceClients?.length != 0">
              <tr class="searchDatajs" *ngFor="let reacceptanceClient of reacceptanceClients" class="position-relative">
                <td data-label="Client name:" class="t-md-col">
                  <span *ngIf="reacceptanceClient.clientId > 0">
                    <a [routerLink]="['/ClientDetails/',reacceptanceClient.clientId]">
                      {{reacceptanceClient.clientName}}
                    </a>
                  </span>
                  <span *ngIf="reacceptanceClient.clientId == 0">
                    {{reacceptanceClient.clientName}}
                  </span>
                </td>
                <td data-label="Engagement name:" class="t-md-col">
                  <span *ngIf="reacceptanceClient.engagementId > 0" >
                    <a [routerLink]="['/EngagementDetail/',reacceptanceClient.engagementId]">
                      {{reacceptanceClient.engagementName}}
                    </a>
                  </span>
                  <span *ngIf="reacceptanceClient.engagementId == 0">
                    {{reacceptanceClient.engagementName}}
                  </span>
                </td>
                <td data-label="Client code:" class="t-sm-col">
                  <span >{{reacceptanceClient.cmsClientCode}}</span> </td>
                <td data-label="Active date:" class="t-sm-col">
                  {{reacceptanceClient.createdOn | date: "MM/dd/yyyy"}} </td>
                  <td data-label="Prior end:" class="t-sm-col">
                    {{reacceptanceClient.periodEndDate | date: "MM/dd/yyyy"}} </td>
                
                <td data-label="Engagement partner :" class="t-md-col pp-continuance">
                  <div id="engagementPartnerNameLevel{{reacceptanceClient.reacceptanceEngagementId}}">
                    <span *ngIf="reacceptanceClient.isUserExist == true" [style.color]="'#707070'">
                      {{reacceptanceClient.engagementPartnerName}}
                    </span>
                    <span *ngIf="reacceptanceClient.isUserExist == false" [style.color]="'#DE002E'">
                      {{reacceptanceClient.engagementPartnerName}}
                    </span>
                  </div>
                  <input type="hidden" id="hdn_IsUserExists_{{reacceptanceClient.reacceptanceEngagementId}}"
                    name="hdn_IsUserExists_{{reacceptanceClient.reacceptanceEngagementId}}"
                    value="{{reacceptanceClient.isUserExist}}" />
                  <input type="hidden" id="hdn_EngagementPartnerName_{{reacceptanceClient.reacceptanceEngagementId}}"
                    name="hdn_EngagementPartnerName_{{reacceptanceClient.reacceptanceEngagementId}}"
                    value="{{reacceptanceClient.engagementPartnerName}}" />
                  <input type="hidden" id="hdn_EngagementPartnerEMail_{{reacceptanceClient.reacceptanceEngagementId}}"
                    name="hdn_EngagementPartnerEMail_{{reacceptanceClient.reacceptanceEngagementId}}"
                    value="{{reacceptanceClient.engagementPartner}}" />
                  <div *ngIf="selectedReacceptanceEngagementId === reacceptanceClient.reacceptanceEngagementId" class="pp-continuance"
                    id="engagementPartnerNamePeoplePicker{{reacceptanceClient.reacceptanceEngagementId}}"
                    style="display: none;" class="input-group mw-100 picker-width">
                    <app-people-picker class="eng-people-picker" [roleShortName]="epRoleName"
                      [labelCtrlName]="eplblCtrlName" [isLabelVisible]="false"
                      [InputCtrlIdIndex]="reacceptanceClient.reacceptanceEngagementId"
                      [PeoplePickerModel]="engagement.engagementPartnerName" [userEmail]="engagement.engagementPartner" [IsValidateUserWithADGroup]="validateEP">
                    </app-people-picker>
                    <div class="input-group-append input-height">
                      <button type="button" id="PPIcon_peoplepickerIndex" data-toggle="modal" 
                        data-target="#modal-EP" data-keyboard="false" data-backdrop="static"
                        class="mw-100 people-picker-button text-center"
                        [disabled]="isMemberFirm"
                        (click)="refreshEPPopup('txtPeoplePicker_peoplepickerIndex')">
                        <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="Engagement PPMD" />
                      </button>
                    </div>
                  </div>
                  <div class="error-color epMandatoryCheck" *ngIf="!isvalidEngEPPMDName
                            && reacceptanceClient.reacceptanceEngagementId == globalClientId">
                    <p>Engagement PPMD is a required field </p>
                  </div>
                </td>
                <td data-label="Solution code:" class="t-md-col pp-continuance">
                  <div id="solutionCodeLevel{{reacceptanceClient.reacceptanceEngagementId}}">
                    <span *ngIf="reacceptanceClient.solutionCodeStatus == 'I'
                                  && reacceptanceClient.isReAcceptanceEligibility == false" [style.color]="'#DE002E'">
                      {{reacceptanceClient.solutionCode}}
                    </span>
                    <span *ngIf="reacceptanceClient.solutionCodeStatus == 'I'
                                  && reacceptanceClient.isReAcceptanceEligibility == true" [style.color]="'#DE002E'">
                      {{reacceptanceClient.solutionCode}}
                    </span>
                    <span *ngIf="reacceptanceClient.solutionCodeStatus == 'A'
                                   && reacceptanceClient.isReAcceptanceEligibility == false" [style.color]="'#DE002E'">
                      {{reacceptanceClient.solutionCode}}
                    </span>
                    <span *ngIf="reacceptanceClient.solutionCodeStatus == 'A'
                                   && reacceptanceClient.isReAcceptanceEligibility == true" [style.color]="'#707070'">
                      {{reacceptanceClient.solutionCode}}
                    </span>
                  </div>
                  <div *ngIf="selectedReacceptanceEngagementId === reacceptanceClient.reacceptanceEngagementId" class="sol-code-area"
                    id="solutionCodePeoplePicker{{reacceptanceClient.reacceptanceEngagementId}}" style="display: none;">
                    <app-auto-complete-text-box id="solutionCode{{reacceptanceClient.reacceptanceEngagementId}}"
                      (change)='onChangeSolutionCode(reacceptanceClient.reacceptanceEngagementId)'
                      name="solutionCode{{reacceptanceClient.reacceptanceEngagementId}}"
                      source="SolutionCodeContinuance" [MappedSource]="SolutionCodeMappedValue"
                      [Sequence]="reacceptanceClient.reacceptanceEngagementId" [NoRecordFoundFor]="false">
                    </app-auto-complete-text-box>
                  </div>
                  <input type="hidden"
                    id="hdn_isReAcceptanceEligibility_{{reacceptanceClient.reacceptanceEngagementId}}"
                    name="hdn_isReAcceptanceEligibility_{{reacceptanceClient.reacceptanceEngagementId}}"
                    value="{{reacceptanceClient.isReAcceptanceEligibility}}" />
                  <input type="hidden" id="hdn_IsSolutionCodeStatus_{{reacceptanceClient.reacceptanceEngagementId}}"
                    name="hdn_IsSolutionCodeStatus_{{reacceptanceClient.reacceptanceEngagementId}}"
                    value="{{reacceptanceClient.solutionCodeStatus}}" />
                  <input type="hidden" id="hdn_SolutionCode_{{reacceptanceClient.reacceptanceEngagementId}}"
                    name="hdn_SolutionCode_{{reacceptanceClient.reacceptanceEngagementId}}"
                    value="{{reacceptanceClient.solutionCode}}" />
                  <input type="hidden" id="hdn_NewSolutionCodeId_{{reacceptanceClient.reacceptanceEngagementId}}"
                    name="hdn_NewSolutionCodeId_{{reacceptanceClient.reacceptanceEngagementId}}"
                    value="{{reacceptanceClient.solutionCodeId}}" />
                  <input type="hidden" id="hdn_SolutionCodeId_{{reacceptanceClient.reacceptanceEngagementId}}"
                    name="hdn_SolutionCodeId_{{reacceptanceClient.reacceptanceEngagementId}}"
                    value="{{reacceptanceClient.scId}}" />
                    <input type="hidden" id="hdn_SolutionCodeScId_{{reacceptanceClient.reacceptanceEngagementId}}"
                    name="hdn_SolutionCodeId_{{reacceptanceClient.reacceptanceEngagementId}}"
                    value="{{reacceptanceClient.solutionCodeId}}" />
                  <input type="hidden" id="hdn_SolutionCodeDesc_{{reacceptanceClient.reacceptanceEngagementId}}"
                    name="hdn_SolutionCode_{{reacceptanceClient.reacceptanceEngagementId}}"
                    value="{{reacceptanceClient.solutionCodeDesc}}" />
                  <input type="hidden" id="hdn_ClientId_{{reacceptanceClient.reacceptanceEngagementId}}"
                    name="hdn_SolutionCode_{{reacceptanceClient.reacceptanceEngagementId}}"
                    value="{{reacceptanceClient.reacceptanceEngagementId}}" />
                  <div class="error-color solutionCodeMandatoryCheck" *ngIf="!arrValidationForEngagement[1]?.isvalid && arrValidationForEngagement[1]?.apply
                            && reacceptanceClient.reacceptanceEngagementId == globalClientId">
                    <p>solution code is a required field </p>
                  </div>
                </td>
                <td data-label="Action:" class="text-center t-sm-col">

                  <div class="cssEditRemove selectdiv" id="divEditRemove{{reacceptanceClient.reacceptanceEngagementId}}"
                    style="display: none;">
                    <select #ddListEditRemove id="ddListEditRemove{{reacceptanceClient.reacceptanceEngagementId}}"
                      data-toggle="modal"
                      (change)='onChangeEditRemove(ddListEditRemove.value,reacceptanceClient.reacceptanceEngagementId)'
                      class="dropdown rounded-0 form-control">
                      <option value="select" selected>--Select--</option>
                      <option value="edit">Edit</option>
                      <option value="remove">Remove</option>
                      <option value="cancel">Cancel</option>
                    </select>
                  </div>
                  <div class="cssSaveCancel" id="divSaveCancel{{reacceptanceClient.reacceptanceEngagementId}}"
                    style="display: none;">
                    <span class="d-inline-block cursor-pointer px-1">
                      <img src="../../../../assets/images/listview/save-24px.svg" alt="save" title="Save"
                        (click)=SaveContinuanceData(reacceptanceClient.reacceptanceEngagementId) />
                    </span>
                    <span class="d-inline-block cursor-pointer">
                      <img src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg" alt="cancel" title="Cancel"
                        (click)="toggleDisplay('saveCancel_'+ reacceptanceClient.reacceptanceEngagementId)" />
                    </span>
                  </div>
                  <div id="imgArrow{{reacceptanceClient.reacceptanceEngagementId}}">
                    <button id="{{reacceptanceClient.reacceptanceEngagementId}}" type="button"
                      [style.visibility]="ShowHideDots() ? 'visible' : 'hidden'" class="btn btn-link threedots mr-1"
                      (click)="toggleDisplay('dot_'+ reacceptanceClient.reacceptanceEngagementId)">
                      <img src="../../../assets/images/listview/more_icon.svg" width="24px" alt="more"
                        style="margin-top: -6px;" />
                    </button>
                    <span [style.visibility]="ShowHideIcon(reacceptanceClient.solutionCodeStatus,
                                  reacceptanceClient.isEngagementPartner,
                                  reacceptanceClient.isUserExist,
                                  reacceptanceClient.isReAcceptanceEligibility,
                                  reacceptanceClient.flag) ? 'visible' : 'hidden'">
                      <a id="initiate-continuance-click" 
                        [class.disabled]="isDisabled"
                        (click)="!isDisabled && setInitiateConfirmationMessage(reacceptanceClient.reacceptanceEngagementId,reacceptanceClient.cmsClientCode,reacceptanceClient.solutionCode,reacceptanceClient.clientId,reacceptanceClient.engagementId);">
                        <img src="../../../assets/images/listview/component.png" alt="Component"
                          style="margin-top: -6px;" />
                      </a>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
            <div class="row" *ngIf="reacceptanceEngagementCount == 0">
              <div class="col-md-9 no-data-available no-data text-left">No Data Available</div>
              <div class="col-md-3 "></div>
            </div>
          </table>
        </div>
      </div>
      <!-- ListView Pagination Start -->
      <div class="d-flex justify-content-center text-center pagination-section" *ngIf="reacceptanceClients?.length > 0">
        <ul *ngIf="pager.pages && pager.pages.length" class="pagination mx-2">
          <li>
            <a id="first" [ngClass]="{isDisabled:pager.currentPage === 1}" (click)="listViewSetPage(1)">
              <img alt="first row" src="../../../assets/images/EDP/Engagement/first_arrow.png">
            </a>
          </li>
          <li>
            <a id="previous" [ngClass]="{isDisabled:pager.currentPage === 1}"
              (click)="listViewSetPage(pager.currentPage - 1)">
              <img alt="previous row" alt="Previous row" src="../../../assets/images/EDP/Engagement/previous_arrow.png">
            </a>

          </li>
          <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
            <a (click)="listViewSetPage(page)">{{page}}</a>
          </li>

          <li>
            <a [ngClass]="{isDisabled:pager.currentPage === pager.totalPages}"
              (click)="listViewSetPage(pager.currentPage + 1)">
              <img alt="Next row" src="../../../assets/images/EDP/Engagement/next_arrow.png">
            </a>
          </li>
          <li>
            <a [ngClass]="{isDisabled:pager.currentPage === pager.totalPages}"
              (click)="listViewSetPage(pager.totalPages)">
              <img alt="Last row" src="../../../assets/images/EDP/Engagement/last_arrow.png">
            </a>
          </li>
        </ul>
        <div>
          <select id="drprecordsPerPage" [(ngModel)]="listViewNoOfrecordsPerPage" name="drprecordsPerPage" (change)="listViewSetPage(1)" class="page-counts">
            <option *ngFor="let listViewNoOfrecordsPerPage of noOfrecordsPerPageList" value="{{listViewNoOfrecordsPerPage}}">{{listViewNoOfrecordsPerPage}}</option>
          </select>
        </div>
      </div>
      <!-- ListView Pagination End -->
    </div><!-- MainContainer End -->
  </div><!-- List View Div End  -->
</div>
<div id="modal-EP" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title">Select engagement PPMD</span>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="deselectEP();">
          <span class="close-text mr-1">Close </span>
          <span class="modal-close-btn">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span id="EPErrorMessage" class="ErrorFont"></span>

        <div class="input-group nav-searchbox-length pb-2">
          <input type="text" id="searchEPName" (keydown.enter)="searchUsers('searchEPName','tblEPAD');"
            class="form-control py-1 rounded-0 nav-seachBox" placeholder="Search for engagement PPMD"
            autocomplete="off">
          <span class="input-group-append nav-seachBox">
            <div class="input-group-text" (click)="searchUsers('searchEPName','tblEPAD');">
              <img src="../../../assets/images/EDP/Filters/search.svg" alt="Search" class="nav-searchIcon" />
            </div>
          </span>
        </div>
        <span class="span-hint mt-1">Hint: Last name, first name, or email.</span>
        <div class="tableScroll mt-2">
          <table id="tblEPAD" class="table  table-hover table-EPPMDAD" aria-describedby="EP">
            <thead>
              <tr>
                <th scope="col"> Name</th>
                <th scope="col"> Email </th>
                <th scope="col">Job Title</th>
                <th scope="col">Phone</th>
                <th scope="col">Line of Business</th>
                <th scope="col">Service Line</th>
                <th scope="col">Location</th>
              </tr>
            </thead>
            <tbody>
              <tr (click)='selectEP(i,$event)' id="trEPs" name="trEPs"
                *ngFor="let member of membersListArrayForEP let i=index">
                <td>
                  <span id='spEPdisplayName{{i}}'>{{member.displayName}}</span>
                </td>
                <td> <span id='spEPmail{{i}}'>{{member.mail}}</span>
                </td>
                <td>
                  <span id='spjobtitle{{i}}'>{{member.jobTitle}}</span>
                </td>
                <td> <span id='spEPbusinessPhones{{i}}'>{{member.businessPhones}}</span>
                </td>
                <td> <span id='splineOfBusiness{{i}}'>{{member.lineOfBusiness}}</span>
                </td>
                <td> <span id='spserviceLine{{i}}'>{{member.serviceLine}}</span>
                </td>
                <td> <span id='splocation{{i}}'>{{member.location}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="SecondaryButton mr-2" data-dismiss="modal"
          style="width: 150px; text-align: center;" (click)="deselectEP();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
          </span>
          <span class="d-inline-block align-middle">Cancel</span>
        </button>
        <button type="button" class="PrimaryButton" data-dismiss="modal" style="width: 150px; text-align: center; "
          (click)="setEP();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
          </span>
          <span class="d-inline-block align-middle">OK</span>
        </button>
      </div>
    </div>
  </div>
</div>

  <!-- Modal Component -->
  <app-auto-create-opportunity [isOpen]="isModalOpen"  [opportunityList]="opportunityList" [accountId] = "accountId" 
  [primarySolutionCode] = "primarySolutionCode" (closeModal)="closeModal()" 
  [errorMessage]= "errorMessage"  (selectModal)="selectModal($event)" >
  </app-auto-create-opportunity>
