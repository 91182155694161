import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Subject, Subscription, timer } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { CardListModel } from './model/cardlistmodel';
import { WorkspaceInterviewsStatus, QuestionnaireDialogTitles } from './model/WorkspaceModel';
import { ConsentResetDialogTitle, EngagementDialogTitles } from './model/Engagement/Engagements';
import { ClientDialogTitles } from './model/Client/NewClient';
import { IndusDialogTitles } from 'src/app/model/WorkspaceModel';

// Service
import { LogService } from './services/log.service';
import { LoadingScreenService } from './services/loading-screen.service';
import { DataService } from './services/data.service';
import { AuthService } from './services/auth.service';
import { LeftNavService } from 'src/app/services/leftnavService';
import { AppConfigService } from './services/appConfigService';
import { cardService } from './services/cardServices';
import { filter, takeUntil } from 'rxjs/operators';
import { ContinuanceDialogTitles } from './model/InitiateNewEngagement';
import { EngagementService } from './services/EngagementService';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, Logger, LogLevel, RedirectRequest } from '@azure/msal-browser';
import { ClientExistDialogTitles, SearchClientbyFieldRequestModel } from './model/Client/CreateClientViewModel';

declare const gtag: Function;
declare const InitializePendoScript: Function;
declare let pendo: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [LeftNavService, cardService, AuthService]
})

export class AppComponent implements OnInit, OnDestroy {

  private roleLoadEndAppRef: Subscription = null;
  private loginFlagRef: Subscription = null;
  noAccessFlag: number;
  noAccessPopUp: number
  enviornmentTitle: string;
  isUserAgentIE: number;
  roleids: string;
  UserObject: any;
  cardlistmodel: CardListModel[] = [];

  private modalDialogRef: Subscription = null;
  private modalEngDialogRef: Subscription = null;
  dialogType: string;
  dialogTitle: string;
  dialogMessage: string;
  dialogDisplay: boolean;
  engdialogDisplay: boolean;
  dialogRedirectUrl: string;
  dialogcontinuanceDashBoardUrl: string
  dialogButton: number;
  dialogInput: string;
  dialogDeclineModal: boolean = false;
  dialogAbandonModal: boolean = false;
  dialogCloseEngagementModal: boolean = false;
  dialogBigTitle = false;
  dialogReviewNoteIsFinalApproval: boolean;
  dialogReviewNoteIsEP: boolean;
  expirySeconds: number;
  tokenURL: string;
  public renewalTokenTimer = timer(1000, 2000);
  private renewalTokenSubscription;
  displaySessionExpiredFlag: boolean;
  timerPaused: boolean;
  trackingGAID: any;
  pendoAPIKey: any;
  engageCalendarJsurl: any;
  EngagementId: string;
  LoggedInUserEmail: string;
  IsAdministrativeClose: boolean;
  displayDelegateDialog: boolean;
  private delegateDialogRef: Subscription = null;
  isAuthenticated: boolean;
  plannedClosureDate: string
  private readonly _destroying$ = new Subject<void>();
  isIframe = false;
  engageSupportCanvasLink: string = '';
  dialogVoidEngagementModal: boolean = false;
  dialogUpdateQuestionnaireExecutionPhase: boolean = false;
  dialogisContinuanceEngagementExistsModel: boolean = false
  SearchClientReqModel: SearchClientbyFieldRequestModel;
  selectedAccountId: string;
  selectedClientCode: string;
  displayNewDocumentDialog: boolean;
  displayDocumentTemplateDialog:boolean;
  prePopulatedClientId: number = 0;
  prePopulatedEngagementId: number = 0;
  dialogNotYetAvailableClient: boolean =false;

  private newDocumentDialogRef: Subscription = null;

  private DocumentTemplateDialogRef: Subscription = null;
  previousUrl:string
  currentUrl:string
  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService, private msalBroadcastService: MsalBroadcastService,
    private environment: AppConfigService, private loadingScreenService: LoadingScreenService,
    private _dataService: DataService, private auth: AuthService, private route: Router,
    private _cardService: cardService, private logService: LogService,
    private leftnavservice: LeftNavService, private router: Router,
    private _engagementService: EngagementService) {

    this.tokenURL = this.environment.config.DATA.AZURE_AD_AUTHENTICATION.TOKEN_URL;
    this.trackingGAID = this.environment.config.DATA.GA_TRACKING_ID;
    this.pendoAPIKey = this.environment.config.DATA.PENDO_API_KEY;
    this.engageCalendarJsurl = this.environment.config.DATA.ENGAGE_CALENDAR_JS_URL;
    this.noAccessFlag = 0; // 0 - Initial Load , 1 - Access, 2 - No Access
    this.noAccessPopUp = 0; // 0 - Initial Load , 1 - Access, 2 - No Access
    this.isUserAgentIE = this.DetectBrowser();
    localStorage.setItem('isUserAgentIE', this.isUserAgentIE.toString());

    this.dialogDisplay = false;
    this.expirySeconds = environment.config.DATA.AZURE_AD_AUTHENTICATION.EXPIRE_OFFSET_SECONDS;
    const adalLogFlag = environment.config.DATA.AZURE_AD_AUTHENTICATION.ADAL_LOGS;
    this.isAuthenticated = false;
    if (adalLogFlag === 'TRUE') {
      console.log('MSAL log enabled ');
      this.authService.setLogger(new Logger({
        loggerCallback: (level, message, containsPii) => {
          //console.log('MSAL Logging: ', message);
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
          }
        },
        piiLoggingEnabled: false,
        logLevel: LogLevel.Trace
      }));
    }

    if (this.trackingGAID !== '') {
      this.addGAScript();
    }
    if (this.pendoAPIKey !== '') {
      this.addPendoScript();
    }
    if (this.engageCalendarJsurl !== '') {
      this.addEngageCalendarScript();
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        gtag('event', 'page_view', { page_path: event.urlAfterRedirects });
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this._dataService.setPreviousUrl(this.previousUrl);
      });

    this.displayDelegateDialog = false;
    this.displayNewDocumentDialog = false;
    this.displayDocumentTemplateDialog=false;
  }

  getUserPreferenceData() {
    const jsonString = localStorage.getItem('currentUser');
    let userObject = JSON.parse(jsonString);
    this._engagementService.GetAllUserPreferenceData(userObject.userName).subscribe(result => {
      localStorage.removeItem('userpreferencelist');
      localStorage.setItem('userpreferencelist', JSON.stringify(result.userPreferenceData));
    });
  }

  ngOnInit() {
    // console.log('No Access Flag ', this.noAccessFlag);
    this.isIframe = window !== window.parent && !window.opener;

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
      )
      .subscribe((result: EventMessage) => {
        console.log('Login Failure ', result);
        this.auth.updateFlagNoAccess();

      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.SSO_SILENT_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        console.log('SSO Silent Sucess', result);
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        console.log('Login Success ', result);
        let userDetails = this.authService.instance.getAllAccounts();
        console.log('Login Display 1', userDetails);
        this.isAuthenticated = userDetails.length > 0;
        if (this.isAuthenticated) {
          this.getUserDetails();
        }
        else {
          this.checkAndSetActiveAccount();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe((result) => {
        this.auth.updateFlagLoading();
        // console.log('In Progress MSAL Status ', result)
        if (!this.isAuthenticated) {
          this.setLoginDisplay();
          this.checkAndSetActiveAccount();
        }
      });


    this.loginFlagRef = this.auth.loginFlag$.subscribe((value) => {
      if (value === 0) {
        this.noAccessFlag = 0;
      } else {
        if (value === -1) {
          this.SetEngageSupportCanvasLink();
          this.loadingScreenService.stopLoading();
          this.noAccessFlag = 2;
        } else {
          this.noAccessFlag = 1;
        }
      }
    });

    this.roleLoadEndAppRef = this.auth.roleLoadEnd$.subscribe((value) => {
      // console.log(' Role Loading ', value, value.length);
      if (value.length > 0) {
        this.getUserPreferenceData();
        this.roleInfoReceived(value);
        if (this.pendoAPIKey !== '') {
          this.initializePendo();
        }
      }
      else {
        // this.auth.updateFlagLoading();
        // if (!this.isAuthenticated) {
        //   this.setLoginDisplay();
        //   this.checkAndSetActiveAccount();
        // }
      }
    });

    this.modalDialogRef = this._dataService.displayDialogNotification$.subscribe(
      message => {
        // console.log('Display Dialog message in App component ', message);
        if (message) {
          const modal = JSON.parse(message);
          this.dialogType = modal.dialogType;
          this.dialogTitle = modal.dialogTitle;
          this.dialogMessage = modal.dialogDescription;
          this.dialogRedirectUrl = modal.dialogRedirectUrl;
          this.dialogButton = modal.dialogButton;
          this.dialogDisplay = modal.modalDisplay;
          this.dialogInput = modal.declineReason;
          this.dialogDeclineModal = modal.dialogDeclineModal;
          this.dialogAbandonModal = modal.dialogAbandonModal;
          this.dialogCloseEngagementModal = modal.dialogCloseEngagementModal;
          this.dialogBigTitle = modal.dialogBigTitle;
          this.dialogReviewNoteIsEP = modal.dialogReviewNoteIsEP;
          this.dialogReviewNoteIsFinalApproval = modal.dialogReviewNoteIsFinalApproval;
          this.EngagementId = modal.engagementId;
          this.SearchClientReqModel = modal.searchClientReqModel;
          this.LoggedInUserEmail = modal.loggedInUserEmail;
          this.IsAdministrativeClose = modal.isAdministrativeClose;
          this.dialogVoidEngagementModal = modal.dialogVoidEngagementModal;
          this.dialogUpdateQuestionnaireExecutionPhase = modal.dialogUpdateQuestionnaireExecutionPhase;
          this.dialogisContinuanceEngagementExistsModel = modal.dialogisContinuanceEngagementExistsModel;
          this.dialogcontinuanceDashBoardUrl = modal.dialogcontinuanceDashBoardUrl;
          this.dialogNotYetAvailableClient = modal.dialogNotYetAvailableClient;
        }
      });

    this.modalEngDialogRef = this._dataService.displayEngDialogNotification$.subscribe(
      message => {
        // console.log('Display Dialog message in App component ', message);
        if (message) {
          const modal = JSON.parse(message);
          this.dialogType = modal.dialogType;
          this.dialogTitle = modal.dialogTitle;
          this.dialogMessage = modal.dialogDescription;
          this.dialogRedirectUrl = modal.dialogRedirectUrl;
          this.dialogButton = modal.dialogButton;
          this.engdialogDisplay = modal.modalDisplay;
          this.dialogBigTitle = modal.dialogBigTitle;
          this.EngagementId = modal.engagementId;
          this.plannedClosureDate = modal.plannedClosureDate;
          this.LoggedInUserEmail = modal.loggedInUserEmail;
          this.IsAdministrativeClose = modal.isAdministrativeClose;
        }
      });

    this.delegateDialogRef = this._dataService.getDelegateModalDetails$.subscribe(
      message => {
        // console.log('Display Delegate Dialog message in App component ', message);
        if (message) {
          const modal = JSON.parse(message);
          this.displayDelegateDialog = modal.displayDialog;
        }
      });

    this.newDocumentDialogRef = this._dataService.getNewDocumentModalDetails$.subscribe(
      message => {
        // console.log('Display New document Dialog in App component ', message);
        if (message) {
          const modal = JSON.parse(message);
          this.displayNewDocumentDialog = modal.modalDisplay;
          this.prePopulatedClientId = modal.prePopulatedClientId;
          this.prePopulatedEngagementId = modal.prePopulatedEngagementId;
        }
      });

      this.DocumentTemplateDialogRef = this._dataService.getDocumentTemplateModalDetails$.subscribe(
        message => {
         // console.log('Display document Template Dialog in App component ', message);
          if (message) {
            const modal = JSON.parse(message);
            this.displayDocumentTemplateDialog =modal.modalDisplay;
            this.prePopulatedClientId = modal.prePopulatedClientId;
          this.prePopulatedEngagementId = modal.prePopulatedEngagementId;
            
          }
        });
  }

  setLoginDisplay() {
    let userDetails = this.authService.instance.getAllAccounts();
    // console.log('Login Display ', userDetails);
    this.isAuthenticated = userDetails.length > 0;
  }

  checkAndSetActiveAccount() {
    // console.log('checkAndSetActiveAccount func called');
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    if (!this.isAuthenticated) {
      this.authService.instance.handleRedirectPromise()
        .then((tokenResponse) => {
         // console.log('handleRedirectPromise result ', tokenResponse);
          if (tokenResponse) {
          //  console.log('Inside else in redirect');
            let activeAccount = this.authService.instance.getActiveAccount();
          //  console.log('Active Account ', activeAccount);
            if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
              let accounts = this.authService.instance.getAllAccounts();
             // console.log('All accounts ', accounts);
              this.authService.instance.setActiveAccount(accounts[0]);
              this.getUserDetails();
            }
          }
          else {
            this.loginApp();
          }
        })
        .catch(err => {
          // Handle error
          console.error('Handle Redirect ', err);
        });
    }
    else {
      this.getUserDetails();
    }
  }

  getUserDetails() {
    this.auth.getProfile().subscribe(
      result => {
        this.auth.addUserDetails(result);
      }
    );
  }

  loginApp() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }


  logout() {
    this.auth.logout();
    this.authService.logoutRedirect();
  }

  afterSuccessLogin(userRoleIds: string) {
    this.getEDPSideMenuList();
    this.getShortcutCount(userRoleIds);
    this._engagementService.initializeUserDetails();
  }

  addGAScript() {
    let gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.trackingGAID;
    document.head.prepend(gtagScript);

    gtag('config', this.trackingGAID, { send_page_view: false });
  }

  addPendoScript() {
    // let pendoScript: HTMLScriptElement = document.createElement('script');
    // pendoScript.async = true;
    // pendoScript.innerHTML = "(function(apiKey) {" +
    //   "(function(p, e, n, d, o) {" +
    //   " var v, w, x, y, z; o = p[d] = p[d] || {}; o._q = [];" +
    //   "v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];" +
    //   "for (w = 0, x = v.length; w < x; ++w)(function(m) {" +
    //   "o[m] = o[m] || function() {" +
    //   "o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));" +
    //   "};" +
    //   "})(v[w]);" +
    //   "y = e.createElement(n); y.async = !0;" +
    //   "y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';" +
    //   "z = e.getElementsByTagName(n)[0]; z.parentNode.insertBefore(y, z);" +
    //   "})(window, document, 'script', 'pendo'); " +
    //   "})('" + this.pendoAPIKey + "');";
    // document.head.prepend(pendoScript);
    InitializePendoScript(this.pendoAPIKey);
  }

  addEngageCalendarScript() {
    let ecScript: HTMLScriptElement = document.createElement('script');
    ecScript.async = true;
    ecScript.src = this.engageCalendarJsurl;
    document.head.prepend(ecScript);
  }

  ngOnDestroy() {
    this.roleLoadEndAppRef.unsubscribe();
    this.loginFlagRef.unsubscribe();
    this.modalDialogRef.unsubscribe();
    this.renewalTokenSubscription.unsubscribe();
    this.delegateDialogRef.unsubscribe();
    this.modalEngDialogRef.unsubscribe();
    this.newDocumentDialogRef.unsubscribe();
    this.DocumentTemplateDialogRef.unsubscribe();
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  ngAfterContentInit() {
    this.loadingScreenService.startLoading();
  }


  roleInfoReceived(value: any) {
    // console.log('roleInfoReceived func called', value, value.length);

    // console.log('Received Role Information in App Component.', value);
    this.noAccessFlag = 1;
    this._dataService.updatedRoleInfo(value);
    this.afterSuccessLogin(value);

    this.loadingScreenService.stopLoading();
  }

  DetectBrowser() {
    var version = detectIE();

    function detectIE() {
      var ua = window.navigator.userAgent;

      var msie = ua.indexOf('MSIE ');
      if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      }

      var trident = ua.indexOf('Trident/');
      if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      }

      var edge = ua.indexOf('Edge/');
      if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
      }

      // other browser
      return false;
    }
    if (version === false) { // returns  false, if browser is not Internet Explorer
      return -1;
    }
    return version;
  }

  SetIwantToHeading() {
    location.href = '/NewEngagement';
  }

  getEDPSideMenuList() {
    this._cardService.getEDPSideMenu().subscribe(
      result => {
        this.cardlistmodel = result;
        // console.log(this.cardlistmodel);
      },
      err => {
        this.cardlistmodel = null;
      });
  }

  getShortcutCount(rolesList: string) {
    // console.log('getShortcutCount fun called - input ', rolesList);
    if (typeof rolesList != 'undefined' && rolesList != '0' && rolesList != 'Loading') {
      this.leftnavservice.getShortcutCount(rolesList, true).subscribe(result => {
        if (result != null) {
          localStorage.setItem('iShortcutCount', result.toString());
          // console.log('getShortcutCount func called ', result);
        }
      },
        err => {
          console.log('Error in fetching data from get verb of LeftNav.', err);
        });
    }
  }

  okClickEvent(event) {
    // console.log(event);
    let result = JSON.parse(event);
    this.dialogInput = result.declineReason;
    this.selectedAccountId = result.selectedAccountId;
    this.selectedClientCode = result.selectedClientCode;
    if (this.dialogTitle === QuestionnaireDialogTitles.FinishInterview ||
      this.dialogTitle === QuestionnaireDialogTitles.UnAnsQuestions ||
      this.dialogTitle === EngagementDialogTitles.CancelEng ||
      this.dialogTitle === QuestionnaireDialogTitles.CancelQuestionnaire ||
      this.dialogTitle === WorkspaceInterviewsStatus.ReviewStatus ||
      this.dialogTitle === WorkspaceInterviewsStatus.SendToEPPMDTitle ||
      this.dialogTitle === WorkspaceInterviewsStatus.EditQuestionnaire ||
      this.dialogTitle === WorkspaceInterviewsStatus.AbandonedTitle ||
      this.dialogTitle === WorkspaceInterviewsStatus.EPApprovedTitle ||
      this.dialogTitle === WorkspaceInterviewsStatus.RPApprovedTitle ||
      this.dialogTitle === WorkspaceInterviewsStatus.EPDeclinedTitle ||
      this.dialogTitle === WorkspaceInterviewsStatus.RPDeclinedTitle ||
      this.dialogTitle === QuestionnaireDialogTitles.QueNewVersion ||
      this.dialogTitle === 'Remove RP' ||
      this.dialogTitle === 'Remove team member' ||
      this.dialogTitle === 'Remove client team member' ||
      this.dialogTitle === ContinuanceDialogTitles.RemoveContinuance ||
      this.dialogTitle === ContinuanceDialogTitles.UpdatedSolutioncCode ||
      this.dialogTitle === 'Remove document' ||
      this.dialogTitle === ClientDialogTitles.createClient ||
      this.dialogTitle === WorkspaceInterviewsStatus.EditEngagement ||
      this.dialogTitle === EngagementDialogTitles.CloseEng ||
      this.dialogTitle === QuestionnaireDialogTitles.InputErrors ||
      this.dialogTitle === EngagementDialogTitles.RemoveAssignment ||
      this.dialogTitle === EngagementDialogTitles.SubmitAssignment ||
      this.dialogTitle === EngagementDialogTitles.SendClosure ||
      this.dialogTitle === ContinuanceDialogTitles.InitiateContinuanceEngagement ||
      this.dialogTitle === EngagementDialogTitles.UnsavedChangesConfirmation ||
      this.dialogTitle === WorkspaceInterviewsStatus.OpenReviewNotes ||
      this.dialogTitle === EngagementDialogTitles.ReEditQuestionnaire ||
      this.dialogTitle === EngagementDialogTitles.VoidEngagement ||
      this.dialogTitle === EngagementDialogTitles.RemoveExecutionDocument ||
      this.dialogTitle === ClientExistDialogTitles.TickerSymbol ||
      this.dialogTitle === ClientExistDialogTitles.PhoneNumber ||
      this.dialogTitle === ClientExistDialogTitles.Address1 ||
      this.dialogTitle === ClientExistDialogTitles.WebSite ||
      this.dialogTitle === ClientExistDialogTitles.BillingContactEmail ||
      this.dialogTitle === ClientExistDialogTitles.BusinessContactEmail ||
      this.dialogTitle === ClientExistDialogTitles.ClientName ||
      this.dialogTitle === 'Reset account?' ||
      this.dialogTitle === EngagementDialogTitles.ContinuanceEngExists ||
      this.dialogTitle === EngagementDialogTitles.UnsavedChangesConfirmationIWantTo||
      this.dialogTitle === EngagementDialogTitles.EditQuestionnaire||
      this.dialogTitle === EngagementDialogTitles.ExportDocument  ||
      this.dialogTitle === IndusDialogTitles.RecordINDUSconsent ||
      this.dialogTitle === IndusDialogTitles.RemoveINDUSconsent ||
      this.dialogTitle === ConsentResetDialogTitle.ResetClientConsent ||
      this.dialogTitle === EngagementDialogTitles.InterChangeableSolutionCodes ||
      this.dialogTitle === EngagementDialogTitles.AdministrativeClose ||
      this.dialogTitle === EngagementDialogTitles.AbandonedEngagement ||
      this.dialogTitle === WorkspaceInterviewsStatus.AbandonedChangeOrderTitle ||
      this.dialogTitle === WorkspaceInterviewsStatus.EPChangeOrderDeclinedTitle ||
      this.dialogTitle === WorkspaceInterviewsStatus.RPChangeOrderDeclinedTitle ||
      this.dialogTitle === 'Client not available.'
    ) {
      var dialogData = {
        dialogTitle: this.dialogTitle,
        dialogButtonName: 'ok',
        DeclinedReasonInput: this.dialogInput,
        selectedAccountId: this.selectedAccountId,
        selectedClientCode: this.selectedClientCode,
        dialogReviewNoteIsFinalApproval: this.dialogReviewNoteIsFinalApproval,
        dialogReviewNoteIsEP: this.dialogReviewNoteIsEP
      };
      var dialogJSON = JSON.stringify(dialogData);
      this._dataService.setDialogDetails(dialogJSON);
    }

    if (this.dialogTitle === 'Session Expired') {
      // console.log('inside ok event of session expired.');
      this.timerPaused = false;
      this.logout();
    }
  }

  cancelClickEvent(event) {
    // console.log(event);
    if (this.dialogTitle === QuestionnaireDialogTitles.UnAnsQuestions ||
      this.dialogTitle === ContinuanceDialogTitles.InitiateContinuanceEngagement ||
      this.dialogTitle === EngagementDialogTitles.UnsavedChangesConfirmation ||
      this.dialogTitle === EngagementDialogTitles.UnsavedChangesConfirmationIWantTo ||
      this.dialogTitle === ConsentResetDialogTitle.ResetClientConsent ||
      this.dialogTitle === WorkspaceInterviewsStatus.EditEngagement ||
      this.dialogTitle === WorkspaceInterviewsStatus.EditQuestionnaire ||
      this.dialogTitle === WorkspaceInterviewsStatus.AbandonedTitle ||
      this.dialogTitle === WorkspaceInterviewsStatus.SendToEPPMDTitle ||
      this.dialogTitle === WorkspaceInterviewsStatus.EPDeclinedTitle ||
      this.dialogTitle === WorkspaceInterviewsStatus.RPDeclinedTitle ||
      this.dialogTitle === WorkspaceInterviewsStatus.EPApprovedTitle ||
      this.dialogTitle === WorkspaceInterviewsStatus.RPApprovedTitle ||
      this.dialogTitle === WorkspaceInterviewsStatus.OpenReviewNotes ||
      this.dialogTitle === EngagementDialogTitles.InterChangeableSolutionCodes ||
      this.dialogTitle === EngagementDialogTitles.AbandonedEngagement ||
      this.dialogTitle === WorkspaceInterviewsStatus.AbandonedChangeOrderTitle ||
      this.dialogTitle === WorkspaceInterviewsStatus.RPChangeOrderDeclinedTitle ||
      this.dialogTitle === WorkspaceInterviewsStatus.EPChangeOrderDeclinedTitle
    ) {
      var dialogData = {
        dialogTitle: this.dialogTitle,
        dialogButtonName: 'cancel',
        DeclinedReasonInput: this.dialogInput
      };
      var dialogJSON = JSON.stringify(dialogData);
      this._dataService.setDialogDetails(dialogJSON);
    }
  }

  okEngClickEvent(event) {
    // console.log(event);
    let result = JSON.parse(event);
    this.dialogTitle = result.dialogTitle;
    if (this.dialogTitle === 'Completion date updated') {
      var dialogData = {
        dialogTitle: this.dialogTitle,
        dialogButtonName: 'ok',
        dialogResultMessage: 'success'
      };
      var dialogJSON = JSON.stringify(dialogData);
      this._dataService.setEngDialogDetails(dialogJSON);
    }

    if (this.dialogTitle === 'Session Expired') {
      // console.log('inside ok event of session expired.');
      this.timerPaused = false;
      this.logout();
    }
  }

  cancelEngClickEvent(event) {
    // console.log(event);
    let result = JSON.parse(event);
    this.dialogTitle = result.dialogTitle;
    if (this.dialogTitle === 'Cancel Popup') {
      var dialogData = {
        dialogTitle: this.dialogTitle,
        dialogButtonName: 'cancel'
      };
      var dialogJSON = JSON.stringify(dialogData);
      this._dataService.setEngDialogDetails(dialogJSON);
    }
  }

  closeEngClickEvent(event) {
    // console.log(event);
    let result = JSON.parse(event);
    this.dialogTitle = result.dialogTitle;
    if (this.dialogTitle === 'Close Popup') {
      var dialogData = {
        dialogTitle: this.dialogTitle,
        dialogButtonName: 'close'
      };
      var dialogJSON = JSON.stringify(dialogData);
      this._dataService.setEngDialogDetails(dialogJSON);
    }
  }

  closeClickEvent(event) {
    // console.log(event);
    if (this.dialogTitle === 'Session Expired') {
      // console.log('inside close event of session expired.');
      // Need to logout in all the tabs.
      this.timerPaused = false;
      this.logout();
    }
    else if (this.dialogTitle === EngagementDialogTitles.UnsavedChangesConfirmation ||
      this.dialogTitle === EngagementDialogTitles.UnsavedChangesConfirmationIWantTo ||
      this.dialogTitle === QuestionnaireDialogTitles.UnAnsQuestions || 
      this.dialogTitle === ConsentResetDialogTitle.ResetClientConsent ||
      this.dialogTitle === WorkspaceInterviewsStatus.EditEngagement ||
      this.dialogTitle === WorkspaceInterviewsStatus.EditQuestionnaire ||
      this.dialogTitle === WorkspaceInterviewsStatus.AbandonedTitle ||
      this.dialogTitle === WorkspaceInterviewsStatus.SendToEPPMDTitle ||
      this.dialogTitle === WorkspaceInterviewsStatus.EPDeclinedTitle ||
      this.dialogTitle === WorkspaceInterviewsStatus.RPDeclinedTitle ||
      this.dialogTitle === WorkspaceInterviewsStatus.EPApprovedTitle ||
      this.dialogTitle === WorkspaceInterviewsStatus.RPApprovedTitle ||
      this.dialogTitle === WorkspaceInterviewsStatus.OpenReviewNotes ||
      this.dialogTitle === EngagementDialogTitles.InterChangeableSolutionCodes ||
      this.dialogTitle === EngagementDialogTitles.AbandonedEngagement ||
      this.dialogTitle === WorkspaceInterviewsStatus.AbandonedChangeOrderTitle ||
      this.dialogTitle === WorkspaceInterviewsStatus.RPChangeOrderDeclinedTitle ||
      this.dialogTitle === WorkspaceInterviewsStatus.EPChangeOrderDeclinedTitle) {
      var dialogData = {
        dialogTitle: this.dialogTitle,
        dialogButtonName: 'close',
        DeclinedReasonInput: this.dialogInput
      };
      var dialogJSON = JSON.stringify(dialogData);
      this._dataService.setDialogDetails(dialogJSON);
    }
  }

  SetEngageSupportCanvasLink() {
    this._engagementService.GetEngageSupportCanvasLink().subscribe(result => {
      this.engageSupportCanvasLink = result;
    },
      err => {
        console.log(err);
      });
  }

  OpenEngageSupportCanvasLink() {
    var win = window.open(this.engageSupportCanvasLink, '_blank');
    win.focus();
  }

  initializePendo() {
    const userObject = JSON.parse(localStorage.getItem('currentUser'));
    // console.log(userObject);
    pendo.initialize({
      visitor: {
        id: userObject.employeeId,
        email: userObject.loggedInUserEmail,
        office: userObject.officeLocation,
        businessLine: userObject.lineOfBusiness_sharepoint,
        careerLevel: userObject.jobTitle,
        language: userObject.userLanguage,
        serviceLine: userObject.serviceLine,
        userName: userObject.firstName + ' ' + userObject.lastName
      },
      account: {
        id: userObject.serviceLine
      },
    });
  }

  cancelNewDocumentDialog(event) {
    // console.log(event);
    var modalObj = {
      dialogButtonName: 'cancel'
    };
    var dialogJSON = JSON.stringify(modalObj);
    //this._dataService.setNewDocumentDialogDetails(dialogJSON);    
  }
}
