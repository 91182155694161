<div class="card" *ngIf="!isSuccessorFirmEnabled">
    <div class="card-header  ">
        <div class="justify-content-between d-flex">
            <div class="card-heading">
                <img src="assets/images/EDP/Workspace/check_circle-24px.svg" class="img-responsive"
                    *ngIf="sectionConfiguration.isCompleted" alt="Completed"
                    style="vertical-align: text-top;float:left;margin-right: 5px;margin-top:-1px;" width="20"
                    height="20" />
                <img src="assets/images/EDP/Workspace/report_problem-24px.svg" class="img-responsive"
                    *ngIf="showRequiredWarning" alt="Warning"
                    style="vertical-align: text-top;float:left;margin-right: 5px;margin-top:-1px;" width="20"
                    height="20" />
                <a class="card-link">
                    <h5 class="edp-font-14 edp-font-color font-weight-bold">ClientVoice survey</h5>
                </a>
            </div>
            <div class="card-icon" *ngIf="!isImageEnabled">
                <a id="accessSurveylink" class="collapsed card-link" data-toggle="collapse" (click)="toggleUp();">
                    <img src="assets/images/EDP/Engagement/desc_order.png" alt="Expand"
                        style="cursor: pointer; padding-bottom: 2px;">
                </a>
            </div>
            <div class="card-icon" *ngIf="isImageEnabled">
                <a class="collapsed card-link" data-toggle="collapse" (click)="toggleDown();">
                    <img src="assets/images/EDP/Engagement/asc_order.png" alt="Expand"
                        style="cursor: pointer; padding-bottom: 2px;">
                </a>
            </div>
        </div>
        <div id="sendSurveyDiv" *ngIf="!isImageEnabled" class="d-flex mt-2 col-md-12 m-0 p-0">
            <div id="clientVoiceMsgDiv" class="card-link text-label col-md-5 m-0 p-0">
                <label>Send the ClientVoice/feedback survey for client</label>
            </div>
            <div class="card-link col-md-7 text-right pr-5">
                <div *ngIf="divType ==='Default'">
                    <label *ngIf="engagementClientVoiceDetailViewModel.engagementClientVoice?.delegateEmailId == null"
                        style="color: #707070;">Awaiting completion</label>
                    <label *ngIf="engagementClientVoiceDetailViewModel.engagementClientVoice?.delegateEmailId != null"
                        style="color: #707070;">Awaiting Delegate completion</label>
                </div>

                <div class="row" style="width: auto;float: right;" *ngIf="divType ==='Opted'">
                    <div class="col-md-7 p-0 pr-3 filter-partition-rt">
                        <label style="color: #707070;">Opted out by</label>&nbsp;
                        <span style="color: #00838F;">
                            {{engagementClientVoiceDetailViewModel.engagementClientVoice.lastModifiedByName}}</span>&nbsp;
                        <label style="color: #707070;">on</label>&nbsp;
                        <label style="color: #707070;">
                            {{engagementClientVoiceDetailViewModel.engagementClientVoice.lastModifiedDate |
                            date: "MM/dd/yyyy"}}
                        </label>&nbsp;
                    </div>
                    <div class="col-md-5 p-0 pl-3 text-left filter-partition">
                        <span>
                            <a (click)="showHideClientvoiceDiv()" class="link-text-blue edp-font-14 ml-1 sap-bar"
                                [ngClass]="{isDisabled:!(isUserEPPMDOrDelegate||isloggedInUserAdmin =='yes')|| engStatus == EngagementStatus.EngVoided}"
                                style="text-decoration: underline;">Clear and schedule survey</a>
                        </span>
                    </div>
                </div>
                <div *ngIf="divType ==='Completed'">
                    <label style="color: #707070;">Completed by</label>&nbsp;
                    <span style="color: #00838F;">
                        {{engagementClientVoiceDetailViewModel.engagementClientVoice.lastModifiedByName}}</span>&nbsp;
                    <label style="color: #707070;">on</label>&nbsp;
                    <label style="color: #707070;">
                        {{engagementClientVoiceDetailViewModel.engagementClientVoice.lastModifiedDate | date:
                        "MM/dd/yyyy"}}
                    </label>
                </div>
            </div>
        </div>

    </div>
    <div id="collapseThree" class="collapse" data-parent="#accordion" ng-app="">
        <div class="card-body">
            <p style=" margin-top: -22px"><span *ngIf="lastSurveySentDateForClient">
                    The last ClientVoice survey response for this service line was on {{
                    lastSurveySentDateForClient | date: 'MM/dd/yyyy' }}. </span>Please consider sending a new
                survey. Please receive approval from the EPPMD before sending a survey. </p>
            <div class="custom-control custom-checkbox" *ngIf="isRequiredToSendClientVoiceSurvey">
                <input id="chkEngagementStatus" [disabled]="isSurveryOptedCompleted" type="checkbox"
                    class="custom-control-input" (change)="toggleDiv()"
                    [(ngModel)]="engagementClientVoiceUIViewModel.isSurveyOptOut">
                <label class="custom-control-label" for="chkEngagementStatus">A ClientVoice survey is not
                    required for this
                    engagement.</label>
            </div>
            <!-- start of 1st screen-->
            <div id="withoutcheck_clientvoice" *ngIf="!engagementClientVoiceUIViewModel.isSurveyOptOut"
                style="margin-right: -25px;">
                <div class="row">
                    <div class="col-12 col-sm-5 col-lg-5">
                        <div class="row" *ngIf="engagementClientVoiceUIViewModel.isloggedInUserSameAsEPPMD">
                            <!--change by swati-->
                            <!-- <div class="col-lg-8 p-1 pl-3">
                                <label for="epRoleName">Select delegate:</label>
                                <div class="input-group mw-100">
                                    <select id="drpDelegate" name="drpDelegate" class="dropdown rounded-0 form-control"
                                        (change)="changeDelegate()">
                                        <option value="" disabled selected hidden style="font-size:14px;">Select
                                            delegate</option>
                                        <option *ngFor="let member of uniqueDelegateMembers"
                                            [value]=member.memberEmailId>
                                            {{member.memberName}}</option>
                                    </select>
                                </div>
                                <div class="form-control-feedback error-color row ml-2"
                                    *ngIf="!engagementClientVoiceUIViewModel.isValidDelegate">
                                    <p>Select delegate</p>
                                </div>
                                <div class="row success-msg ml-1"
                                    *ngIf="engagementClientVoiceUIViewModel.isDelegateSaved">
                                    <p><strong>Delegate saved successfully</strong></p>                                    
                                </div>
                            </div>-->
                            <!---->
                            <!--<div class="col-lg-4 delegate p-1">
                                <label></label>
                                <button class="btn SecondaryButton w-100 text-center" type="button"
                                    [disabled]="isSurveryOptedCompleted" (click)="setDelegate()">
                                    Delegate
                                </button>
                            </div>-->
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3 ">
                        <label for="engagement_ChangedName">Refer to the engagement as..<span class="mandatory">*</span>
                        </label>
                        <input type="text" id="engagement_ChangedName"
                            class="form-control rounded-0 border engagement-name input-height" maxlength="100"
                            placeholder="Enter an engagement name"
                            [(ngModel)]="engagementClientVoiceUIViewModel.engagement_ChangedName"
                            style="height: 40px !important;" [disabled]="isSurveryOptedCompleted" />
                        <div class="form-control-feedback error-color row ml-2"
                            *ngIf="!engagementClientVoiceUIViewModel.isValidEngName">
                            <p>Enter an engagement name</p>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4">
                        <label for="lblsurveyDate">Send survey on the following date:<span
                                class="mandatory">*</span></label>
                        <app-calendercontrol id="calendersurveyDt" [txtDate]="surveyDateCtrlId"
                            [disableWeekend]="disableWeekendValue"
                            [ngClass]="{true: 'isDisabled'}[isSurveryOptedCompleted]">
                        </app-calendercontrol>
                        <label for="lblHolidayNote">Note: Avoid sending on a holiday.</label>
                        <div class="form-control-feedback error-color row ml-2"
                            *ngIf="!engagementClientVoiceUIViewModel.isValidSurveyDate">
                            <p>Select/enter survey date</p>
                        </div>
                        <div class="form-control-feedback error-color row ml-2"
                            *ngIf="!engagementClientVoiceUIViewModel.isSurveyDateFutureDt">
                            <p>Survey date should be future date</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-4 pl-3">
                    <p><strong>Who will receive the ClientVoice user feedback survey?</strong></p>
                </div>
                <div>
                    <p>Please validate the client contact email address before submission. </p>
                </div>
                <div class="row p-0">
                    <div class="col-lg-12 col-sm-6 p-0 pl-4">
                        <div class="row">
                            <div class="col-12 col-sm-5 col-lg-5">
                                <div class="row">
                                    <div class="col-lg-9 p-1">
                                        <label for="epRoleName">Select recipient:</label>
                                        <div class="selectdiv mw-100">
                                            <select id="drprecipient" name="recipient"
                                                class="dropdown rounded-0 form-control" (change)="changeRecipient()">
                                                <option value="" disabled selected hidden>Select recipient
                                                </option>
                                                <option *ngFor="let recipient of clientVoiceRecipientList"
                                                    [value]=recipient.contactGuid>
                                                    {{recipient.fullName}}</option>
                                            </select>
                                        </div>
                                        <div class="form-control-feedback error-color row ml-2"
                                            *ngIf="!engagementClientVoiceUIViewModel.isValidRecipient">
                                            <p>Select recipient</p>
                                        </div>
                                        <div class="form-control-feedback error-color row ml-2"
                                            style="width:max-content;"
                                            *ngIf="engagementClientVoiceUIViewModel.isDrpRecipientExistsInList">
                                            <p>Selected recipient already exists in the list </p>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 delegate p-1">
                                        <label></label>
                                        <button class="btn SecondaryButton w-100 text-center" type="button"
                                            id="btnAddRecipient" [disabled]="isSurveryOptedCompleted"
                                            (click)="addRecipient()">
                                            Add
                                        </button>
                                    </div>
                                </div>
                                <label class="mt-3" style="margin-left: -8px;">Or add one manually:</label>
                                <div class="row">
                                    <div class="col-lg-6 col-sm-6 p-1">
                                        <div>
                                            <label for="engagementName">First name<span
                                                    class="mandatory">*</span></label>
                                            <input type="text" id="manualRecipientName"
                                                class="form-control rounded-0 border engagement-name input-height"
                                                maxlength="40" placeholder="Enter recipient first name"
                                                [(ngModel)]="manualRecipient.firstName" />
                                        </div>
                                        <div class="form-control-feedback error-color row ml-2"
                                            *ngIf="!manualRecipient.isValidFirstName">
                                            <p>Enter recipient first name </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-6 p-1">
                                        <div>
                                            <label for="engagementLastName">Last name<span
                                                    class="mandatory">*</span></label>
                                            <input type="text" id="manualRecipientLastName"
                                                class="form-control rounded-0 border engagement-name input-height"
                                                maxlength="40" placeholder="Enter recipient last name"
                                                [(ngModel)]="manualRecipient.lastName" />
                                        </div>
                                        <div class="form-control-feedback error-color row ml-2"
                                            *ngIf="!manualRecipient.isValidLastName">
                                            <p>Enter recipient last name </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-12 col-sm-12 px-1 pt-3 pb-1">
                                        <div>
                                            <label for="manualRecipientTitle">Title</label>
                                            <input type="text" id="manualRecipientTitle"
                                                class="form-control rounded-0 border engagement-name input-height"
                                                maxlength="100" placeholder="Enter recipient title"
                                                [(ngModel)]="manualRecipient.memberTitle" />
                                        </div>
                                    </div>
                                </div>

                                <div class="row p-1 mt-2">
                                    <div class="col-lg-9 col-sm-9 p-1">
                                        <div>
                                            <label for="manualRecipientEmail">Email<span
                                                    class="mandatory">*</span></label>
                                            <input type="text" id="manualRecipientTitle"
                                                class="form-control rounded-0 border engagement-name input-height"
                                                maxlength="100" placeholder="Enter recipient email"
                                                [(ngModel)]="manualRecipient.memberEmail" />
                                        </div>

                                        <div class="form-control-feedback error-color row ml-2"
                                            *ngIf="!manualRecipient.isValidMemberEmail">
                                            <p>Enter recipient email</p>
                                        </div>

                                        <div class="form-control-feedback error-color"
                                            *ngIf="!manualRecipient.isValidMemberEmailText">
                                            <p>Enter valid recipient email </p>
                                        </div>
                                        <div class="form-control-feedback error-color"
                                            *ngIf="manualRecipient.isRecipientExistsInList">
                                            <p>Recipient already exists in the list </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-sm-3 delegate p-1 ">

                                        <label></label>
                                        <button id="btnAddRecipientManually"
                                            class="btn SecondaryButton w-100 text-center" type="button"
                                            id="btnAddRecipientManually" [disabled]="isSurveryOptedCompleted"
                                            (click)="addRecipientManually()">
                                            Add
                                        </button>

                                    </div>

                                </div>

                            </div>
                            <div class="col-lg-7 col-sm-6 p-0">
                                <div class="d-block">
                                    <label class="float-left">Recipients:</label>
                                    <span class="error-color float-left"
                                        *ngIf="engagementClientVoiceUIViewModel.isDuplicateEmailExistsInList">
                                        <p>There are duplicate email addresses, please remove one of them.</p>
                                    </span>
                                    <span class="error-color float-left"
                                        *ngIf="engagementClientVoiceUIViewModel.isFirstOrLastNameBlankInList">
                                        <p>First or Last name cannot be blank.</p>
                                    </span>
                                    <span class="error-color float-left"
                                        *ngIf="!engagementClientVoiceUIViewModel.isValidrecipientCount">
                                        <p>Should have atleast one recipient.</p>
                                    </span>
                                    <span class="error-color float-left"
                                        *ngIf="!engagementClientVoiceUIViewModel.isValidrecipientEmail">
                                        <p>Recipient email is mandatory, please check below list.</p>
                                    </span>
                                    <span class="float-right">
                                        <a (click)="resetClientVoiceTeamMembers()"
                                            [ngClass]="{isDisabled:isSurveryOptedCompleted}"
                                            class="link-text-blue edp-font-14 ml-1 sap-bar">Reset</a>
                                    </span>
                                </div>
                                <div class="tablefixhead">
                                    <table class="table recipient-table table-lg border">
                                        <thead>
                                            <tr>
                                                <th width="20%" scope="col">First Name</th>
                                                <th width="20%" scope="col">Last Name</th>
                                                <th width="30%" scope="col">Email</th>
                                                <th width="15%" scope="col">Title/Role</th>
                                                <th width="15%" scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="dbClientVoiceTeamMemberCount == 0">
                                            <tr [ngClass]="{'tr-bgcolor':member.isRowInvalid}"
                                                *ngFor="let member of engagementClientVoiceUIViewModel.clientVoiceTeamMembers; let i=index">
                                                <td scope="row">
                                                    <span class="text-break"
                                                        *ngIf="!member.isRecipientsEdit">{{member.firstName}}</span>
                                                    <span>
                                                        <div class="p-0 m-0" *ngIf="member.isRecipientsEdit">
                                                            <div>
                                                                <input id="txtfirstName" type="text" name="txtfirstName"
                                                                    [(ngModel)]="member.editFirstName"
                                                                    autocomplete="off"
                                                                    class="input-height form-control rounded-0 "
                                                                    maxlength="50">
                                                            </div>

                                                            <div class="form-control-feedback error-color row ml-2 pb-3"
                                                                *ngIf="!member.isValidFirstName">
                                                                <p>Enter first name</p>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </td>

                                                <td scope="row">
                                                    <span class="text-break"
                                                        *ngIf="!member.isRecipientsEdit">{{member.lastName}}</span>
                                                    <span>
                                                        <div class="p-0 m-0" *ngIf="member.isRecipientsEdit">
                                                            <div>
                                                                <input id="txtfirstName" type="text" name="txtfirstName"
                                                                    [(ngModel)]="member.editLastName" autocomplete="off"
                                                                    class="input-height form-control rounded-0 "
                                                                    maxlength="50">
                                                            </div>

                                                            <div class="form-control-feedback error-color row ml-2 pb-3"
                                                                *ngIf="!member.isValidLastName">
                                                                <p>Enter last name</p>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="text-break"
                                                        *ngIf="!member.isRecipientsEdit">{{member.memberEmail}}</span>
                                                    <span>
                                                        <div class="p-0 m-0" *ngIf="member.isRecipientsEdit">
                                                            <div>
                                                                <input id="txtMemberEmail" type="text"
                                                                    name="txtMemberEmail"
                                                                    [(ngModel)]="member.editMemberEmail"
                                                                    autocomplete="off"
                                                                    class="input-height form-control rounded-0 "
                                                                    maxlength="80">
                                                            </div>

                                                            <div class="form-control-feedback error-color row ml-2"
                                                                *ngIf="!member.isValidMemberEmail">
                                                                <p>Enter an email</p>
                                                            </div>

                                                            <div class="form-control-feedback error-color"
                                                                *ngIf="!member.isValidMemberEmailText">
                                                                <p>Enter valid email </p>
                                                            </div>
                                                            <div class="form-control-feedback error-color"
                                                                style="height:50px!important;"
                                                                *ngIf="member.isEmailExistsInList">
                                                                <p>Recipient email already exists in the list
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </td>
                                                <td class="text-break">{{member.memberTitle}}</td>

                                                <td class="p-0 m-0 text-right pr-2">
                                                    <span *ngIf="!member.isRecipientsEdit">
                                                        <a *ngIf="!member.isRecipientsEdit && !isSurveryOptedCompleted"
                                                            id="editclientVoiceTeamMember_{{i}}"
                                                            (click)="editclientVoiceTeamMember(i)">
                                                            <img style="width: 18px;height: 18px;"
                                                                src="../../../../assets/images/EDP/Engagement/edit-24px.svg"
                                                                alt="Edit" class="middle-align">
                                                        </a>
                                                        <a id="clientVoiceTeamMember_{{i}}"
                                                            *ngIf="!isSurveryOptedCompleted"
                                                            (click)="removeClientVoiceTeamMember(i)">
                                                            <img class=""
                                                                src="assets/images/EDP/Engagement/remove_circle_outline.svg"
                                                                alt="remove" class="middle-align">
                                                        </a>
                                                    </span>
                                                    <span *ngIf="member.isRecipientsEdit">
                                                        <a id="saveClientVoiceTeamMemberLink_{{i}}"
                                                            (click)="saveclientVoiceTeamMember(i)"
                                                            class="link-text-blue edp-font-14 ml-1 sap-bar">Save</a>
                                                        <a id="cancelClientVoiceTeamMemberLink_{{i}}"
                                                            (click)="cancelclientVoiceTeamMember(i)"
                                                            class="link-text-blue edp-font-14 ml-1 sap-bar">Cancel</a>
                                                    </span>

                                                </td>
                                            </tr>
                                        </tbody>


                                        <tbody *ngIf="dbClientVoiceTeamMemberCount > 0">
                                            <tr
                                                *ngFor="let member of engagementClientVoiceDetailViewModel.engagementClientVoiceTeamMemberList; let i=index">
                                                <td scope="row" class="text-break">{{member.firstName}}</td>
                                                <td scope="row" class="text-break">{{member.lastName}}</td>
                                                <td class="text-break">{{member.memberEmail}}</td>
                                                <td class="text-break">{{member.memberTitle}}</td>
                                                <td>
                                                    <a id="clientVoiceTeamMember_{{i}}" *ngIf="!isSurveryOptedCompleted"
                                                        (click)="removeClientVoiceTeamMember(i)">
                                                        <img class=""
                                                            src="assets/images/EDP/Engagement/remove_circle_outline.svg"
                                                            alt="remove" class="middle-align">
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row success-msg col-lg-12 justify-content-end d-flex "
                    *ngIf="engagementClientVoiceUIViewModel.isSurveySaved">
                    <p><b>Survey request sent successfully</b></p>
                </div>

                <div class="col-lg-12 justify-content-end d-flex mt-3 p-1 pr-4 button-text">
                    <button id="btnSurveyCancel" class="btn SecondaryButton w-40  text-center mr-3 btn-closeout"
                        type="button" (click)="toggleDown()">
                        Cancel
                    </button>
                    <button id="btnSurveySend" class="btn PrimaryButton w-40 text-center  pr-4 btn-closeout"
                        type="button" [disabled]="isSurveryOptedCompleted" (click)="sendSurvey()">
                        Send
                    </button>
                </div>
            </div>
            <!-- end on 1 screen-->

            <div class="row col-lg-6 " id="oncheck_clientvoice" *ngIf="engagementClientVoiceUIViewModel.isSurveyOptOut">
                <label>Please explain why a ClientVoice survey shouldn't be sent:</label>
                <textarea class="form-control" cols="10" rows="8" maxlength="500" id="txtoptOutReason"
                    [disabled]="isSurveryOptedCompleted" [(ngModel)]="engagementClientVoiceUIViewModel.optOutReason">
                        </textarea>
                <div class="form-control-feedback error-color row ml-2"
                    *ngIf="!engagementClientVoiceUIViewModel.isValidoptOutReason">
                    <p>Enter opt out reason</p>
                </div>
                <div class="row success-msg  ml-2" *ngIf="engagementClientVoiceUIViewModel.optOutReasonSaved">
                    <p>Client voice survey opted out successfully</p>
                </div>

                <div class="col-lg-12 justify-content-end d-flex  p-1 mt-3">
                    <button id="btnSurveyOptCancel" class="btn SecondaryButton w-40  text-center btn-closeout "
                        type="button" (click)="showHideclientvoiceOptDiv(false)" [disabled]="isSurveryOptedCompleted">
                        Cancel
                    </button>
                    <button id="btnSurveyOptSend" class="btn PrimaryButton w-40 text-center pl-4 btn-closeout pr-4 ml-3"
                        type="button" [disabled]="isSurveryOptedCompleted" (click)="SurveyOptSend()">
                        Save
                    </button>
                </div>

            </div>

        </div>

    </div>
</div>