import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { User } from '../../model/User';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { listviewService } from '../../services/listviewService';
import { KeyValue } from '../../model/KeyValue';
import { DataService } from 'src/app/services/data.service';
import { NavigationIdWithGreyImageNames, NavigationIdWithPurpleImageNames } from '../leftnav/leftnav.component';
import { EngagementService } from 'src/app/services/EngagementService';

declare var $;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  providers: [listviewService]
})

export class NavbarComponent implements OnInit, OnDestroy {

  @Output() onComplete = new EventEmitter<string>();
  private roleLoadEndRef: Subscription = null;
  userName = '';
  isDisplayConfiguration: boolean = false;
  user: User;
  isUserAgentIE: number;
  serviceLines: KeyValue[] = [];
  searchTypes: KeyValue[] = [];
  selectedServiceLine: string;
  selectedSearchType: string;
  searchTerm: string;
  replacedImageBasePath = '/assets/images/EDP/LeftSidebar/purple/';
  originalImageBasePath = '/assets/images/EDP/LeftSidebar/grey/';
  purpleImageFolder = 'purple'
  greyImageFolder = 'grey'
  imageType = '.svg';
  originalImagePath = '';
  newImagePath = '';
  originalImgClass = 'imgchange';
  newImgClass = 'imgReplace';
  @Output() onSignOut = new EventEmitter<string>();
  isCollapsibleLeftNavVisible: boolean;
  subscription: Subscription;
  subCloseReviewNotes: Subscription;
  isReviewNotesClose: boolean;
  isMemberFirm: boolean = false;
  userObject: any;
  globalSearchPlaceholderText: string = "  Search";

  constructor(private private_authService: AuthService,
    private engService: EngagementService,
    private route: Router, private _listviewService: listviewService, private dataService: DataService) {

    this.user = private_authService.getUserInfo();
    if (this.user != null) {
      if (this.user.userType == "Guest") {
        this.userName = (this.user.firstName + ' ' + this.user.lastName).replace(',','');
      }
      else {
        this.userName = this.user.firstName + ' ' + this.user.lastName;
      }
    }
    const IEversion = localStorage.getItem('isUserAgentIE');
    this.isUserAgentIE = JSON.parse(IEversion);
    // console.log('IE-version=> ' + this.isUserAgentIE);
    this.searchTerm = '';
    this.selectedSearchType = '';
    this.selectedServiceLine = '';

    const jsonString = localStorage.getItem('currentUser');
    this.userObject = JSON.parse(jsonString);
    
    
  }

  ngOnInit() {
    this.GetServiceLines();
    this.GetSearchTypes();
    this.roleLoadEndRef = this.private_authService.roleLoadEnd$.subscribe((value) => {
      // console.log('ngOnInit func called of Navbar Component ', value);
      this.IsLoggedinUserSystemAdmin();
    });
    setTimeout(() => {
      $('#configLink').click(function () {
        var menuitems = $('.nav-tabs> #sideMenuItem');
        var childMenu = $(menuitems).children();
        $(childMenu).each(function (i, obj) {
          if ($(obj).hasClass('active')) {
            $(obj).removeClass('active');

          }
        });
      });
      $('#main-app').click(function () {
        $('#txtGlobalSearch').val('');
      });
      $('.nav-header').click(function () {
        $('#txtGlobalSearch').val('');
      });
      $('.user-icon').click(function () {
        $('#txtGlobalSearch').val('');
      });
      $('.nav-SettingIcon').click(function () {
        $('#txtGlobalSearch').val('');
      });

    }, 50);

    this.subscription =
      this.dataService.isCollapsibleLeftNavVisible$.subscribe(value => {
        this.isCollapsibleLeftNavVisible = value
        if (!this.isCollapsibleLeftNavVisible) {
          this.collapseLeftNavPanel(false);
        }
        else {
          this.collapseLeftNavPanel(true);
        }
      });
    this.subCloseReviewNotes =
      this.dataService.isCloseReviewNotes$.subscribe(value => {
        this.isReviewNotesClose = value
      });
      this.setIsMemberFirm();

  } // end ngOnInit

  ngOnDestroy() {
    this.roleLoadEndRef.unsubscribe();
    this.subscription.unsubscribe();
    this.subCloseReviewNotes.unsubscribe();
  }

  IsLoggedinUserSystemAdmin() {
    if (this.user != null && this.user.role != null && this.user.role.length > 0) {
      if (!this.isDisplayConfiguration) {
        for (let i = 0; i < this.user.role.length; i++) {
          if (this.user.role[i].shortRoleName === 'SA') {
            this.isDisplayConfiguration = true;
            break;
          }
        }
      }
    }
  }

  SignOut() {
    this.onSignOut.emit('sign out');
  }

  Search() {
    if (!this.isReviewNotesClose)
      $('.margin-all').removeClass('compressDiv');

    if (this.isCollapsibleLeftNavVisible)
      this.dataService.setCloseReviewNotes(true);

    if (this.route.url.indexOf('Search') !== -1) {
      let serviceLine = $('#ddGServiceLine').val();
      let searchType = $('#ddGSearchTypesDropdown').val();
      $('#ddServiceLine').val(serviceLine);
      $('#ddSearchTypesDropdown').val(searchType);
      let searchTerm = $('#txtGlobalSearch').val();
      $('#txtSearchTerm').val(searchTerm);
      let searchObj: {};
      searchObj = {
        ServiceLine: serviceLine,
        SearchType: searchType,
        SearchTerm: searchTerm
      };
      this.dataService.triggerGlobalSearch(searchObj);
    } else {
      this.route.navigate(['/Search']);
    }
  }

  GetServiceLines() {
    this._listviewService.GetServiceLines().subscribe(services => {
      this.serviceLines = services;
    });
  }

  GetSearchTypes() {
    this._listviewService.GetSearchTypes().subscribe(stypes => {
      this.searchTypes = stypes;
    });
  }

  DelegateApprovals() {
    const dialogData = {
      dialogTitle: 'Display Delegate',
      displayDialog: true
    };
    const dialogJSON = JSON.stringify(dialogData);
    this.dataService.setDelegateDialogDetails(dialogJSON);
  }

  RemoveLeftNavActiveTabs() {
    let oldpath = $('.' + this.newImgClass).attr('src');
    if (oldpath?.includes(this.purpleImageFolder)) {
      let imageName = oldpath.substr(this.replacedImageBasePath.length, oldpath.length).split('.')[0];
      let purpleImageNamesKey = Object.keys(NavigationIdWithPurpleImageNames)[Object.values(NavigationIdWithPurpleImageNames).indexOf(imageName)];
      let purpleImageNamesValue = NavigationIdWithGreyImageNames[purpleImageNamesKey]
      this.originalImagePath = this.originalImageBasePath + purpleImageNamesValue + this.imageType;
      $('.' + this.newImgClass).attr('src', this.originalImagePath);
      if ($('.' + this.newImgClass).parent('a').hasClass('active'))
        $('.' + this.newImgClass).parent('a').removeClass('active')
    }
  }

  CloseReviewNotesPanel() {
    if (this.isCollapsibleLeftNavVisible)
      this.dataService.setCloseReviewNotes(true);
    $('body').removeClass('modal-open');
    this.RemoveLeftNavActiveTabs();
  }

  collapseLeftNav() {
    if ($('.cardMenuName').hasClass('collapsibleLeftNav')) {
      this.collapseLeftNavPanel(false);
    }
    else {
      this.collapseLeftNavPanel(true);
    }
  }

  collapseLeftNavPanel(isCollapse: boolean) {
    if (!isCollapse) {
      $('.cardMenuName').removeClass('collapsibleLeftNav');
      $('.iWantToParent').removeClass('collapsibleIwanto');
      $('#IwanttoDiv').removeClass('collapsibleIwantoHide');
      $('.nav-col').removeClass('showDivCollapsible');
      $('#sideMenuParent').removeClass('sideMenuBar');
      $('.margin-all').removeClass('removeMargin');
      $('.divImgIwanTo').removeClass('collapsibleImageIwantTo');
      $('.collapse-copy-small').removeClass('collapsibleCopyRight');
      $('.collapse-copy-large').removeClass('collapsibleCopyRightHide');
      $('.margin-all').removeClass('compressDiv');
      $('.containerDiv').removeClass('compresscontainerDiv');
    }
    else {
      $('.cardMenuName').addClass('collapsibleLeftNav');
      $('.iWantToParent').addClass('collapsibleIwanto');
      $('#IwanttoDiv').addClass('collapsibleIwantoHide');
      $('.nav-col').addClass('showDivCollapsible');
      $('#sideMenuParent').addClass('sideMenuBar');
      $('.margin-all').addClass('removeMargin');
      $('.divImgIwanTo').addClass('collapsibleImageIwantTo');
      $('.collapse-copy-small').addClass('collapsibleCopyRight');
      $('.collapse-copy-large').addClass('collapsibleCopyRightHide');
      if (this.isCollapsibleLeftNavVisible) {
        $('.margin-all').addClass('compressDiv');
        $('.containerDiv').addClass('compresscontainerDiv');
      }
    }
  }
  setIsMemberFirm() {
    this.isMemberFirm = this.userObject.isMemberFirmFeatureEnabled;
  }
}
