<app-loading-screen></app-loading-screen>
<div class="row pageShortcutParent" *ngIf="iShortcutCount > 6">
  <div class="col-md-12">
    <app-shortcut></app-shortcut>
  </div>
</div>

<div class="row pb-3 mx-0 mt-3" [class.pageTitleParentExtraMargin]="iShortcutCount > 6"
  [class.pageTitleParent]="iShortcutCount <= 6">
  <div class="col-md-10 pl-0">
    <h2 class="pageTitle largerFontSize2">My clients</h2>
  </div>
  <div class="col-md-2 pr-0" *ngIf="iShortcutCount <= 6">
    <app-shortcut></app-shortcut>
  </div>
</div>

<div class="bg-edp-body card-container position-relative">
  <!-- List View and card view main container start -->
  <!--ListView Design Start-->
  <div class="Eng-ListView-Div" style="display:none">
    <div class="d-flex p-2">
      <div class="mr-auto ml-1">
        <div class="d-flex flex-row bd-highlight">
          <a id="filterListView" class="anchoroutline filter-tab" (click)="ShowHideListFilterPanel('Show')" data-placement="bottom">
            Filter
          </a>
          <a id="showAllListView" class="anchoroutline showall-tab" (click)="ShowAllClientsWithNoFilters('ListView')" data-placement="bottom">
            Show all
          </a>
        </div>
      </div>
      <!-- Toggle buttons Start -->
      <div class="ml-auto mr-1">
        <div class="d-flex flex-row bd-highlight">
          <a class="ShowCardViewjs px-3 py-1 anchoroutline" (click)="ToggleListAndCardView('Card');">
            <img class="CardViewImg pointerCursor shortcutimg"
              src="../../../../assets/images/EDP/Filters/apps-unselect.svg" alt="Card View" />
          </a>
          <a class="ShowListViewjs px-3 py-1 anchoroutline active" (click)="ToggleListAndCardView('List');">
            <img class="ListViewImgEng pointerCursor shortcutimg"
              src="../../../../assets/images/EDP/Filters/list_alt.svg" alt="List View" />
          </a>
        </div>
      </div>
      <!--  Toggle buttons End -->
    </div>
<!-- Accordian List view start -->
<section id="listViewFilterSection" class="filter-panel clientDashboard" style="display: none;">
  <div class="row m-0 bg-edp-body">
    <div class="col-md-12">
      <div class="row m-0 pb-4">
        <div class="col-lg-3 col-md-4 filter-partition">
          <label class="font-weight-bold mb-1">Client name</label>
          <div >
            <app-multi-select-dropwdown-prime [clientFilterModel]="clientFilterModel" [sourceData]= "clientNameSource" 
            [optionLable] ="optionLabelClientName"  [optionValue] ="optionValueClientName"
            [optionHeader]="optionHeaderClientName" (onItemValueChange)="onItemValueChange($event)" >
           </app-multi-select-dropwdown-prime>
            <!-- <select id="ddClientsForClientsList" [(ngModel)]="clientListViewFilterModel.clientId"
              class="dropdown rounded-0 form-control" name="ddClientsForClientsList">
              <option value="0" disabled hidden>Select client</option>
              <option *ngFor="let clients of clientFilterModel" [value]="clients.key">
                {{clients.value}}
              </option>
            </select> -->
          </div>
        </div>
        <div class="col-lg-2 col-md-4 filter-partition">
          <label class="font-weight-bold mb-1">Industry</label>
          <div >
            <app-multi-select-dropwdown-prime [clientFilterModel]="clientIndustryTypeList" [sourceData]= "industrySource" 
            [optionLable] ="optionLabelIndustryName"  [optionValue] ="optionValueIndustryName" 
            (onItemValueChange)="onItemValueChange($event)" [optionHeader]="optionHeaderIndustryName">
           </app-multi-select-dropwdown-prime>
            <!-- <select id="ddClientIndustryTypeList" [(ngModel)]="clientListViewFilterModel.industryTypeId"
              class="dropdown rounded-0 form-control" name="ddClientIndustryTypeList">
              <option value="0" disabled hidden>Select industry</option>
              <option *ngFor="let industryType of clientIndustryTypeList" [value]="industryType.industryTypeId">
                {{industryType.industryTypeName}}
              </option>
            </select> -->
          </div>
        </div>
        <div class="col-lg-2 col-md-4 filter-partition partition-md-remove">
          <label class="font-weight-bold mb-1">Client status</label>
          <div>
            <app-multi-select-dropwdown-prime [clientFilterModel]="clientStatusList" 
            [sourceData]= "clientStatusSource" 
            [optionLable] ="optionLabelClientStatus"  [optionValue] ="optionValueClientStatus" 
            (onItemValueChange)="onItemValueChange($event)" [optionHeader]="optionHeaderClientStatusName">
           </app-multi-select-dropwdown-prime>
            <!-- <select id="ddClientStatusList" [(ngModel)]="clientListViewFilterModel.clientStatusId"
              class="dropdown rounded-0 form-control" name="ddClientStatusList">
              <option value="0" disabled hidden>Select client status</option>
              <option *ngFor="let clientStatus of clientStatusList" [value]="clientStatus.clientStatusId">
                {{clientStatus.clientStatusName}}
                
              </option>
            </select> -->
          </div>
        </div>
        <div class="col-lg-2 col-md-4 filter-partition mt-md-3 mt-lg-0">
          <label class="font-weight-bold mb-1">Client program</label>
          <div>
            <app-multi-select-dropwdown-prime [clientFilterModel]="clientProgramList" 
            [sourceData]= "clientProgramSource" 
            [optionLable] ="optionLabelProgramList"  [optionValue] ="optionValueProgramList" 
            (onItemValueChange)="onItemValueChange($event)"  [optionHeader]="optionHeaderClientProgramName">
           </app-multi-select-dropwdown-prime>
            <!-- <select id="ddClientProgramList" [(ngModel)]="clientListViewFilterModel.programId"
              class="dropdown rounded-0 form-control" name="ddClientProgramList">
              <option value="0" disabled hidden>Select client program</option>
              <option *ngFor="let clientProgram of clientProgramList" [value]="clientProgram.clientProgramId">
                {{clientProgram.clientProgramName}}
              </option>
            </select> -->
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mt-md-3 mt-lg-0">
          <label class="font-weight-bold mb-1">Structure/entity</label>
          <div>
            <app-multi-select-dropwdown-prime [clientFilterModel]="clientStructEntityList" 
            [sourceData]= "structEntitySource" 
            [optionLable] ="optionLabelStructEntity"  [optionValue] ="optionValueStructEntity"
             (onItemValueChange)="onItemValueChange($event)"  [optionHeader]="optionHeaderStructName">
           </app-multi-select-dropwdown-prime>
            <!-- <select id="ddClientStructEntityList" [(ngModel)]="clientListViewFilterModel.structEntityId"
              class="dropdown rounded-0 form-control" name="ddClientStructEntityList">
              <option value="0" disabled hidden>Select structure/entity</option>
              <option *ngFor="let structEntity of clientStructEntityList" [value]="structEntity.structEntityId">
                {{structEntity.structEntityName}}
              </option>
            </select> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row align-self-end text-right m-0 pr-2">
      <div class="col-lg-8 col-md-4 filter-partition">
        <button class="SecondaryButton filter-button-cnt" id="resetListViewFiliter"
          (click)="ShowHideListFilterPanel('Reset')" type="button"><span
            class="d-inline-block align-middle">Reset</span></button>
      </div>
      <div class="col-lg-2 col-md-4 mt-md-0 mt-lg-0 px-3">
        <button class="SecondaryButton filter-button-cnt" (click)="ShowHideListFilterPanel('Hide')" type="button"><span
            class="d-inline-block align-middle">Cancel</span></button>
      </div>
      <div class="col-lg-2 col-md-4 mt-md-0 mt-lg-0 pl-2">
        <button class="PrimaryButton mr-2 filter-button-cnt" (click)="GetAllClientsListByFilters()" type="button"><span
            class="d-inline-block align-middle">Apply</span></button>
      </div>
    </div>
  </div>
</section>
<!-- Accordian List view end -->
    <div class="MainContainer ml-3 mr-2 my-3">
      <div class="tableData1">
        <div class="tableData2">
          <table class="listViewDataTable ResponsiveDataTable">
            <thead>
              <tr>
                <th scope="col" class="table-sorted-desc">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[0].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[0].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="HeaderSort(headerModelList[0].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img alt="image_{{img.imageName}}" src="{{img.imagePath}}" />
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[1].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[1].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="HeaderSort(headerModelList[1].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img alt="image_{{img.imageName}}" src="{{img.imagePath}}" />
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[2].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[2].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="HeaderSort(headerModelList[2].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img alt="image_{{img.imageName}}" src="{{img.imagePath}}" />
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-md-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[3].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[3].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="HeaderSort(headerModelList[3].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img alt="image_{{img.imageName}}" src="{{img.imagePath}}" />
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-md-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[4].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[4].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="HeaderSort(headerModelList[4].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img alt="image_{{img.imageName}}" src="{{img.imagePath}}" />
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-md-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[5].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[5].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="HeaderSort(headerModelList[5].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img alt="image_{{img.imageName}}" src="{{img.imagePath}}" />
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-md-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[6].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[6].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="HeaderSort(headerModelList[6].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img alt="image_{{img.imageName}}" src="{{img.imagePath}}" />
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-md-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[7].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[7].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="HeaderSort(headerModelList[7].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img alt="image_{{img.imageName}}" src="{{img.imagePath}}" />
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody class="display" *ngIf="listCount > 0">
              <tr class="searchDatajs" *ngFor="let list of myClientslistViewList">
                <td data-label="Client Name:">
                  <a routerLink="/ClientDetails/{{list.clientId}}" class="link">
                    {{ list.clientName}}
                  </a>
                </td>
                <td data-label="Client ID:"> {{list.clientCode}} </td>
                <td data-label="Type :">{{list.publicPrivate}}</td>
                <td data-label="Program:" class="t-md-col">{{list.clientProgram}}</td>
                <td data-label="Engagements:" class="text-center t-md-col"> {{list.activeEngagementCount }} </td>
                <td data-label="Service Lines:" class="t-md-col">
                  <div class="client-link">
                    <span>{{list.serviceLineList}}</span>
                  </div>
                </td>
                <td data-label="Last Activity Date:" class="t-md-col"> {{list.lastActivityDate | date: "MM/dd/yyyy"}} </td>
                <td data-label="Service Lines:" class="t-md-col"> {{list.clientStatus }} </td>
              </tr>
            </tbody>
            <div *ngIf="listCount == 0 || listCount == null">
                <div class="no-data-available no-data">{{listDataMessage}}</div>
            </div>
          </table>

          <!-- pager start-->
          <!-- pager End-->
        </div>
      </div>
      <!-- ListView Pagination Start -->
      <div class="d-flex justify-content-center text-center pagination-section" *ngIf="listCount > 0">
        <ul *ngIf="pager.pages && pager.pages.length" class="pagination mx-2">
          <li>
            <a id="first" [ngClass]="{isDisabled:pager.currentPage === 1}" (click)="listViewSetPage(1)">
              <img alt="First arrow" src="../../../../assets/images/EDP/Engagement/first_arrow.png" />
            </a>
          </li>
          <li>
            <a id="previous" [ngClass]="{isDisabled:pager.currentPage === 1}"
              (click)="listViewSetPage(pager.currentPage - 1)">
              <img alt="Previous arrow" src="../../../../assets/images/EDP/Engagement/previous_arrow.png" />
            </a>

          </li>
          <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
            <a (click)="listViewSetPage(page)">{{page}}</a>
          </li>

          <li>
            <a [ngClass]="{isDisabled:pager.currentPage === pager.totalPages}"
              (click)="listViewSetPage(pager.currentPage + 1)">
              <img alt="Next arrow" src="../../../../assets/images/EDP/Engagement/next_arrow.png" />
            </a>
          </li>
          <li>
            <a [ngClass]="{isDisabled:pager.currentPage === pager.totalPages}"
              (click)="listViewSetPage(pager.totalPages)">
              <img alt="Last arrow" src="../../../../assets/images/EDP/Engagement/last_arrow.png" />
            </a>
          </li>

        </ul>
        <div>
          <select id="drprecordsPerPage" [(ngModel)]="listViewnoOfrecordsPerPage" name="drprecordsPerPage" (change)="listViewSetPage(1)" class="page-counts">
            <option *ngFor="let listViewnoOfrecordsPerPage of noOfrecordsPerPageList" value="{{listViewnoOfrecordsPerPage}}">{{listViewnoOfrecordsPerPage}}</option>
          </select>
        </div>

      </div>
      <!-- ListView Pagination End -->
    </div>
    <!-- MainContainer End -->
  </div>
  <!--ListView Design End-->

  <!--CardView Design Start-->
  <div class="Eng-CardView-Div">
    <div class="d-flex p-2">
      <div class="mr-auto ml-1">
        <ul class="d-flex flex-row bd-highlight">
          <li id="filterCardView" class="anchoroutline filter-tab" (click)="ShowHideFilterPanel('Show')" data-placement="bottom">
            Filter
          </li>
          <li id="sortCardView" class="anchoroutline sort-tab" (click)="ShowHideFilterSortPanel('Show')" data-placement="bottom">
            Sort
          </li>
          <li id="showAllCardView" class="anchoroutline showall-tab" (click)="ShowAllClientsWithNoFilters('CardView')" data-placement="bottom">
            Show all
          </li>
        </ul>
      </div>
      <!-- Toggle buttons Start -->
      <div class="ml-auto mr-1">
        <div class="d-flex flex-row bd-highlight">
          <a class="ShowCardViewjs px-3 py-1 anchoroutline active" (click)="ToggleListAndCardView('Card');">
            <img alt="Grid on" class="CardViewImg pointerCursor shortcutimg active"
              src="../../../../assets/images/EDP/Filters/grid_on.svg" alt="Card View" />
          </a>
          <a class="ShowListViewjs px-3 py-1 anchoroutline" (click)="ToggleListAndCardView('List');">
            <img alt="List unselected" class="ListViewImgEng pointerCursor shortcutimg"
              src="../../../../assets/images/EDP/Filters/list_alt-unselected.svg" alt="List View" />
          </a>
        </div>
      </div>
      <!--  Toggle buttons End -->
    </div>
<!-- Accordian view start -->
<section id="cardViewFilterSection" class="filter-panel clientDashboard" style="display: none;">
  <div class="row m-0 bg-edp-body">
    <div class="col-md-12">
      <div class="row m-0 pb-4">
        <div class="col-lg-3 col-md-4 filter-partition">
          <label class="font-weight-bold mb-1">Client name</label>
          <div>
            <!-- <app-multi-select-dropdown   [clientFilterModel]="clientFilterModel" [settings]="clientSettings"
            [sourceData]= "clientNameSource" (onItemValueSelect)="onItemValueSelect($event)"  (onItemValueUnSelect)="onItemValueUnSelect($event)" >
            </app-multi-select-dropdown> -->

            <app-multi-select-dropwdown-prime [clientFilterModel]="clientFilterModel" 
            [sourceData]= "clientNameSource" 
             [optionLable] ="optionLabelClientName"  [optionValue] ="optionValueClientName" 
             [optionHeader]="optionHeaderClientName" (onItemValueChange)="onItemValueChange($event)" >
            </app-multi-select-dropwdown-prime>
            <!-- <select id="ddClientsForCardClientsList" [(ngModel)]="clientCardViewFilterModel.clientId"
              class="dropdown rounded-0 form-control" name="ddClientsForCardClientsList">
              <option value="0" disabled hidden>Select client</option>
              <option *ngFor="let clients of clientFilterModel" [value]="clients.key">
                {{clients.value}}
              </option>
            </select> -->
          </div>
        </div>
        <div class="col-lg-2 col-md-4 filter-partition">
          <label class="font-weight-bold mb-1">Industry</label>
          <div >
            <!-- <app-multi-select-dropdown   [clientFilterModel]="clientIndustryTypeList" [settings]="industrySettings"
            [sourceData]= "industrySource" (onItemValueSelect)="onItemValueSelect($event)"  (onItemValueUnSelect)="onItemValueUnSelect($event)" >
            </app-multi-select-dropdown> -->


            <app-multi-select-dropwdown-prime [clientFilterModel]="clientIndustryTypeList"
             [sourceData]= "industrySource" 
            [optionLable] ="optionLabelIndustryName"  [optionValue] ="optionValueIndustryName" 
            (onItemValueChange)="onItemValueChange($event)" [optionHeader]="optionHeaderIndustryName">
           </app-multi-select-dropwdown-prime>

            <!-- <p-multiSelect [options]="clientIndustryTypeList" [(ngModel)]="clientCardViewFilterModel.industryTypeId" defaultLabel="Select a City" optionLabel="name">
            </p-multiSelect> -->
            <!-- <app-multi-select-dropwdown-prime   [clientFilterModel]="clientIndustryTypeList" [settings]="industrySettings"
            [sourceData]= "industrySource" (onItemValueSelect)="onItemValueSelect($event)"  (onItemValueUnSelect)="onItemValueUnSelect($event)" >
            </app-multi-select-dropwdown-prime> -->
            <!-- <select id="ddClientIndustryTypeCardList" [(ngModel)]="clientCardViewFilterModel.industryTypeId"
              class="dropdown rounded-0 form-control" name="ddClientIndustryTypeCardList">
              <option value="0" disabled hidden>Select industry</option>
              <option *ngFor="let industryType of clientIndustryTypeList" [value]="industryType.industryTypeId">
                {{industryType.industryTypeName}}
              </option>
            </select> -->
          </div>
        </div>
        <div class="col-lg-2 col-md-4 filter-partition partition-md-remove">
          <label class="font-weight-bold mb-1">Client status</label>
          <div >
            <!-- <app-multi-select-dropdown   [clientFilterModel]="clientStatusList" [settings]="clientStatusSettings"
            [sourceData]= "clientStatusSource" (onItemValueSelect)="onItemValueSelect($event)"  (onItemValueUnSelect)="onItemValueUnSelect($event)" >
            </app-multi-select-dropdown> -->
            <app-multi-select-dropwdown-prime [clientFilterModel]="clientStatusList" 
            [sourceData]= "clientStatusSource" 
            [optionLable] ="optionLabelClientStatus"  [optionValue] ="optionValueClientStatus" 
            (onItemValueChange)="onItemValueChange($event)" [optionHeader]="optionHeaderClientStatusName">
           </app-multi-select-dropwdown-prime>
            <!-- <select id="ddClientStatusCardList" [(ngModel)]="clientCardViewFilterModel.clientStatusId"
              class="dropdown rounded-0 form-control" name="ddClientStatusCardList">
              <option value="0" disabled hidden>Select client status</option>
              <option *ngFor="let clientStatus of clientStatusList" [value]="clientStatus.clientStatusId">
                {{clientStatus.clientStatusName}}
              </option>
            </select> -->
          </div>
        </div>
        <div class="col-lg-2 col-md-4 mt-md-3 mt-lg-0 filter-partition">
          <label class="font-weight-bold mb-1">Client program</label>
          <div >
            <!-- <app-multi-select-dropdown   [clientFilterModel]="clientProgramList" [settings]="clientProgramSettings"
            [sourceData]= "clientProgramSource" (onItemValueSelect)="onItemValueSelect($event)"  (onItemValueUnSelect)="onItemValueUnSelect($event)" >
            </app-multi-select-dropdown> -->
            <app-multi-select-dropwdown-prime [clientFilterModel]="clientProgramList" 
            [sourceData]= "clientProgramSource" 
            [optionLable] ="optionLabelProgramList"  [optionValue] ="optionValueProgramList" 
            [optionHeader]="optionHeaderClientProgramName"
            (onItemValueChange)="onItemValueChange($event)" >
           </app-multi-select-dropwdown-prime>
            <!-- <select id="ddClientProgramCardList" [(ngModel)]="clientCardViewFilterModel.programId"
              class="dropdown rounded-0 form-control" name="ddClientProgramCardList">
              <option value="0" disabled hidden>Select client program</option>
              <option *ngFor="let clientProgram of clientProgramList" [value]="clientProgram.clientProgramId">
                {{clientProgram.clientProgramName}}
              </option>
            </select> -->
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mt-md-3 mt-lg-0">
          <label class="font-weight-bold mb-1">Structure/entity</label>
          <div>
            <!-- <app-multi-select-dropdown   [clientFilterModel]="clientStructEntityList" [settings]="structEntitySettings"
            [sourceData]= "structEntitySource" (onItemValueSelect)="onItemValueSelect($event)"  (onItemValueUnSelect)="onItemValueUnSelect($event)" >
            </app-multi-select-dropdown> -->
            <app-multi-select-dropwdown-prime [clientFilterModel]="clientStructEntityList" 
            [sourceData]= "structEntitySource" 
            [optionLable] ="optionLabelStructEntity"  [optionValue] ="optionValueStructEntity" 
            (onItemValueChange)="onItemValueChange($event)" [optionHeader]="optionHeaderStructName">
           </app-multi-select-dropwdown-prime>
            <!-- <select id="ddClientStructEntityCardList" [(ngModel)]="clientCardViewFilterModel.structEntityId"
              class="dropdown rounded-0 form-control" name="ddClientStructEntityCardList">
              <option value="0" disabled hidden>Select structure/entity</option>
              <option *ngFor="let structEntity of clientStructEntityList" [value]="structEntity.structEntityId">
                {{structEntity.structEntityName}}
              </option>
            </select> -->
          </div>
        </div>
      </div>
    </div>  
    <div class="row m-0 pb-4">
      <div class="col-lg-8 col-md-4  align-self-end text-right m-0 pr-3 filter-partition">
        <button type="button" id="resetCardViewFiliter" (click)="ShowHideFilterPanel('Reset')" 
        class="SecondaryButton filter-button-cnt">
          <span class="d-inline-block align-middle">Reset</span>
        </button>
      </div>
      <div class="col-lg-2 col-md-4 mt-md-0 mt-lg-0 px-3">
        <button type="button" id="cancelCardViewFiliter" (click)="ShowHideFilterPanel('Hide')" 
        class="SecondaryButton filter-button-cnt"
          style="margin-right: 15px;">
          <span class="d-inline-block align-middle">Cancel</span>
        </button>
      </div>
      <div class="col-lg-2 col-md-4 mt-md-0 mt-lg-0 px-2">
        <button type="button" id="applyCardViewFiliter" (click)="GetAllClientsByFilters()" 
        class="PrimaryButton filter-button-cnt mr-2">
          <span class="d-inline-block align-middle">Apply</span>
        </button>  
      </div>
    </div>  
  </div>
</section>
<!-- Accordian view end -->
<!-- Accordian sort card view start -->
<section id="cardViewSortSection" class="filter-panel" style="display: none;">
  <div class="row m-0 bg-edp-body">
  <div class="col-md-12">
    <div class="row m-0 pb-2">
      <div class="col-xl-2 col-md-3 pr-0 filter-partition">
        <div class="custom-control custom-checkbox">
          <input id="chkClientName" 
                 [(ngModel)]="clientCardViewFilterModel.sortClientId"
                 type="checkbox" name="chkClientName"
                 class="custom-control-input">
          <label class="custom-control-label font-weight-bold" for="chkClientName"> Client name</label>
        </div>          
        <div class="custom-control custom-checkbox">
          <input id="chkClientProgram" 
                 [(ngModel)]="clientCardViewFilterModel.sortProgramId"
                 type="checkbox" name="chkClientProgram"
                 class="custom-control-input" >
          <label class="custom-control-label font-weight-bold" for="chkClientProgram">Program</label>
        </div>              
        <div class="custom-control custom-checkbox">
          <input id="chkClientStatus" 
                 [(ngModel)]="clientCardViewFilterModel.sortClientStatusId"
                 type="checkbox" name="chkClientStatus"
                 class="custom-control-input" >
          <label class="custom-control-label font-weight-bold" for="chkClientStatus">Status</label>
        </div>
        <div class="custom-control custom-checkbox">
          <input id="chkLastActivityDate" 
                 [(ngModel)]="clientCardViewFilterModel.sortLastActivityDate"
                 type="checkbox" name="chkLastActivityDate"
                 class="custom-control-input" >
          <label class="custom-control-label font-weight-bold" for="chkLastActivityDate">Last activity date</label>
        </div>
      </div>
      <div class="col-xl-2 col-md-3 pl-md-3 pl-xl-3">
        <label class="font-weight-bold mb-1" for="chkLastActivityDate">Order by type</label>
        <div class="selectdiv">
          <select id="ddListOrderByType" class="dropdown rounded-0 form-control" [(ngModel)]="sortOrderByType"
                    name="ddListOrderByType">
              <option value="DESC">Descending</option>
              <option value="ASC">Ascending</option>
                          
          </select>
        </div>
      </div>
      <div class="col-xl-2 col-md-2"></div>
      
      <div class="col-xl-6 col-md-12 col-lg-12 mt-md-4 mt-2">
        <div class="row mb-0 mt-4 mx-0 p-0">
          <div class="col-lg-8 col-xl-4 col-md-4 filter-partition px-2 align-self-end text-right">
            <button type="button" id="resetCardViewSortFiliter" (click)="ShowHideFilterSortPanel('Reset')"
              class="SecondaryButton filter-button-cnt mr-1">
              <span class="d-inline-block align-middle">Reset</span>
            </button>
          </div>
          <div class="col-lg-2 col-xl-4 col-md-4 mt-md-3 mt-lg-0 px-2 align-self-end text-left">
            <button type="button" (click)="ShowHideFilterSortPanel('Hide')" 
            class="SecondaryButton filter-button-cnt ml-1">
              <span class="d-inline-block align-middle">Cancel</span>
            </button>
          </div>
          <div class="col-lg-2 col-md-4 mt-md-3 mt-lg-0 pl-2 align-self-end text-left">
            <button type="button" (click)="GetAllSortedClientsByFilters()"
            class="PrimaryButton filter-button-cnt">
              <span class="d-inline-block align-middle">Apply</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>  
</div>
</section>
<!-- Accordian sort card view end -->
    <div class="d-flex p-0">
      <div class="card-deck w-100 mx-0">
        <div class="card client-data-card position-relative" *ngFor="let list of myClientsCardList">
          <a routerLink="/ClientDetails/{{list.clientId}}">
            <div class="card-body text-align-left ">
              <div class="CardViewContent">
                <ul class="list-group ">
                  <li class="list-group-item">
                    <div class="client-link">
                      <span [class]="(list?.isAdvisory)?'advisoryTaxGreen':''">Advisory</span>
                      &nbsp;
                      <span [class]="(list?.isAudit)?'advisoryTaxGreen':''">Audit</span>
                      &nbsp;
                      <span [class]="(list?.isTax)?'advisoryTaxGreen':''">Tax</span>
                    </div>
                    <a *ngIf="list.clientName" class="card-status">{{list.clientStatus }}</a>
                  </li>
                  <li class="list-group-item">
                    <span class="CardViewText CardViewHeaderFont">
                      {{ list.clientName}}</span>
                  </li>
                  <li class="list-group-item">
                    <span class="SolutionCodejsForFilter">{{ list.clientCode }}</span>
                  </li>
                  <li class="list-group-item">
                    <span>Type:</span>
                    <span class="SolutionCodejsForFilter font-weight-bold pl-1">{{ list.publicPrivate }}</span>
                  </li>
                  <li class="list-group-item">
                    <span>Program:</span>
                    <span class="StatusjsForFilter font-weight-bold pl-1">{{ list.clientProgram }}</span>
                  </li>
                  <li class="list-group-item">
                    <span>Active engagements:</span>
                    <span class="StatusjsForFilter font-weight-bold pl-1">
                      {{list.activeEngagementCount}}
                    </span>
                  </li>
                </ul>
              </div>
              <div class="last-eng-activity">
                <span class="float-left">Last client activity:</span>
                <span class="pl-1">{{ list.lastActivityDate | date: "MM/dd/yyyy"}}</span>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div id="NoDataMessage" class="action-label" *ngIf="cardCount == 0 || cardCount == null ">
       {{DataMessage}}
      </div>
    </div>
    <!-- CardView Pagination Start -->
    <div class="d-flex justify-content-center text-center pagination-section" *ngIf="cardCount > 0">
      <ul *ngIf="cardViewPager.pages && cardViewPager.pages.length" class="pagination mx-2">
        <li>
          <a id="cardViewFirst" [ngClass]="{isDisabled:cardViewPager.currentPage === 1}" (click)="cardViewSetPage(1)">
            <img alt="First arrow" src="../../../../assets/images/EDP/Engagement/first_arrow.png" />
          </a>
        </li>
        <li>
          <a id="cardViewPrevious" [ngClass]="{isDisabled:cardViewPager.currentPage === 1}"
            (click)="cardViewSetPage(cardViewPager.currentPage - 1)">
            <img alt="Previous arrow" src="../../../../assets/images/EDP/Engagement/previous_arrow.png" />
          </a>

        </li>
        <li *ngFor="let page of cardViewPager.pages" [ngClass]="{active:cardViewPager.currentPage === page}">
          <a (click)="cardViewSetPage(page)">{{page}}</a>
        </li>

        <li>
          <a [ngClass]="{isDisabled:cardViewPager.currentPage === cardViewPager.totalPages}"
            (click)="cardViewSetPage(cardViewPager.currentPage + 1)">
            <img alt="Next row" src="../../../../assets/images/EDP/Engagement/next_arrow.png" />
          </a>
        </li>
        <li>
          <a [ngClass]="{isDisabled:cardViewPager.currentPage === cardViewPager.totalPages}"
            (click)="cardViewSetPage(cardViewPager.totalPages)">
            <img alt="Last row" src="../../../../assets/images/EDP/Engagement/last_arrow.png" />
          </a>
        </li>

      </ul>
      <div>
        <select id="cardViewdrprecordsPerPage" [(ngModel)]="cardViewnoOfrecordsPerPage" name="cardViewdrprecordsPerPage" (change)="cardViewSetPage(1)" class="page-counts">
          <option *ngFor="let cardViewnoOfrecordsPerPage of noOfrecordsPerPageList" value="{{cardViewnoOfrecordsPerPage}}">{{cardViewnoOfrecordsPerPage}}</option>
        </select>
      </div>
    </div>
    <!-- CardView Pagination End -->
  </div>
  <!--CardView Design End-->
</div>
<!-- List View and card view main container End-->
<!-- end MainContainer -->