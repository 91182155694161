import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Alert } from 'src/app/model/Alert';
import { PrimaryWorkspaceModel } from 'src/app/model/Engagement/CreateEngagementData';
import { ExecutionDocumentEnagments } from 'src/app/model/engagements';
import { HDWorkItemDocumentModel, HDWorkItemModel } from 'src/app/model/HDWorkItemModel';
import { KeyValue } from 'src/app/model/KeyValue';
import { AddWorkspaceDocumentValidationModel } from 'src/app/model/TemplatePackageModel';
import { CloseoutChecklistWorkspaceModel } from 'src/app/model/Workspace/CloseoutChecklistWorkspaceModel';
import { WorkspaceDocumentModel } from 'src/app/model/WorkspaceDocumentModel';
import { AlertsService } from 'src/app/services/alerts.service';
import { AppConfigService } from 'src/app/services/appConfigService';
import { ClientService } from 'src/app/services/clientservice';
import { DataService } from 'src/app/services/data.service';
import { DocumentService } from 'src/app/services/DocumentService';
import { EngagementService } from 'src/app/services/EngagementService';
import { HotDocsService } from 'src/app/services/HotDocsService';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { LogService } from 'src/app/services/log.service';
import { TemplateMaintenanceService } from 'src/app/services/template-maintenance.service';
import { WorkspaceService } from 'src/app/services/workspace.service';
declare var $;
@Component({
  selector: 'app-new-document-dialog',
  templateUrl: './new-document-dialog.component.html',
  styleUrls: ['./new-document-dialog.component.css']
})
export class NewDocumentDialogComponent implements OnInit, OnChanges {
  //@Output() cancelClick: EventEmitter<string> = new EventEmitter<string>();
  //@Output() closeClick: EventEmitter<string> = new EventEmitter<string>();
  @Input() modalDisplay: boolean;
  workspaceId: string;
  @Input() set prePopulatedClientId(id: number) {
    if (id !== undefined && id !== null && id > 0) {
      this.clientId = id;
    }
    else {
      this.clientId = -1;
    }
  }
  @Input() set prePopulatedEngagementId(id: number) {
    if (id !== undefined && id !== null && id > 0) {
      this.engagementId = id;
    }
    else {
      this.engagementId = -1;
    }
  }
  isHidden: boolean;
  messageTitle: string;
  addWorkspace_SelectedData: AddWorkspaceDocumentValidationModel = new AddWorkspaceDocumentValidationModel();
  addWorkspace_PrimaryWorkspaceStatus: string;
  createWorkItemDetails: HDWorkItemModel = new HDWorkItemModel();
  addWorkspace_SiteUniqueId: string;
  workspaceData: PrimaryWorkspaceModel = new PrimaryWorkspaceModel();
  workItemDetails: HDWorkItemModel = new HDWorkItemModel();
  addWorkspaceDocumentList: WorkspaceDocumentModel[] = [];
  addWorkspace_InsertWorspaceDeatils: CloseoutChecklistWorkspaceModel = new CloseoutChecklistWorkspaceModel();
  MappedSourceData = { 'clients': 'ClientsForDocument', 'engagement': 'EngagementsForDocument', 'documentType': 'DocumentTypeForDocument' }
  loggedInUser: any;
  clientName: any;
  isloggedInUserAdmin: boolean = false;
  mappedSourcePlaceholder: string;
  keyValueList: any;
  clientkeyValueList: KeyValue[] = [];
  engagementkeyValueList: KeyValue[] = [];
  DocumentkeyValueList: KeyValue[] = [];
  userObject: any;
  clientId: number = -1;
  engagementId: number = -1;
  documentName: string;
  engagements: ExecutionDocumentEnagments[];
  isClientSelected: boolean;
  isEngagementSelected: boolean;
  notAllowedspecialCharactes: RegExp = /["*:;<>?\/|#%~&\\]/;
  isSpecialCharactesFound: boolean;
  documentCount: Number = 0;
  isAddDocumentLimitExceeded: boolean = false;
  addDocumentsLimit: Number = 0;
  isDisabled: boolean = false;
  isDocumentTypeExist: boolean = false;
  isDisableDocumentType: boolean = false;
  isDisableDocumentName: boolean = false;
  documentTypeText: string;
  newDocumentPlaceholderText: string = "Enter document name";
  constructor(private data: DataService,
    private activatedRoute: ActivatedRoute,
    private logService: LogService,
    private documentService: DocumentService,
    private loadingService: LoadingScreenService,
    private alertService: AlertsService,
    private workspaceService: WorkspaceService,
    private hdService: HotDocsService,
    private route: Router,
    private clientService: ClientService,
    private engagementService: EngagementService,
    private templateMaintenanceService: TemplateMaintenanceService,
    private appService: AppConfigService
  ) {
    this.isHidden = false;
    this.isAddDocumentLimitExceeded = false;
    this.isDocumentTypeExist = false;
    this.isDisabled = false;
    this.isDisableDocumentType = false;
    this.isDisableDocumentName = false;
    this.IsLoggedinUserSystemAdmin();
    this.addDocumentsLimit = Number(this.appService.config.ADD_MULTIPLE_DOCUMENT_LIMIT_IWantTo) <= 0 ? 1 : Number(this.appService.config.ADD_MULTIPLE_DOCUMENT_LIMIT_IWantTo);
  }




  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.workspaceId = (params.get('workspaceId'))?.toString();
    });
    this.clearValidationMessages();
    this.ClearNewDocumentDailog('All')
    this.isHidden = this.modalDisplay;
    this.documentTypeText = "Select document type...";

  }

  ngOnChanges(changes: SimpleChanges) {
    const jsonString = localStorage.getItem('currentUser');
    this.userObject = JSON.parse(jsonString);
    let nameJSON = JSON.parse(localStorage.getItem('currentUser'));
    if (nameJSON != null) {
      this.loggedInUser = nameJSON.userName;
    }
    // console.log('Calling workspaceService.getAuthUser() to repopulate User Info.');
    this.workspaceService.getAuthUser();
    for (const propName in changes) {
      if (propName === 'modalDisplay') {
        const change = changes[propName];
        let curVal = JSON.stringify(change.currentValue);
        const prevVal = JSON.stringify(change.previousValue);
        if (curVal) {
          curVal = curVal.replace(/['"]+/g, '');
          if (curVal !== prevVal) {
            this.isHidden = (/true/i).test(curVal);

            if (this.clientId > 0) {
              this.prepopulateData();
            }
            else {
              this.engagementkeyValueList = [];
              this.DocumentkeyValueList = [];
              this.BindClientData();
            }
          }
        }
      }
    }
  }

  closeDialog() {
    this.logService.log('closeDialog button called');
    this.ClearNewDocumentDailog('All');
    this.clearValidationMessages();
    // const result = { buttonName: 'close', dialogTitle: this.messageTitle };
    // this.closeClick.emit(JSON.stringify(result));
    this.hideModal();
  }

  hideModal() {
    this.isHidden = false;
    const modalObj = {
      modalDisplay: false
    };
    const modalJSON = JSON.stringify(modalObj);
    this.data.setNewDocumentDialogDetails(modalJSON);
  }

  BindClientData() {
    if (this.loggedInUser !== null && this.loggedInUser !== undefined) {
      this.clientService.GetClientsByActiveEngagementOfUser(this.loggedInUser, this.isloggedInUserAdmin).subscribe(result => {
        this.clientkeyValueList = this.getMethod(result, 'clientId', 'clientName');
      });
    }
  }

  onClientsChange() {
    this.clearValidationMessages();
    //this.engagementId = -1;
    this.ClearNewDocumentDailog('Client');
    this.BindEngagementData(this.clientId);
  }

  BindEngagementData(clientId: number) {
    this.engagementService.GetActiveEngagementsByClientOfUser(this.loggedInUser, clientId, this.isloggedInUserAdmin).subscribe(result => {
      this.engagements = result;
      this.engagementkeyValueList = this.getMethod(result, 'engagementId', 'engagementName');
    });
  }

  onEngagementsChange() {
    console.log('1. Start of onEngagementsChange:', this.isDisabled, 'DocumentTypeExist', this.isDocumentTypeExist);
    this.clearValidationMessages();
    this.ClearNewDocumentDailog('Engagement');
    if (this.engagementId > 0) {
      this.GetDocumentCountbyEngId();
      let result = this.engagements.filter(en => en.engagementId == this.engagementId)
      if (result.length > 0)
        this.addWorkspace_SiteUniqueId = result[0].siteUniqueID
      this.BindDocumentData(this.engagementId);
 //console.log('6. End of onEngagementsChange :', this.isDisabled, 'DocumentTypeExist', this.isDocumentTypeExist);
    }
  }
 
  BindDocumentData(engagementId: number) {
    console.log('4. Start of BindDocumentData :', this.isDisabled, 'DocumentTypeExist', this.isDocumentTypeExist);
    this.templateMaintenanceService.GetTemplatesByEngagementsOfUser(this.loggedInUser, engagementId, this.isloggedInUserAdmin).subscribe(result => {
      this.DocumentkeyValueList = this.getMethod(result, 'templatePackageId', 'templateName');
      if (this.isAddDocumentLimitExceeded == false) {
        if (this.DocumentkeyValueList.length == 0 || this.DocumentkeyValueList.length == null || this.DocumentkeyValueList.length == undefined) {
          // this.isDisabled = true;
          this.isDocumentTypeExist = true;
        }
        else {
          //this.isDisabled = false;
          this.isDocumentTypeExist = false;
        }
      }
      if (this.isDocumentTypeExist == true || this.isAddDocumentLimitExceeded == true) {
        this.isDisabled = true;
      }
      else {

        this.isDisabled = false;
      }
      if (this.isDocumentTypeExist == true) {
        this.isDisableDocumentType = true;
        this.isDisableDocumentName = true;
        this.documentTypeText = "None available";
      }
      else {
        this.isDisableDocumentType = false;
        this.isDisableDocumentName = false;
        this.documentTypeText = "Select document type...";
      }
    });
    console.log('5. End of BindDocumentData :', this.isDisabled, 'DocumentTypeExist', this.isDocumentTypeExist);
  }

  onDocumentChange() {
    //this.clearValidationMessages();
    this.documentName = $("#ddDocuments option:selected").text().trim();
  }

  getMethod(array: any, keyProperty: string, valueProperty) {
    let keyValueList: KeyValue[] = [];
    array.forEach(element => {
      let kv = new KeyValue();
      kv.key = element[keyProperty];
      kv.value = element[valueProperty];
      keyValueList.push(kv);
    });
    return keyValueList;
  }

  Cancel() {
    this.ClearNewDocumentDailog('All');
    this.clearValidationMessages();
    this.hideModal();
  }

  ClearNewDocumentDailog(type: string) {
    switch (type) {
      case 'All':
        this.engagementId = -1;
        this.clientId = -1;
        this.addWorkspace_SelectedData.templatePackageId = '-1';
        this.documentName = '';
        this.isDisableDocumentName=false;
        this.isDisableDocumentType=false;
        this.documentTypeText = "Select document type...";
        break;

      case 'Client':
        this.engagementId = -1;
        this.addWorkspace_SelectedData.templatePackageId = '-1';
        this.documentName = '';
        this.isDisableDocumentName=false;
        this.isDisableDocumentType=false;
        this.documentTypeText = "Select document type...";
        break;

      case 'Engagement':
        this.addWorkspace_SelectedData.templatePackageId = '-1';
        this.documentName = '';
        this.isDisableDocumentName=false;
        this.isDisableDocumentType=false;
        this.documentTypeText = "Select document type...";
        break;
    }

  }
  clearValidationMessages() {
    this.isClientSelected = undefined;
    this.isEngagementSelected = undefined;
    this.isAddDocumentLimitExceeded = false;
    this.addWorkspace_SelectedData.IsTemplateSelected = undefined;
    this.addWorkspace_SelectedData.IsDocumentAliasNameEmpty = undefined;
    this.addWorkspace_SelectedData.IsDocumentAliasNameEmpty = undefined;
    this.addWorkspace_SelectedData.IsDocumentAliasNameDuplicate = undefined;
    this.isDocumentTypeExist = false;
    this.isDisabled = false;
  }

  validateData() {
    this.documentName = this.documentName?.trim();
    this.addWorkspace_SelectedData.documentAliasName = this.documentName;
    if (this.clientId == undefined || this.clientId == -1) //for client dropdown
      this.isClientSelected = true;
    else
      this.isClientSelected = false;
    if (this.engagementId == undefined || this.engagementId == -1) {
      this.isEngagementSelected = true;
    } else {
      this.isEngagementSelected = false;
      //this.GetDocumentCountbyEngId();
    }//for engagement dropdown



    if (this.addWorkspace_SelectedData.templatePackageId == undefined || this.addWorkspace_SelectedData.templatePackageId == '-1') //for template dropdown
      this.addWorkspace_SelectedData.IsTemplateSelected = true;
    else
      this.addWorkspace_SelectedData.IsTemplateSelected = false;
    if (this.addWorkspace_SelectedData?.documentAliasName == undefined || this.addWorkspace_SelectedData?.documentAliasName?.trim().length == 0) { //for doucument name
      this.addWorkspace_SelectedData.IsDocumentAliasNameEmpty = true;
    }
    else {
      this.addWorkspace_SelectedData.IsDocumentAliasNameEmpty = false;
      this.isSpecialCharactesFound = this.notAllowedspecialCharactes.test(this.addWorkspace_SelectedData.documentAliasName);
      if (this.addWorkspace_SelectedData.documentAliasName.trim().length > 50) {
        this.addWorkspace_SelectedData.IsDocumentAliasMaxLengthExceeded = true;
      } else {
        this.addWorkspace_SelectedData.IsDocumentAliasMaxLengthExceeded = false;
      }
    }
    if (!this.isClientSelected && !this.isEngagementSelected && !this.addWorkspace_SelectedData.IsTemplateSelected &&
      !this.addWorkspace_SelectedData.IsDocumentAliasNameEmpty && !this.addWorkspace_SelectedData.IsDocumentAliasMaxLengthExceeded
      && !this.isSpecialCharactesFound && !this.isAddDocumentLimitExceeded) {
      this.addWorkspace_SelectedData.engagementId = this.engagementId;
      this.addDocument();
    }
  }

  NavigateToInterview(workspaceId: string) {
    this.data.setUnsavedDialogVisibility(true);
    this.route.navigate(['NewDocumentInterviews/' + workspaceId]);

  }


  addDocument() {
    this.documentService.getCountOfDocumentAliasNameByEngagementId(this.addWorkspace_SelectedData).subscribe(
      count => {
        if (count > 0) {
          this.addWorkspace_SelectedData.IsDocumentAliasNameDuplicate = true;
        } else {
          this.addWorkspace_SelectedData.IsDocumentAliasNameDuplicate = false;
          this.loadingService.startLoading();
          this.CreateWorkitem();
        }
      },
      err => {
        this.DisplaySystemAlert();
      });
  }

  CreateWorkitem() {
    this.workspaceService.getWorkspaceByEngagementId(this.engagementId).subscribe(
      workspaceResult => {
        this.addWorkspace_PrimaryWorkspaceStatus = workspaceResult.status;
        this.workspaceService.getMaxWorkspaceId().subscribe(
          result => {
            if (result <= 0) {
              result = 1;
            }
            this.createWorkItemDetails.workspaceCode = result + 3;
            this.createWorkItemDetails.siteUniqueId = this.addWorkspace_SiteUniqueId + '_Secondary_Execution_';
            this.createWorkItemDetails.templatePackageId = this.addWorkspace_SelectedData.templatePackageId;
            this.ProcessWorkItemCreation();
          },
          error => {
            this.DisplaySystemAlert();
          });
      },
      error => {
        this.DisplaySystemAlert();
      });
  }

  ProcessWorkItemCreation() {
    this.hdService.GetCreateWorkItemDocumentData(this.createWorkItemDetails).subscribe(
      workItemResult => {
        this.workItemDetails = workItemResult.hdWorkItem;
        this.workspaceData.hdWorkGroupId = this.workItemDetails.workGroupId;
        this.workspaceData.hdWorkItemId = this.workItemDetails.id;
        this.workspaceData.hdTemplatePackageId = this.workItemDetails.templatePackageId;
        this.workspaceData.hdCurrentTempVersion = this.workItemDetails.templateCurrentVersion;
        this.workspaceData.status = this.addWorkspace_PrimaryWorkspaceStatus;
        this.workspaceData.hdTemplatePackageVersionId = this.workItemDetails.templatePackageVersionId;
        this.getDocumentListtoPreview(workItemResult);
      },
      error => {
        this.DisplaySystemAlert();
      });
  }

  getDocumentListtoPreview(workItemResult: HDWorkItemDocumentModel) {
    if (workItemResult.hdWorkItem.id != null) {
      this.addWorkspaceDocumentList = [];
      let workspaceDocument = new WorkspaceDocumentModel();
      workItemResult.hdWorkspaceDocumentList.forEach(x => {
        workspaceDocument.hdDocumentId = x.documentId;
        workspaceDocument.hdDocumentName = x.name;
        workspaceDocument.hdMimeType = x.mimeType;
        workspaceDocument.hdAssemblySession = x.assemblySessionId;
        workspaceDocument.isActive = true;
        workspaceDocument.hdTemplatePackageVersionId = this.workspaceData.hdTemplatePackageVersionId;
        workspaceDocument.documentAliasName = this.addWorkspace_SelectedData.documentAliasName;
        workspaceDocument.engagementState = 'Execution';
        this.addWorkspaceDocumentList.push(workspaceDocument);
      });
      this.createWorkspaceInDB();
    }
  }

  createWorkspaceInDB() {
    this.addWorkspace_InsertWorspaceDeatils.engagementId = this.engagementId;
    this.addWorkspace_InsertWorspaceDeatils.workspaceData = this.workspaceData;
    this.addWorkspace_InsertWorspaceDeatils.workspaceDocuments = this.addWorkspaceDocumentList;
    this.addWorkspace_InsertWorspaceDeatils.isCloseOutChecklist = false;
    this.workspaceService.InsertCloseoutChecklistWorkspace(this.addWorkspace_InsertWorspaceDeatils).subscribe(
      result => {
        this.loadingService.stopLoading();
        if (result != null && result != undefined && result != '') {
          //let e: Event
          this.closeDialog();
          this.NavigateToInterview(result);
        }
      },
      error => {
        this.DisplaySystemAlert();
      });
  }

  IsLoggedinUserSystemAdmin() {
    if (this.userObject != null && this.userObject.role != null && this.userObject.role.length > 0) {
      for (let i = 0; i < this.userObject.role.length; i++) {
        if (this.userObject.role[i].shortRoleName === 'SA') {
          this.isloggedInUserAdmin = true;
          break;
        }
        else {
          this.isloggedInUserAdmin = false;
        }
      }
    }
  }

  DisplaySystemAlert() {
    this.alertService.remove(new Alert());
    this.loadingService.stopLoading();
    this.alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
  }

  prepopulateData() {
    this.clearValidationMessages();
    this.clientService.GetClientsByActiveEngagementOfUser(this.loggedInUser, this.isloggedInUserAdmin).subscribe(clients => {
      this.clientkeyValueList = this.getMethod(clients, 'clientId', 'clientName');
      if (this.clientId > 0 && this.clientId != null) {
        let result1 = clients.filter(en => en.clientId == this.clientId);
        if (result1.length > 0) {
          this.clearValidationMessages();
          this.engagementService.GetActiveEngagementsByClientOfUser(this.loggedInUser, this.clientId, this.isloggedInUserAdmin).subscribe(engagments => {
            this.engagements = engagments;
            this.engagementkeyValueList = this.getMethod(engagments, 'engagementId', 'engagementName');
            if (this.engagementId > 0 && this.engagementId != null) {
              let result2 = this.engagements.filter(en => en.engagementId == this.engagementId)
              if (result2.length > 0) {
                this.addWorkspace_SiteUniqueId = result2[0].siteUniqueID
                this.documentName = '';
                this.addWorkspace_SelectedData.templatePackageId = '-1';
                this.onEngagementsChange();
                // this.clearValidationMessages();
                // this.GetDocumentCountbyEngId();
                // this.BindDocumentData(this.engagementId);
              }
              else {
                this.engagementId = -1;
              }

            }
            else {
              this.engagementId = -1;
            }
          });
        }
        else {
          this.clientId = -1;
        }
      }
      else {
        this.clientId = -1;
      }
    });
  }

  GetDocumentCountbyEngId() {
    console.log(' 2. Start of GetDocumentCountbyEngId :', 'DocumentLimit', this.isAddDocumentLimitExceeded, this.isDisabled, 'DocumentTypeExist', this.isDocumentTypeExist);
    var engagementState = 'Execution';
    this.workspaceService.GetWorkspaceDocumentCountbyEngId(this.engagementId, engagementState,this.workspaceId).subscribe(
      docCount => {
        this.documentCount = docCount;
        this.isAddDocumentLimitExceeded = (this.documentCount) >= this.addDocumentsLimit;
        if (this.isAddDocumentLimitExceeded == true) {
          //this.isDisabled = true;
        }
        else {
          // this.isDisabled = false;
        }
      });
    console.log('3. End of GetDocumentCountbyEngId :', 'DocumentLimit', this.isAddDocumentLimitExceeded, this.isDisabled, 'DocumentTypeExist', this.isDocumentTypeExist);
  }

}
