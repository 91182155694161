import { Router } from '@angular/router';
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { EngagementService } from 'src/app/services/EngagementService';
import { EngagementCompletionDateViewModel, EngCompletionTypes, EngCompletionViewModel } from 'src/app/model/Engagement/EngagementDetails';
declare var $;

@Component({
  selector: 'app-engagement-message-dialog',
  templateUrl: './engagement-message-dialog.component.html',
  styleUrls: ['./engagement-message-dialog.component.css']
})
export class EngagementMessageDialogComponent implements OnInit {

  @Input() messageType: string; // success, warning, danger, information
  @Input() messageTitle: string;
  @Input() messageDescription: string;
  @Input() successRedirect: string;
  @Input() modalDisplay: boolean;
  @Input() modalButtons: number; // 1, 2, 3   
  @Input() IsBigDialogTitle: boolean;
  @Input() IsDeclineModal: boolean;  
  @Input() engagementId: string;
  @Input() plannedClosureDate: string;  
  @Input() loggedInUserEmail: string;         
  @Output() closeEngClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() okEngClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() cancelEngClick: EventEmitter<string> = new EventEmitter<string>();
  userObject: any;
  messageInput: string = '';  
  isHidden: boolean;
  selectedAccountId: string ='';
  selectedClientCode: string ='';
  engCompletionTypes: EngCompletionTypes[] = [];
  engCompletionDt: string;
  engCompletionViewModel : EngCompletionViewModel = new EngCompletionViewModel();  

  constructor(private engagementService: EngagementService , private router: Router,     
    private dataService: DataService) {
    this.isHidden = false;    
  }

  ngOnInit() {
   //  console.log('ngOnInit of Message Dialog ', this.modalDisplay);
    this.userObject = JSON.parse(localStorage.getItem('currentUser'));
    this.fillCompletionTypes();
    this.isHidden = this.modalDisplay; 
    this.engCompletionDt = 'engPeriodDate';    
  }


  fillCompletionTypes() {
    this.engCompletionTypes = [];
    this.engCompletionTypes.push(new EngCompletionTypes ('Estimated completion date', 1));
    this.engCompletionTypes.push(new EngCompletionTypes ('Actual completion date', 2));    
  }

  ngOnChanges(changes: SimpleChanges) {
    this.engCompletionViewModel = new EngCompletionViewModel();
    this.engCompletionViewModel.resultMessage='';
    this.engCompletionViewModel.engCompletionTypeId = 0;
    // console.log(' change engagementId ==> ', this.engagementId);        
    // console.log(' change loggedInUserEmail ==> ', this.loggedInUserEmail);

    for (const propName in changes) {
      // console.log('ngOnChange ', propName);
      if (propName === 'modalDisplay') {
        const change = changes[propName];
        let curVal = JSON.stringify(change.currentValue);
        const prevVal = JSON.stringify(change.previousValue);
        // console.log('ngOnChange func called in Message Dialog Component', curVal, prevVal);
        if (curVal) {
          curVal = curVal.replace(/['"]+/g, '');
          if (curVal !== prevVal) {
            this.isHidden = (/true/i).test(curVal);
          }
        }
      }
    }
  }

  changeCompletionType(event) {   
    let id = Number(event.target.value);
    //this.engCompletionViewModel.EngCompletionDate =  $('.' + this.engCompletionDt).val();
    this.engCompletionViewModel.engCompletionTypeId =
    this.engCompletionTypes.find(element =>
      element.value == id).value;
  }

  checkSaveValidation() {
    this.engCompletionViewModel.isEngCompletionDateSame = false;
    let retVal: boolean = true;
    //validation for completion Type 
    if (this.engCompletionViewModel.engCompletionTypeId == 0) {
      this.engCompletionViewModel.isEngCompletionTypeSelected = false;
    } else {
      this.engCompletionViewModel.isEngCompletionTypeSelected = true;
    }

    //Validation for completion date
    let engCompletionDateVal = $('.' + this.engCompletionDt).val();
    if (engCompletionDateVal == '') {
      this.engCompletionViewModel.isCompletionDateSelected = false;
    } else {
      this.engCompletionViewModel.engCompletionDate = engCompletionDateVal;
      this.engCompletionViewModel.isCompletionDateSelected = true;
    }

    //check same date
    if (this.engCompletionViewModel.isEngCompletionTypeSelected &&
      this.engCompletionViewModel.engCompletionTypeId == 1) {      
      if (this.plannedClosureDate == this.engCompletionViewModel.engCompletionDate.toString()) {
        this.engCompletionViewModel.isEngCompletionDateSame = true;
      }
    }

    if (!this.engCompletionViewModel.isEngCompletionTypeSelected ||
      !this.engCompletionViewModel.isCompletionDateSelected ||
      this.engCompletionViewModel.isEngCompletionDateSame) {
      retVal = false;
    } else {
      retVal = true;
    }
    return retVal;
  }
  
  updateEngCompletionDate() {
    //If vaidation success then proceed
    if (this.checkSaveValidation()) {
      this.userObject = JSON.parse(localStorage.getItem('currentUser'));
      let engagementCompletionDateViewModel: EngagementCompletionDateViewModel = new EngagementCompletionDateViewModel();
      engagementCompletionDateViewModel.engagementId = Number(this.engagementId);
      engagementCompletionDateViewModel.loggedInUser = this.userObject.loggedInUserEmail;
      engagementCompletionDateViewModel.loggedInUserName = this.userObject.loggedInUserFullName;
      engagementCompletionDateViewModel.completionDate = this.engCompletionViewModel.engCompletionDate;

      let datelabel = this.engCompletionTypes.find(element =>
        element.value == this.engCompletionViewModel.engCompletionTypeId).type;

      if (this.engCompletionViewModel.engCompletionTypeId == 1) {
        engagementCompletionDateViewModel.isEngagementCompletionDate = false;
      } else if (this.engCompletionViewModel.engCompletionTypeId == 2) {
        engagementCompletionDateViewModel.isEngagementCompletionDate = true;
      } else {
        engagementCompletionDateViewModel.isEngagementCompletionDate = undefined;
      }

      this.engagementService.UpdateEngCompletionDate(engagementCompletionDateViewModel).subscribe(result => {
        if (result == 'true') {
          console.log('UpdateEngCompletionDate Result:==>', result);
          //this.engCompletionViewModel.resultMessage = datelabel + ' updated successfully';
          const okResultJSON = { buttonName: 'ok', dialogTitle: 'Completion date updated' };          
          this.okEngClick.emit(JSON.stringify(okResultJSON));    
          this.hideModal();
        }
        else {
          this.engCompletionViewModel.resultMessage = datelabel + ' not updated please contact';
        }

      },
        (error) => {
          console.log('Something went wrong! Please contact system administrator.', error);
          this.engCompletionViewModel.resultMessage = 'Something went wrong! Please contact system administrator.';
        });
    }
  }
   
  closeDialog() {
    console.log('closeDialog button called');
    const closeResultJSON = { buttonName: 'cancel', dialogTitle: 'Close Popup' };       
    this.closeEngClick.emit(JSON.stringify(closeResultJSON));
    this.hideModal();
  }

  navigateUrl() {
    const result = { buttonName: 'ok', declineReason: this.messageInput};
    this.okEngClick.emit(JSON.stringify(result));
    if (this.successRedirect !== '') {
      this.router.navigate([this.successRedirect]);
    }
    this.hideModal();
  }

  Cancel() {
    console.log('Cancel button called');
    const cancelResultJSON = { buttonName: 'cancel', dialogTitle: 'Cancel Popup' };          
    this.cancelEngClick.emit(JSON.stringify(cancelResultJSON));    
    this.hideModal();
  }  
  
  Skip() {
    console.log('Skip button called');
    this.cancelEngClick.emit('Cancel'); 
    this.hideModal();
  }

  hideModal(title: string = '', buttonName: string= '') {
    this.engagementId = "0";    
    this.loggedInUserEmail='';
    const modalObj = {
      dialogType: '',
      dialogTitle: '',
      dialogDescription: '',
      dialogRedirectUrl: '',
      dialogButton: 0,
      modalDisplay: false,
      declineReason: '',
      selectAccountId: ''
    };
    const modalJSON = JSON.stringify(modalObj);
    this.dataService.displayEngDialogNotification(modalJSON);
    const modalData = {
      dialogTitle: title,
      dialogButtonName: buttonName,
      dialogInput: ''
    };
    const dialogJSON = JSON.stringify(modalData);
    this.dataService.setEngDialogDetails(dialogJSON);
  }
}

