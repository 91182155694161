import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SearchClientOpportunityByRequestModel } from 'src/app/model/Client/CreateClientViewModel';
import { OneViewClientSearchOpportunityModel } from 'src/app/model/Client/OneViewClientDetail';
import { ClientService } from 'src/app/services/clientservice';

@Component({
  selector: 'app-auto-create-opportunity',
  templateUrl: './auto-create-opportunity.component.html',
  styleUrls: ['./auto-create-opportunity.component.css']
})
export class AutoCreateOpportunityComponent implements OnInit {
 

  @Input() isOpen: boolean = false; // To control modal visibility
  @Output() closeModal = new EventEmitter(); // Event to close the modal
  @Output() selectModal = new EventEmitter<string>(); 
  @Input() accountId: string = "";
  @Input() primarySolutionCode: string = "";
  @Input() opportunityList: OneViewClientSearchOpportunityModel[]=[];
  @Input() errorMessage: string;
  isOpportunityChecked: boolean = false;
  isModalOpen = false;
  selectedRow: any = null;
  opportunityIdVal: string;
  validationMessage: string; 

  constructor(private _clientService: ClientService) { }

  ngOnInit(): void {
  }

 
  onClose() {
    this.errorMessage = "";
    this.validationMessage = "";
    this.isOpportunityChecked =true;
    this.closeModal.emit();
  }

  onSelect(){
    this.errorMessage = "";
    // this.errorMessage = "";
    if (this.isOpportunityChecked){
      this.opportunityIdVal = "";
    }
    this.validationMessage = "";
    if (this.opportunityIdVal != null && this.opportunityIdVal != undefined && this.opportunityIdVal!= ''){
      this.selectModal.emit(this.opportunityIdVal);
      // this.validationMessage = this.errorMessage;
    }else{
      this.validationMessage = "Select an opportunity to proceed."
    }

    
  }

  onOpportunityClick(id: string){
    this.errorMessage = "";
    this.isOpportunityChecked =false;
    this.opportunityIdVal =  id;
  }


}
