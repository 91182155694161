import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  subject: Subject<Object> = new Subject<Object>();
  constructor() {
  }

  emitValue(value: Object) {
    this.subject.next(value);  
  }
}
