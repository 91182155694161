<nav class=" top-header fixed-top">
  <div class="collapse-expand" (click)="collapseLeftNav()">
    <span >
      <img class="mt-3" src="../../../assets/images/EDP/Top-Navigation/three_dots_white.svg" alt="collapse">
    </span>
        
  </div>
  <div class="navbar-brand regularFontSize1 nav-header">
    <a href="/" class="d-inline-block logo-title">
      <img src="../../../assets/images/EDP/Top-Navigation/Engage_logo_white_horizontal.svg" alt="Engage" width="250" height="38" />
    </a>
    <!-- <div class="d-inline-block vertical-line">
      <img src="../../../assets/images/EDP/Top-Navigation/gt-logo-white.svg" alt="Engage" width="150" height="28" />
    </div> -->
  </div>
  <form class=" mt-2 form-inline portrait-position">
    <div id="searchDropdownControl">
      <div class="selectdiv ">
        <select id="ddGServiceLine" [style.cursor]="isMemberFirm?'auto':'pointer'" name="serviceLineDropdown" class="dropdown rounded-0 form-control" [disabled]="isMemberFirm">
          <option *ngFor="let services of serviceLines" [value]="services.key">
            {{services.value}}
          </option>
        </select>
      </div>
      <div class="selectdiv left-right-border">
        <select id="ddGSearchTypesDropdown" [style.cursor]="isMemberFirm?'auto':'pointer'" name="searchTypeDropdown" class="dropdown rounded-0 form-control" [disabled]="isMemberFirm">
          <option *ngFor="let stypes of searchTypes" [value]="stypes.key">
            {{stypes.value}}
          </option>
        </select>
      </div>
    </div>
    <div class="input-group nav-searchbox-length">
      <input style="height:35px;" id="txtGlobalSearch" class="form-control-plain py-1 border-0" [disabled]="isMemberFirm" [style.background-color]="isMemberFirm?'#f5f5f5':'white'" type="search" placeholder="{{globalSearchPlaceholderText}}"
        (focus)="this.globalSearchPlaceholderText=''" (blur)="this.globalSearchPlaceholderText='  Search'" (click)="RemoveLeftNavActiveTabs()" (keydown.enter)="Search()"
        autocomplete="off">
      <button class="input-group-append nav-seachBox"  [disabled]="isMemberFirm" style="border-top:none;border-right:none"[style.cursor]="isMemberFirm?'auto':'pointer'" (click)="Search()" >
        <div class="input-group-text d-block" (click)="RemoveLeftNavActiveTabs()" style="padding:5px 10px;">
          <img src="../../../assets/images/EDP/Filters/search.svg" alt="Search" class="nav-searchIcon"/>
        </div>
      </button>
    </div>
    <div class="dropdown user-icon">
      <span class="userProfilePhoto" data-trigger="focus" tabindex="0" id="dropdownMenu2" style="cursor: pointer;"
        data-toggle="dropdown" rel="dropdown" aria-haspopup="true" aria-expanded="false" data-placement="bottom">
        <ngx-avatars class="my-avatar" name="{{userName}}" title="{{userName}}">
        </ngx-avatars>
      </span>
      <div id="divProfileMenu" class="dropdown-menu nav-dropdown-menu" aria-labelledby="dropdownMenu2">
        <span class="dropdown-menu-arrow"></span>
        <a class="dropdown-item nav-signOutText" id="approvals" (click)="DelegateApprovals();">Delegate approvals</a>
        <a class="dropdown-item nav-signOutText" id="logout" (click)="SignOut();">Sign out</a>
      </div>
    </div>
    <div class="nav-SettingIcon">
      <a *ngIf="isDisplayConfiguration" id="configLink" (click)="CloseReviewNotesPanel()" routerLink="/Configuration">
        <img src="../../../assets/images/EDP/Top-Navigation/Gear-white.svg" alt="Configuration">
      </a>
    </div>
  </form>
</nav>