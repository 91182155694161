<div class="" id="app-content">
  <div class="row m-0" id="homePageContentRow">
    <div class="col-lg-6 text-center homepageWrapper">
      <img src="/assets/images/EDP/LeftSidebar/Engage_keyline_purple_red.svg" alt="" class="homepageImg">
      <div class="welcomeDiv">
      <h3 class="welcomeText">Welcome to</h3>
      <img src="/assets/images/EDP/LeftSidebar/Engage_wordmark.svg" alt="" class="logoImg"> 
    </div>
    </div>
    <div class="col-lg-6 pl-xl-4" id="home">
      
      <h1 class="pageTitle"> {{UserObject.firstName}}</h1>
      <div class="regularFontSize2 mt-4">        
        <h4> What would you like to do?</h4>
      </div>
      <div id="homePageLinks">
        <ul class="list-group">
          <li class="list-group-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <a routerLink="/NewEngagement" (click)="RemoveLeftNavActiveTabs('newEngagement')" class="list-group-link">
              <img src="/assets/images/EDP/LeftSidebar/circular/blue-document.svg" alt="">
              &nbsp;&nbsp; <span [class.smallFontSize17]='isSmallDevice===false'> Create new engagement </span>
            </a>
          </li>
          <li class="list-group-item">
            <a routerLink="/MyClients" (click)="RemoveLeftNavActiveTabs('MyClients')" class="list-group-link">
              <img src="/assets/images/EDP/LeftSidebar/circular/blue-building.svg" alt="">
              &nbsp;&nbsp; <span [class.smallFontSize17]='!isSmallDevice'> View my clients </span>
            </a>
          </li>
          <li class="list-group-item">
            <a routerLink="/MyEngagements" (click)="RemoveLeftNavActiveTabs('MyEngagements')" class="list-group-link">
              <img src="/assets/images/EDP/LeftSidebar/circular/blue-document.svg" alt="">
              &nbsp;&nbsp; <span [class.smallFontSize17]='!isSmallDevice'> View my engagements </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
