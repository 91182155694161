import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LogEntry } from '../model/Log/LogEntry';
import { LogLevel } from '../model/Log/LogLevel';
import { ErrorLog } from '../model/Workspace/ErrorLog';
import { AppConfigService } from './appConfigService';

// Ref Links https://www.codemag.com/Article/1711021/Logging-in-Angular-Applications

@Injectable()
export class LogService {
    APIEndPoint: string;
    level: LogLevel = LogLevel.All;
    logWithDate: boolean;

    constructor(environment: AppConfigService, private http: HttpClient) {
        this.APIEndPoint = environment.config.DATA.API_END_POINT.API_URL;
        this.logWithDate = true;
    }

    debug(msg: string, ...optionalParams: any[]) {
        this.writeToLog(msg, LogLevel.Debug,
            optionalParams);
    }

    info(msg: string, ...optionalParams: any[]) {
        this.writeToLog(msg, LogLevel.Info,
            optionalParams);
    }

    warn(msg: string, ...optionalParams: any[]) {
        this.writeToLog(msg, LogLevel.Warn,
            optionalParams);
    }

    error(msg: string, ...optionalParams: any[]) {
        this.writeToLog(msg, LogLevel.Error,
            optionalParams);
    }

    fatal(msg: string, ...optionalParams: any[]) {
        this.writeToLog(msg, LogLevel.Fatal,
            optionalParams);
    }

    log(msg: string, ...optionalParams: any[]) {
        this.writeToLog(msg, LogLevel.All,
            optionalParams);
    }

    private shouldLog(level: LogLevel): boolean {
        let ret = false;
        if ((level >= this.level &&
            level !== LogLevel.Off) ||
            this.level === LogLevel.All) {
            ret = true;
        }
        return ret;
    }

    private writeToLog(msg: string,
        level: LogLevel,
        params: any[]) {
        if (this.shouldLog(level)) {
            const entry: LogEntry = new LogEntry();
            entry.message = msg;
            entry.level = level;
            entry.extraInfo = params;
            entry.logWithDate = this.logWithDate;
            if (entry.level == LogLevel.Fatal || entry.level == LogLevel.Error) {
                console.error(entry.buildLogString());
                this.storeErrorLog(entry.buildLogString()).subscribe(
                    result =>{
                        // console.log(result);
                    }
                );
            }
            else {
                console.log(entry.buildLogString());
            }
        }
    }

    storeErrorLog(errorMessage: string) : Observable<string> {
        const log = new ErrorLog();
        log.message = errorMessage;
        return this.http.post<string>(this.APIEndPoint + '/Log/UIErrorLog/', log);
    }
}
