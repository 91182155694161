<app-loading-screen></app-loading-screen>
<div *ngIf="(EditMode && IsUserAccessPermission) || !EditMode;else AccessDenied">
  <div class="containerDiv">
    <!-- Breadcrum start -->
    <nav aria-label="breadcrumb" class="py-2">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active">
          <label>
            Basic info
            <span>
              <img style="vertical-align: top;"
                src="../../../assets/images/EDP/Workspace/keyboard_arrow_right-24px-lightgrey.svg" alt="Basic info" />
            </span>
          </label>
        </li>
        <li class="breadcrumb-item disabled">
          <label>
            Questionnaire
            <span>
              <img style="vertical-align: top;"
                src="../../../assets/images/EDP/Workspace/keyboard_arrow_right-24px-lightgrey.svg"
                alt="Questionnaire" />
            </span>
          </label>
        </li>
        <li class="breadcrumb-item disabled">
          Review
        </li>
      </ol>
    </nav>
    <!-- Breadcrum end -->
    <!-- Page Title row -->
    <div class="d-flex justify-content-between pageTitleParent pb-3 mw-100">
      <div class="text-center">
        <h2 class=" float-md-left pageTitle largerFontSize2" *ngIf="EditMode==false">Create new engagement</h2>
        <h2 class="float-md-left pageTitle largerFontSize2" *ngIf="EditMode==true && !isContinuanceEngagement">Edit
          engagement</h2>
        <h2 class="float-md-left pageTitle largerFontSize2" *ngIf="EditMode==true && isContinuanceEngagement">
          Continuance
          engagement</h2>
      </div>
      <div class="text-center" *ngIf="EditMode">
        <span class="float-md-left">
          <app-shortcut></app-shortcut>
        </span>
        <a (click)="DisplayReviewNotes()" data-toggle="tooltip" data-placement="bottom" title="Review notes"
          id="review-NotesIcon" class="ml-2 float-left position-relative btn-light btn-sm customDropdown" role="button"
          aria-haspopup="true" aria-expanded="false">
          <img src="../../../assets/images/EDP/Workspace/library_add_check-18px-blue.svg" alt="" class="p-1">
          <span class="redicon" *ngIf="workspaceOpenReviewPointsCount > 0"></span>
        </a>
        <span class="float-right mt-1 ml-2 mr-2 font-weight-bold"
          *ngIf="workspaceOpenReviewPointsCount > 0">{{workspaceOpenReviewPointsCount}}</span>
      </div>
      <div class="text-center" *ngIf="!EditMode">
        <span class="float-md-right">
          <app-shortcut></app-shortcut>
        </span>
      </div>
    </div>
    <!-- End Page Title row -->


    <div class="row mx-0 my-3">
      <div *ngIf="dataModificationDetails.editStatus" class="alert-warning col-md-12">
        <span class="msg-icon">
          <img src="assets/images/EDP/Workspace/report_problem_warning.svg" alt="Inprogress session">
        </span>
        <span class="d-inline-block align-middle msg-text">
          This engagement is currently being edited by {{reviewInProgressEditEngagementMembers}} Your changes may not be
          saved
        </span>
      </div>
    </div>
    <form novalidate #engagementForm="ngForm">
      <div class="OneViewClientSearch pb-0" *ngIf="clientSearchFeatureFlagEnabled">
        <div class="pt-1">
          <!-- <div class="smallScreenResolution pb-3" *ngIf="isBigScreen==false"> -->
          <div class="smallScreenResolution pb-3">
            <div class="row pt-3 pb-0 ml-0">
              <div class="col-12 col-xs-12 col-md-12 col-lg-12">
                <oneview-client-search #OneViewClientSearch name="OneViewClientName" id="OneViewClientName"
                  source="OneViewClientWithShortNm" [MappedSourceClientNameValue]="mappedSourceClientNameValue"
                  [MappedSource]="OneViewMappedValue" [Sequence]="0" (clientChange)="oneViewClientChange($event)"
                  [editMode]="EditMode" [clientDetails]="client">

                </oneview-client-search>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="InitateEngagementForm pb-5">
        <!-- Engagement Basic screen div -->
        <div class="pt-1">

          <!-- Client Name Row-->
          <!---Small screen Laptop/Tab-->
          <div class="smallScreenResolution pb-3" *ngIf="isBigScreen==false && !clientSearchFeatureFlagEnabled">
            <div class="row pt-3 pb-0 ml-0">
              <div class="col-12 col-xs-12 col-md-12 col-lg-12">
                <label for="ClientName">Client <span class="mandatory">*</span></label>
                <span id="CreateNewClient" class="float-right" (click)="NavigateToCreateClient()"
                  style="cursor: pointer; margin-right: 3px;color:#00838F;font-weight: bold;text-decoration: underline;">
                  Create new client
                </span>
              </div>
            </div>

            <div class="row py-0 ml-0">
              <div class="col-12 col-xs-12 col-md-12 col-lg-12 d-flex">
                <client-smart-search #ClientSearchBig name="ClientName" class="w-100" id="ClientName"
                  source="ClientWithShortNm" [MappedSource]="MappedValue" (validation)="ClientValidation($event)"
                  [Sequence]="0" (newClientOption)="displayClientOption($event)" (change)="clientChange()"
                  [IsAutoPopulate]="IsClientAutoPopulate">
                </client-smart-search>
                <span class="icon-dupe-check-search" id="btnClientSearch"
                  (click)="onSearch(ClientSearchBig.value)"></span>
              </div>
              <div class="col-12 col-xs-12 col-md-12 col-lg-12 d-flex">
                <div id="clientValidationMessage" class="engagement-sample-text error-color d-none"></div>
                <div class="error-color clientNameMandatoryCheck"
                  *ngIf="!arrValidationForEngagement[0]?.isvalid && arrValidationForEngagement[0]?.apply">
                  <p>Client is a required field.</p>
                </div>
              </div>
            </div>
          </div>
          <!---Small screen Laptop/Tab-->

          <!---Big screen Monitor-->
          <div class="row py-3 ml-0 bigScreenResolution" *ngIf="isBigScreen==true && !clientSearchFeatureFlagEnabled">
            <div class="col-md-9">
              <label for="ClientName">Client <span class="mandatory">*</span></label>
              <client-smart-search #ClientSearch name="ClientName" id="ClientName" source="ClientWithShortNm"
                [MappedSource]="MappedValue" (validation)="ClientValidation($event)" [Sequence]="0"
                (newClientOption)="displayClientOption($event)" (change)="clientChange()"
                [IsAutoPopulate]="IsClientAutoPopulate">
              </client-smart-search>
              <div id="clientValidationMessage" class="engagement-sample-text error-color d-none"></div>
              <div class="error-color clientNameMandatoryCheck"
                *ngIf="!arrValidationForEngagement[0]?.isvalid && arrValidationForEngagement[0]?.apply">
                <p>Client is a required field.</p>
              </div>
            </div>
            <div class="col-md-3 padding-left-0">
              <div class="">
                <div class="">
                  <div><label>&nbsp;</label></div>
                  <button id="btnClientSearch" class="btn PrimaryButton rounded-0 w-100 input-height" type="button"
                    (click)="onSearch(ClientSearch.value)">
                    <img src="../../../../assets/images/EDP/ClientSearch/search-24px-white.svg" alt="Search"
                      style="cursor: pointer; margin-right: 3px;">
                    Search
                  </button>
                </div>
                <div id="CreateNewClientBtn" class="">
                  <button class="btn SecondaryButton rounded-0 w-100 mt-2 tablet-btn" type="button"
                    (click)="NavigateToCreateClient()">
                    <img src="../../../../assets/images/EDP/ClientSearch/add_box-24px-blue.svg" alt="Create new client"
                      style="cursor: pointer; margin-right: 3px;">
                    Create new client
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!---Big screen Monitor-->
          <!-- Solution code and FYW/TYE Row -->
          <div class="row py-3 ml-0">
            <div class="col-6 col-sm-9 col-lg-6">
              <label for="solutionCode">Primary solution code <span class="mandatory">*</span></label>
              <app-auto-complete-text-box id="solutionCode" name="solutionCode" source="SolutionCodeWithFT"
                [MappedSource]="SolutionCodeMappedValue" (change)="OnSolutionCodeChange($event,true)" [Sequence]="0"
                [NoRecordFoundFor]="false">
              </app-auto-complete-text-box>
              <div class="error-color solutionCodeMandatoryCheck"
                *ngIf="!arrValidationForEngagement[2]?.isvalid && arrValidationForEngagement[2]?.apply">
                <p>Primary solution code is a required field </p>
              </div>
            </div>
          </div>
          <br />
          <!--Display Opportunities List -->
          <div class="row m-0" *ngIf="clientSearchFeatureFlagEnabled && isOpportunitySolutionCodeChanged">
            <div class="col-md-12">
              <div class="row m-0 p-0">
                <div class="row pl-0 pr-0 mr-0 ml-0">
                  <div class="col-md-12 px-0 mb-2">
                    <div class=" table-box mb-3 pb-2">
                      <div class="col-md-12">

                        <h6 style="color:#707070;" class="pt-3 font-weight-bold"> Select opportunity</h6>
                        <p class="mb-2 pt-1">
                          The following opportunities are captured in oneView and match the selected solution
                          code. Please review and select the one to associate with this engagement. Related solutions will
                          be highlighted.
                        </p>
                        <div class="main-table tableOneViewInitiate">
                          <table class="table table-engage mb-0">
                            <thead>
                              <tr>
                                <th style="width: 40%;">Opportunity</th>
                                <th>ID</th>
                                <th>Total est. fees</th>
                                <th>Expected start date</th>
                                <th>Pursuit owner</th>
                              </tr>
                            </thead>
                            <tbody>

                              <tr *ngFor="let item of opportunityList">
                                <td style="width: 40%;">
                                  <label class="custom-radio opp-radio">
                                    {{ item.opportunityName }}
                                    <input type="radio" name="opportunityName"
                                      (click)="onModalPopupByOpportunityId(item.opportunityId,false)"
                                      [checked]="item.opportunityId === currentSelectedOpportunityId"
                                      id="opportunityName" [checked]="selectedOpportunityId === item.opportunityId">
                                    <span class="checkmark"></span>
                                  </label>
                                </td>
                                <td>{{ item.opportunityId }}</td>
                                <td>{{ item.opportunityEstimatedFees}}</td>
                                <td>{{ item.expectedStartDate | date:'MM/dd/yyyy' }}</td>
                                <td>{{ item.pursuitTeam }}</td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="error-color opportunityMandatoryCheck"
                            *ngIf="!arrValidationForEngagement[5]?.isvalid && arrValidationForEngagement[5]?.apply">
                            <p>Opportunity selection is a required field.</p>
                          </div>
                          <div>


                          </div>

                        </div>
                        <div *ngIf="opportunityList?.length==0 &&engagement.parentSolutionCodeId>0;" class="no-record border mt-2">
                          <div class="row w-100 oneViewNoResult p-3">
                            <div class="col-md-12 text-left ml-3" style="background-color: #f5f5f5;">
                              <p class="pt-2">
                                No results were found. The opportunity might not have been created in oneView yet. Contact your engagement PPMD, Senior Manager or Director for next steps.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="!(opportunityList?.length==0 &&engagement.parentSolutionCodeId>0)"class="no-record border mt-2">
                          <div class="row w-100 oneViewNoResult p-3">
                            <div class="col-md-12 text-left ml-3" style="background-color: #f5f5f5;">
                              <p class="pt-2">
                                Can't find the opportunity you are looking for? The opportunity might not have been created in oneView yet. Contact your engagement PPMD, Senior Manager or Director for next steps.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>




                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--Display Opportunities List if EditMode enabled -->
          <div class="row m-0"
            *ngIf="(clientSearchFeatureFlagEnabled && EditMode && !isOpportunitySolutionCodeChanged)">
            <div class="col-12">
              <div class="col-12 bg-white  filter-select-OneView pl-3 pr-3 mb-4" style="border:1px solid #c7c7c7;">

                <div class="mt-3">
                  <h6 style="color:#707070;">Selected opportunity</h6>
                </div>
                <div class="main-table table-responsive">
                  <!-- <div class="card-body p-0">
                    <div class="row p-3">
                      <div class="col-md-12 "> -->
                  <div *ngFor="let item of opportunityList">
                    <table class="table table-auto border ">
                      <thead>
                        <tr>
                          <th style="width: 40%;" class="pl-4">Opportunity Name</th>
                          <th>Opportunity ID</th>
                          <th>Estimated fees</th>
                          <th>Expected start date</th>
                          <th>Pursuit owner</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="pl-4">{{ item.opportunityName }} </td>
                          <td style="white-space:nowrap;">{{ item.opportunityId }}</td>
                          <td>{{ item.opportunityEstimatedFees}}</td>
                          <td>{{ item.expectedStartDate | date:'MM/dd/yyyy' }}</td>
                          <td>{{ item.pursuitTeam }}</td>
                        </tr>

                      </tbody>

                    </table>
                  </div>


                  <!-- <p style="color:#707070;" class="card-title mb-0 font-eng">Opportunity Name: {{
                          item.opportunityName }}</p>
                        <p class="card-text mb-0 font-eng">Opportunity Id: {{ item.opportunityId }}</p>
                        <p class="card-text mb-0 font-eng">Estimated Fees: {{ item.opportunityEstimatedFees }}</p>
                        <p class="card-text mb-0 font-eng">Expected Start Date: {{ item.expectedStartDate |
                          date:'dd/MM/yyyy' }}</p>
                        <p class="card-text mb-0 font-eng">Pursuit Owner:{{ item.pursuitTeam }} </p> -->
                  <!-- </div>
                    </div>
                  </div> -->
                  <div class="error-color opportunityMandatoryCheck"
                    *ngIf="arrValidationForEngagement[2]?.isvalid && !arrValidationForEngagement[3]?.isvalid && arrValidationForEngagement[3]?.apply">
                    <p>Opportunity selection is a required field.</p>
                  </div>
                  <div>
                    <!-- Shift code from above tr as per mockup previous condition  *ngIf="opportunityList.length === 0" -->
                    <div class="no-record border mb-3">
                      <div class="row w-100 oneViewNoResult p-3">
                        <div class="col-md-12 text-left ml-3" style="background-color: #f5f5f5;">
                          <p class="pt-2">
                            Can't find what you are looking for? The opportunity is not yet created in
                            oneView. Contact your engagement partner, principal or managing director for next
                            steps.
                          </p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>


          <!-- Engagement name Row-->
          <div class="row pt-0 pb-0 ml-0">
            <div class="col-6 col-sm-9 col-lg-6">
              <label for="engagementName">Engagement name <span class="mandatory">*</span></label>
              <input type="text" id="engagementName" class="form-control rounded-0 border engagement-name input-height"
                maxlength="25" placeholder="{{engagementNamePlaceholderText}}"
                (focus)="this.engagementNamePlaceholderText=''"
                (blur)="this.engagementNamePlaceholderText = 'Enter an engagement name'"
                (keyup)="CheckEngagementNameLimit()" (keypress)="blockScriptTags($event,true)"
                (keydown.enter)="controlInitialization()" [(ngModel)]="engagement.engagementName" name="engagementName"
                (change)="EngNameChange($event)" autocomplete="off" />
              <p *ngIf="isContinuanceEngagement && engagement.status == EngagementStatus.EngNotStarted" class="m-0">PY
                Engagement Name: {{originalEngagementName}}</p>
              <div id="EngagementNameHint" class="span-hint mt-1 mb-0" *ngIf="!(!arrValidationForEngagement[1]?.isvalid && arrValidationForEngagement[1]?.apply) &&
                          validEngagementName && !checkEngagementExists &&
                          !(invalidvalues != '' && invalidvalues != null)">
                <p class="m-0">Allowable: . - _ & ' , ( )</p>
              </div>
              <div class="error-color engNameMandatoryCheck"
                [ngClass]="{'pb-3': validEngagementName && !checkEngagementExists && (invalidvalues == '' || invalidvalues == null)}"
                *ngIf="!arrValidationForEngagement[1]?.isvalid && arrValidationForEngagement[1]?.apply">
                <p>Engagement name is a required field </p>
              </div>
              <div class="form-control-feedback error-color pb-1" *ngIf="!validEngagementName">
                <p>Invalid engagement Name </p>
              </div>
              <div class="form-control-feedback error-color pb-1" *ngIf="invalidvalues != '' && invalidvalues != null ">
                <p>'{{invalidvalues}}' <span *ngIf="invalidvalues.length === 1">is not allowed.</span><span
                    *ngIf="invalidvalues.length > 1">are not allowed.</span></p>
              </div>
              <div class="form-control-feedback error-color pb-1" *ngIf="checkEngagementExists">
                <p>Engagement name is a duplicate</p>
              </div>
            </div>
            <div class="col-6 col-sm-9 col-lg-6 pt-2 char-margin">
              <div class="hide-me"><label>&nbsp;</label></div>
              <span id="engagement-remaining-chars">25</span> characters remaining
            </div>
          </div>

          <!--Period End Row -->
          <div class="row py-3 ml-0 periodnew">
            <div class="col-6 col-sm-9 col-lg-6">
              <label for="engPeriodDate">Period end <span class="mandatory"
                  *ngIf="isPeriodRequire==true">*</span></label>
              <app-calendercontrol id="engPeriodDate" [txtDate]="engPeriodDateCtrlId"
                [selectedDate]="engagement.taxYear" class="eng-period-date">
              </app-calendercontrol>
              <div class="error-color periodMandatoryCheck" *ngIf='isValidPeriodDate==true'>
                <p>Period end is a required field </p>
              </div>
              <div class="error-color periodMandatoryCheck" *ngIf='!isvalidPED'>
                <p>Period end should be valid </p>
              </div>
            </div>
          </div>
          <!-- EP Row -->
          <div class="row py-3 ml-0">
            <div class="col-6 col-sm-9 col-lg-6 engagament-PPMDDiv">
              <label for="epRoleName">Engagement PPMD <span class="mandatory">*</span></label>
              <div class="input-group mw-100">
                <app-people-picker class="eng-people-picker" [roleShortName]="epRoleName"
                  [labelCtrlName]="eplblCtrlName" [isLabelVisible]="false" [InputCtrlIdIndex]="peoplepickerIndex"
                  [PeoplePickerModel]="engagement.engagementPartnerName" [userEmail]="engagement.engagementPartner"
                  [IsValidateUserWithADGroup]="validateEP"
                  (validPeopleSelected)="updateTeamMembersList($event,epRoleName)">
                </app-people-picker>

                <div class="input-group-append input-height">
                  <button type="button" id="PPIcon_{{peoplepickerIndex}}" data-toggle="modal" data-target="#modal-EP"
                    data-keyboard="false" data-backdrop="static" class="mw-100 people-picker-button text-center"
                    (click)="refreshEPPopup('txtPeoplePicker_')" [disabled]="isMemberFirm">
                    <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="Engagement PPMD" />
                  </button>
                </div>
              </div>
              <div class="error-color epMandatoryCheck"
                *ngIf="!arrValidationForEngagement[3]?.isvalid && arrValidationForEngagement[3]?.apply">
                <p>Engagement PPMD is a required field </p>
              </div>
              <div class="error-color epMandatoryCheckForAssignment d-none">
                <p>Engagement PPMD is a required field </p>
              </div>
              <div class="error-color epMandatoryCheckForAssignment" *ngIf="isEPMatchEQCR">
                <p>Engagement EPPMD cannot be same as assignment EQCR </p>
              </div>

            </div>

          </div>

          <div class="p-2 px-3">
            <hr>
          </div>
        </div>
        <!--End Engagement Basic screen div -->

        <!-- Start Engagement Assignment -->
        <div class="m-3">

          <div id="assignmentHeaderDiv" class="row col-md-12">
            <h6 class="form-heading-text">Engagement assignments</h6>

          </div>
          <!-- Start Continuance Engagement Assignments Pre request check-->
          <div class="mt-2" *ngIf="isContinuanceEngagement && engagement.preRequestDuringContinuance">
            <div class="preRequestForContinuance-radio-div white-background p-2"
              *ngIf="!engagement.isPreRequestOptedOut">
              <p class="m-2">
                Would you like to pre-request your assignment code(s) based on the prior engagement?
              </p>
              <span class="m-2"
                [ngClass]="{true: 'eng-div-disabled'}[isCMSPreRequestSent || engagement.status != EngagementStatus.EngNotStarted]">
                <label class="custom-radio">
                  <span class="mb-0 pos-top ">
                    Yes
                  </span>
                  <input type="radio" name="PreRequestContinuanceCheck" value=true
                    [(ngModel)]="selectedPreRequestForContinuanceValue"
                    id='engagement-assignments-createAssignmentCode-radio' (change)="OnPreRequestFlagSelected()"
                    [disabled]="isCMSPreRequestSent || engagement.status != EngagementStatus.EngNotStarted">
                  <span class="checkmark"></span>
                </label>
                <label class="custom-radio ml-3" [ngClass]="{true: 'eng-div-disabled'}[isCMSPreRequestSent]">
                  <span class="mb-0 pos-top ">
                    No
                  </span>
                  <input type="radio" name="PreRequestContinuanceCheck" value=false
                    [(ngModel)]="selectedPreRequestForContinuanceValue"
                    id='engagement-assignments-createAssignmentCode-radio' (change)="OnPreRequestFlagSelected()"
                    [disabled]="isCMSPreRequestSent || engagement.status != EngagementStatus.EngNotStarted">
                  <span class="checkmark"></span>
                </label>
              </span>
            </div>
            <p class="mt-4" *ngIf="engagement.preRequestForContinuance">
              Click Edit to update assignment information. An assignment code request will be sent once the edits are
              submitted. Click Remove to remove an assignment that is not relevant for the current year.
            </p>
            <p class="mt-4" *ngIf="!engagement.preRequestForContinuance">
              Click Edit to update assignment information.
            </p>
          </div>
          <!-- End Continuance Engagement Assignments Pre request check-->
          <!--Continuance/Non continuance Engagment Assignment View Mode-->
          <div id="viewAssignmentDiv" *ngIf="arrEngagementAssignment.length > 0"
            style="overflow-y: auto; max-height: 650px;">
            <div *ngFor="let assignmentItem of arrEngagementAssignment; let i = index" class="seperator py-3">

              <div id="row_{{i}}" class="row m-0 p-0 position-relative editAssignmentWhiteForm">
                <div class="row m-0 p-0 pb-4" id="first_row_of_index_{{i}}">
                  <div class="col-xl-3 col-md-6 pl-0 ">
                    <!-- Assignment Name for non continuance Engagement -->
                    <div *ngIf="!isContinuanceEngagement">
                      <p class="position-absolute">
                        <span><strong>{{assignmentItem.assignmentName}}&nbsp;</strong></span>
                        <span *ngIf="assignmentItem.clientCode != null && assignmentItem.assignmentCode != null ">
                          - {{assignmentItem.clientCode}}.{{assignmentItem.assignmentCode}}&nbsp;
                        </span>
                        <span *ngIf="assignmentItem.isPrimaryAssignment"> - Primary</span>
                      </p>
                    </div>
                    <!-- Assignment Name for continuance Engagement -->
                    <div *ngIf="isContinuanceEngagement">
                      <p class="position-absolute">
                        <span
                          *ngIf="assignmentItem.isContinuanceCarryFrwdAssignment && assignmentItem.assignmentName === '' && 
                        assignmentItem.assignmentCodeStatus == AssignmentCodePending"><strong>_______________&nbsp;</strong></span>
                        <span class="alert-pre"
                          *ngIf="assignmentItem.assignmentCodeStatus != AssignmentCodePending || !assignmentItem.isContinuanceCarryFrwdAssignment || assignmentItem.assignmentName !== ''">
                          <strong>{{assignmentItem.assignmentName}}&nbsp;</strong></span>
                        <span
                          *ngIf="assignmentItem.clientCode != null && assignmentItem.assignmentCode != null && assignmentItem.assignmentName !== ''">
                          - {{assignmentItem.clientCode}}.{{assignmentItem.assignmentCode}}&nbsp;
                        </span>
                        <span *ngIf="assignmentItem.isPrimaryAssignment"> - Primary</span>
                      </p>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6 pl-3 ">
                    <span>
                      <span class="alert-pre"
                        *ngIf="isContinuanceEngagement && engagement.status == EngagementStatus.EngNotStarted">
                        (PY Assignment Name: <span>{{assignmentItem.priorYearAssignmentName}}</span>)
                      </span>
                      &nbsp;
                    </span>
                  </div>
                  <div class="position-absolute ediControls" *ngIf="(isContinuanceEngagement && assignmentItem.assignmentCodeStatus==AssignmentCodePending && assignmentItem.isContinuanceCarryFrwdAssignment) ||
                          (!assignmentItem.isContinuanceCarryFrwdAssignment)">
                    <!--Non Continuance Edit Action-->
                    <a id="editAssignmentLink_{{i}}" (click)="editAssignment(i)">
                      <span class="align-middle mr-1">Edit</span>
                      <img style="width: 18px;height: 18px;"
                        src="../../../../assets/images/EDP/Engagement/edit-24px.svg" alt="Edit">
                    </a>
                    <a id="removeAssignmentLink_{{i}}" *ngIf="!assignmentItem.isPrimaryAssignment"
                      (click)="removeAssignment(i)">

                      <span class="align-middle mr-1">Remove</span>
                      <img style="width: 18px;height: 18px;"
                        src="../../../../assets/images/EDP/Engagement/remove_circle_outline-24px.svg" alt="Remove">
                    </a>
                  </div>
                  <!--Display alert for continuance carry forward assignment if cms code request status is invalid-->
                  <div class="position-absolute ediControls" *ngIf="isContinuanceEngagement && 
                assignmentItem.isContinuanceCarryFrwdAssignment && 
                assignmentItem.assignmentCodeStatus != AssignmentCodeStatus.Pending &&
                assignmentItem.assignmentCodeStatus != AssignmentCodeStatus.RequestSent &&
                assignmentItem.assignmentCodeStatus != AssignmentCodeStatus.OpenActive &&
                assignmentItem.assignmentCodeStatus != AssignmentCodeStatus.Closed &&
                assignmentItem.assignmentCodeStatus != AssignmentCodeStatus.Rejected">
                    <span class="error-color">CMS request failed. Please contact administrator.</span>
                  </div>
                </div>
                <div class="row m-0 p-0" id="second_row_of_index_{{i}}">
                  <div class="col-xl-3 col-md-6 pl-0">
                    <div class="mb-1">{{assignmentItem?.selectedengagementType?.value}}</div>
                    <div class="mb-1">{{assignmentItem?.solutionCode}} -
                      {{assignmentItem?.solutionCodeDescription}}</div>
                    <div class="mb-1">Estimated start date: {{assignmentItem.engagementStartDate | date : "MM/dd/yy"}}
                    </div>
                  </div>

                  <div class="col-xl-3 col-md-6 ">
                    <div class="mb-1">CMS PPMD:&nbsp;
                      <span class="link-text-blue contactUser cursor-pointer"
                        id="CMSEPPMD_{{assignmentItem?.assignmentCodeId}}"
                        (click)="displayContactPopover(assignmentItem?.cmsEPPMDEmailId, 'CMSEPPMD_'+ assignmentItem?.assignmentCodeId)">{{assignmentItem.cmsEPPMDName}}</span>
                    </div>
                    <div class="mb-1">Assignment administrator:&nbsp;
                      <span class="link-text-blue contactUser cursor-pointer"
                        id="Admin_{{assignmentItem?.assignmentCodeId}}"
                        (click)="displayContactPopover(assignmentItem?.adminEmailId, 'Admin_'+ assignmentItem?.assignmentCodeId)">{{assignmentItem.assignmentAdministrator}}</span>
                    </div>
                    <div class="mb-1">Estimated end date: {{assignmentItem.engagementEndDate | date : "MM/dd/yy"}}</div>
                  </div>

                  <div class="col-xl-3 col-md-6 pl-0">
                    <div class="mb-1">Realization %: <strong>{{assignmentItem.realization}}%</strong></div>
                    <div class="mb-1">Estimated net fees: <strong>{{assignmentItem.netFeesInCurrencyFormat}}</strong>
                    </div>
                    <div class="mb-1">Billing contact:
                      <span class="font-weight-bold">
                        {{assignmentItem.clientBillingContactName}}, {{assignmentItem.clientBillingContactTitle}}
                      </span>
                    </div>
                    <div class="mb-1">Billing contact email:
                      <span class="font-weight-bold">
                        {{assignmentItem.clientBillingContactEmail}}
                      </span>
                    </div>
                  </div>

                  <div class="col-xl-3 col-md-6">
                    <div class="mb-1" *ngIf="assignmentItem.isContingent == true">Contingent: <strong>Yes</strong></div>
                    <div class="mb-1" *ngIf="assignmentItem.isContingent == false">Contingent: <strong>No</strong></div>
                    <div class="mb-1" *ngIf="assignmentItem.isContingent == null">Contingent: <strong></strong></div>
                    <div class="mb-1">Estimated gross margin %:
                      <strong>{{assignmentItem.estimatedGrossMargin}}%</strong>
                    </div>
                    <div class="mb-1">Closest GT office: <strong>{{assignmentItem.officeLocationCodeName}}</strong>
                    </div>
                    <div class="mb-1">Assignment EQCR:&nbsp;
                      <span class="link-text-blue contactUser cursor-pointer"
                        id="EQCR_{{assignmentItem?.assignmentCodeId}}"
                        (click)="displayContactPopover(assignmentItem?.eqcrEmailId, 'EQCR_'+ assignmentItem?.assignmentCodeId)">{{assignmentItem.eqcrName}}</span>
                      &nbsp;
                      <span class="reviewer" class="clickableLink" *ngIf="!assignmentItem.isEditEQCR && 
                          assignmentItem.eqcrName != '' && isContinuanceEngagement && assignmentItem.isContinuanceCarryFrwdAssignment &&
                         ( assignmentItem.assignmentCodeStatus == AssignmentCodeStatus.RequestSent ||
                          assignmentItem.assignmentCodeStatus == AssignmentCodeStatus.OpenActive ||
                          assignmentItem.assignmentCodeStatus == AssignmentCodeStatus.Closed ||
                          assignmentItem.assignmentCodeStatus == AssignmentCodeStatus.Rejected )"
                        (click)="addEditEQCR(i)" [ngClass]="{'hidden':!isCMSPreRequestSent}">Edit</span>

                      <span class="reviewer" class="clickableLink" *ngIf="!assignmentItem.isEditEQCR && 
                            assignmentItem.eqcrName == '' && isContinuanceEngagement && assignmentItem.isContinuanceCarryFrwdAssignment &&
                            (assignmentItem.assignmentCodeStatus == AssignmentCodeStatus.RequestSent ||
                            assignmentItem.assignmentCodeStatus == AssignmentCodeStatus.OpenActive ||
                            assignmentItem.assignmentCodeStatus == AssignmentCodeStatus.Closed ||
                            assignmentItem.assignmentCodeStatus == AssignmentCodeStatus.Rejected)"
                        (click)="addEditEQCR(i)" [ngClass]="{'hidden':!isCMSPreRequestSent}">Add</span>

                      <div class="px-0 my-1" *ngIf="assignmentItem.isEditEQCR">
                        <div class="d-flex align-items-center">
                          <div class="input-group mw-100 w-46-sm">
                            <app-people-picker class="eng-assign-people-picker" [IsPickerForAssignment]=true
                              [roleShortName]="EQCRRoleName" [labelCtrlName]="EQCRlblCtrlName" [isLabelVisible]="false"
                              [InputCtrlIdIndex]="i" [PeoplePickerModel]="assignmentItem.newEQCRName"
                              [userEmail]="assignmentItem.newEQCREmailId"
                              [IsValidateUserWithADGroup]="assignmentItem.isvalidEQCR">
                            </app-people-picker>

                            <div class="input-group-append input-height">

                              <button type="button" id="PPIcon_{{peoplepickerIndex}}" data-toggle="modal"
                                data-target="#modal-AssignmentEQCR" data-keyboard="false" data-backdrop="static"
                                class="mw-100 people-picker-button text-center"
                                (click)="refreshAssignmentEQCRPopup(i,'txtPeoplePicker_')">
                                <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="Assignment EQCR" />
                              </button>
                            </div>

                          </div>


                        </div>

                        <div style="float: right;">
                          <span class="ml-4 cursor-pointer link-text-blue" (click)="cancelEQCR(i)">Cancel</span>

                          <span class="ml-4 font-weight-bold  link-text-blue"
                            (click)="updateEngagementEqcr(i)">Save</span>
                        </div>
                        <div class="form-control-feedback error-color" *ngIf="!assignmentItem.isvalidEQCR">
                          <p>Assignment EQCR should be valid</p>
                        </div>
                        <div id="businessMessageForEQCRDiv" *ngIf="!assignmentItem.isValidEQCRBusinessRule"
                          class="form-control-feedback error-color">
                          <p>EQCR cannot be EPPMD or CMS PPMD or AA</p>
                        </div>
                        <div id="businessMessageForEQCRDiv" *ngIf="assignmentItem.isEQCRExists"
                          class="form-control-feedback error-color">
                          <p>No change in EQCR to save</p>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="editAssignmentDiv_{{i}}"
                *ngIf="isVisibleEditAssignmentDiv && editAssignmentDivId == 'editAssignmentDiv_' + i"
                class="mt-3 w-100">
                <div class="col-md-12 bg-white pl-2 pb-3">
                  <div class="row m-0">
                    <div class="col-xl-3 col-md-6 pr-2 pt-3">
                      <label><span class="label">Assignment name </span> <span class="label mandatory">*</span></label>
                      <input id="txtAssignmentName" name="assignmentName" class="input-height form-control rounded-0 "
                        type="text" maxlength="40" placeholder="Assignment name" pattern="^[A-Za-z0-9]?$"
                        (keyup)="blockScriptTags($event,false)" [(ngModel)]="defaultEngAssignment.assignmentName"
                        autocomplete="off" (change)="invalidCharacterDetectionAssignmentName()">
                      <div id="EngagementNameHint" class="span-hint mt-1 mb-0" *ngIf="!(!arrControlValidation[0]?.isvalid && arrControlValidation[0]?.apply) &&
                                    !(duplicateAssignmentName || isAssignmentNameExistForClient) &&
                                    validAssignmentName && !(invalidvaluesAN != '' && invalidvaluesAN != null)">
                        <p>Allowable: . - _ & ' , ( )</p>
                      </div>
                      <div class="form-control-feedback error-color"
                        *ngIf="invalidvaluesAN != '' && invalidvaluesAN != null ">
                        <p>'{{invalidvaluesAN}}' <span *ngIf="invalidvaluesAN.length === 1">is not allowed.</span><span
                            *ngIf="invalidvaluesAN.length > 1">are not allowed.</span></p>
                      </div>
                      <div class="form-control-feedback error-color"
                        *ngIf="!arrControlValidation[0]?.isvalid && arrControlValidation[0]?.apply">
                        <p>Enter assignment name </p>
                      </div>
                      <div class="form-control-feedback error-color"
                        *ngIf="duplicateAssignmentName || isAssignmentNameExistForClient">
                        <p>Duplicate assignment name </p>
                      </div>
                      <div class="form-control-feedback error-color" *ngIf="!validAssignmentName">
                        <p>Invalid assignment name </p>
                      </div>
                    </div>

                    <div class="col-xl-3 col-md-6 pr-2 pt-3">
                      <label><span class="label">Solution code </span> <span class="label mandatory">*</span></label>
                      <div class="selectdiv ">
                        <select id="drpsolutionCodeId" name="solutionCodeId" class="dropdown rounded-0"
                          [disabled]="(arrEngagementAssignment.length==0) || (defaultEngAssignment.isPrimaryAssignment)"
                          #solutionCodeId="ngModel" [(ngModel)]="defaultEngAssignment.solutionCodeId"
                          (change)="onEngagementAssignmentsSolutionCodeChange()">
                          <option value="null" disabled hidden>Select solution code</option>
                          <option *ngFor="let solutionCode of defaultEngAssignment.solutionCodeListData"
                            [value]=solutionCode?.childSolutionCodeId>
                            {{solutionCode.solutionCode}}-{{solutionCode.solutionCodeDescription}}</option>
                        </select>
                      </div>
                      <div class="form-control-feedback error-color"
                        *ngIf="!arrControlValidation[1]?.isvalid && arrControlValidation[1]?.apply">
                        <p>Select solution code</p>
                      </div>
                    </div>

                    <div class="col-xl-3 col-md-6 pr-2 pt-3">
                      <label><span class="label">CMS PPMD </span> <span class="label mandatory">*</span></label>
                      <div class="input-group mw-100">
                        <app-people-picker class="eng-assign-people-picker" [IsPickerForAssignment]=true
                          [roleShortName]="epRoleName" [labelCtrlName]="cmslblCtrlName" [isLabelVisible]="false"
                          [InputCtrlIdIndex]="peoplepickerIndex" [PeoplePickerModel]="cmsDisplayUserName"
                          [userEmail]="cmsDisplayUserEmail" [IsValidateUserWithADGroup]="validateCMSEP"
                          [isValidateUser]="isvalidCMSEP" (assignUser)="assignUserDetailsForCMSEP($event)">
                        </app-people-picker>

                        <div class="input-group-append input-height">
                          <button type="button" id="PPIcon_{{peoplepickerIndex}}" data-toggle="modal"
                            data-target="#modal-CMSEP" data-keyboard="false" data-backdrop="static"
                            class="mw-100 people-picker-button text-center"
                            (click)="refreshCMSEPPopup(i,'txtPeoplePicker_')" [disabled]="isMemberFirm">
                            <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="CMS PPMD" />
                          </button>
                        </div>

                      </div>
                      <div class="form-control-feedback error-color"
                        *ngIf="!arrControlValidation[6]?.isvalid && arrControlValidation[6]?.apply">
                        <p>Select CMS PPMD</p>
                      </div>
                      <div class="form-control-feedback error-color" *ngIf="!isvalidcmsEPPMDName">
                        <p>CMS PPMD should be valid</p>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 pr-2 pt-3">
                      <label><span class="label">Assignment administrator </span> <span
                          class="label mandatory">*</span></label>
                      <div class="input-group mw-100">
                        <app-people-picker class="eng-assign-people-picker" [roleShortName]="assignAdminRoleName"
                          [labelCtrlName]="assignAdminlblCtrlName" [isLabelVisible]="false"
                          [InputCtrlIdIndex]="peoplepickerIndex" [IsPickerForAssignment]=true
                          [PeoplePickerModel]="adminDisplayUserName" [userEmail]="adminDisplayUserEmail"
                          [IsValidateUserWithADGroup]="validateCMSAA" [isValidateUser]="isvalidAA"
                          (assignUser)="assignUserDetailsForAA($event)">
                        </app-people-picker>

                        <div class="input-group-append input-height">
                          <button type="button" id="PPIcon_{{peoplepickerIndex}}" data-toggle="modal"
                            data-target="#modal-AssignmentAdministrator" data-keyboard="false" data-backdrop="static"
                            class="mw-100 people-picker-button text-center"
                            (click)="refreshCMSAssignmentAdminPopup(i,'txtPeoplePicker_')" [disabled]="isMemberFirm">
                            <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="Assignment administrator" />
                          </button>
                        </div>
                      </div>
                      <div id="mandatoryMessageForAdministratorsDiv" class="form-control-feedback error-color"
                        *ngIf="!arrControlValidation[7]?.isvalid && arrControlValidation[7]?.apply">
                        <p>Select assignment administrator</p>
                      </div>

                      <div id="businessMessageForAdministratorsDiv" style="display: none;"
                        class="form-control-feedback error-color">
                        <p>CMS PPMD and AA cannot be the same.</p>
                      </div>
                      <div class="form-control-feedback error-color" *ngIf="!isvalidAassignmentAdministrator">
                        <p>Assignment administrator should be valid</p>
                      </div>
                    </div>
                  </div>
                  <div class="row m-0">
                    <div class="col-xl-3 col-md-6 pr-2 pt-3">
                      <label><span class="label">Engagement type </span> <span class="label mandatory">*</span></label>
                      <div class="selectdiv ">
                        <select id="drpengagementTypeId" name="engagementTypeId" class="dropdown rounded-0 form-control"
                          [(ngModel)]="defaultEngAssignment.engagementTypeId" [disabled]="isMemberFirm">
                          <option value="0" disabled hidden>Select engagement type</option>
                          <option *ngFor="let EngTypeItem of defaultEngAssignment.engagementTypeData"
                            [value]=EngTypeItem.key>
                            {{EngTypeItem.value}}</option>
                        </select>
                      </div>
                      <div class="form-control-feedback error-color"
                        *ngIf="!arrControlValidation[2]?.isvalid && arrControlValidation[2]?.apply">
                        <p>Select engagement type</p>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 pr-2 pt-3 periodnew">
                      <label for="EstimatedGM">Estimated start date <span class="mandatory"
                          *ngIf="((defaultEngAssignment || {}).selectedsolutionCode || {}).isEngagementStartDateRequired==true">*</span></label>
                      <app-calendercontrol id="EstimatedStartDate" [txtDate]="StartDateCtrlId" class="eng-period-date">
                      </app-calendercontrol>
                      <div class="form-control-feedback error-color"
                        *ngIf="!arrControlValidation[12]?.isvalid && arrControlValidation[11]?.apply">
                        <p>Enter estimated start date</p>
                      </div>
                      <div id="errormessage" class="form-control-feedback error-color" *ngIf="DateCompareResult > 0">
                        <p>Start date cannot be after the end date</p>
                      </div>
                      <div id="errormessage" class="form-control-feedback error-color" *ngIf="!isvalidESD">
                        <p>Start date should be valid</p>
                      </div>
                      <div id="errormessage" class="form-control-feedback error-color"
                        *ngIf="startDateCompareResult > 0">
                        <p>Start cannot be in the past</p>
                      </div>
                    </div>

                    <div class="col-xl-3 col-md-6 pr-2 pt-3 periodnew">
                      <label for="EstimatedGM">Estimated end date <span class="mandatory"
                          *ngIf="((defaultEngAssignment || {}).selectedsolutionCode || {}).isEngagementEndDateRequired==true">*</span></label>
                      <app-calendercontrol id="EstimatedEndDate" [txtDate]="EndDateCtrlId" class="eng-period-date">
                      </app-calendercontrol>
                      <div class="form-control-feedback error-color"
                        *ngIf="!arrControlValidation[13]?.isvalid && arrControlValidation[11]?.apply">
                        <p>Enter estimated end date</p>
                      </div>
                      <div id="errormessage" class="form-control-feedback error-color" *ngIf="DateCompareResult == 0">
                        <p>Start date and end date can not be equal</p>
                      </div>
                      <div id="errormessage" class="form-control-feedback error-color" *ngIf="!isvalidEED">
                        <p>End date should be valid</p>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 pr-2 pt-3">
                      <label><span class="label">Closest GT office </span> </label>
                      <div class="selectdiv">
                        <select id="ddClosestGTOffice" class="dropdown rounded-0 form-control" name="closestGTOffice"
                          #closestGTOffice="ngModel" [(ngModel)]="defaultEngAssignment.officeLocationCodeId">
                          <option value="0" disabled hidden>Select closest GT office</option>
                          <option *ngFor="let location of ClientLocations" [value]="location.id">
                            {{location.typeValue}}
                          </option>
                        </select>
                      </div>

                    </div>

                  </div>
                  <!--Budget approved row for continuance engagement-->
                  <div class="row m-0" *ngIf="isContinuanceEngagement && engagement.preRequestForContinuance &&
                defaultEngAssignment.isContinuanceCarryFrwdAssignment">
                    <div class="col-xl-12 col-md-12 pt-3">
                      <p>
                        The section allows updates to economics for the current engagement.
                        Select “Yes” to update the economics associated with this code request(s) to the draft budget
                        economics or select “No” to create the assignment(s) based on the prior economics.
                        Updates to economics after the request has been submitted are made via Share Request in
                        Centralized Billing.
                        <span class="error-color"> *</span>
                      </p>
                      <span class="mt-1 mb-2">
                        <label class="custom-radio">
                          <span class="mb-0 pos-top ">
                            Yes
                          </span>
                          <input type="radio" name="BudgetApprovedRadio" value=true
                            [(ngModel)]="defaultEngAssignment.isAssignmentFinanceDataEditable"
                            id='engagement-assignments-budgetApprovedRadio-radio' (change)="onBudgetApprovedChange()">
                          <span class="checkmark"></span>
                        </label>
                        <label class="custom-radio ml-3">
                          <span class="mb-0 pos-top ">
                            No
                          </span>
                          <input type="radio" name="BudgetApprovedRadio" value=false
                            [(ngModel)]="defaultEngAssignment.isAssignmentFinanceDataEditable"
                            id='engagement-assignments-budgetApprovedRadio-radio' (change)="onBudgetApprovedChange()">
                          <span class="checkmark"></span>
                        </label>
                      </span>
                    </div>
                    <div class="form-control-feedback error-color" *ngIf="isAssignmentFinanceDataEditableBlank">
                      <p>Select budget approved</p>
                    </div>
                  </div>
                  <!-- start Finance fields -->
                  <div class="row m-0" *ngIf="defaultEngAssignment.isAssignmentFinanceDataEditable === 'true'">
                    <div class="col-xl-3 col-md-6 pr-2 pt-3">
                      <label><span class="label">Contingent </span> <span class="label mandatory">*</span></label>
                      <div class="selectdiv">
                        <select id="drpisContingent" name="isContingent" class="dropdown rounded-0 form-control"
                          [(ngModel)]="defaultEngAssignment.isContingent">
                          <option value="null" disabled hidden>Yes or No</option>
                          <option *ngFor="let item of defaultEngAssignment.iscontingentData" [value]=item.key>
                            {{item.value}}
                          </option>
                        </select>
                      </div>
                      <div class="form-control-feedback error-color"
                        *ngIf="!arrControlValidation[5]?.isvalid && arrControlValidation[5]?.apply">
                        <p>Select contingent</p>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 pr-2 pt-3">
                      <label><span class="label">Estimated net fees </span> <span
                          class="label mandatory">*</span></label>
                      <input id="txtnetFees" name="netFees" class="input-height form-control rounded-0 " maxlength="9"
                        type="text" placeholder="$1,000,000" pattern="^[0-9]+(\.[0-9]{1,2})?$"
                        [(ngModel)]="defaultEngAssignment.netFees" autocomplete="off" (keypress)="numberOnly($event)">
                      <div class="form-control-feedback error-color"
                        *ngIf="!arrControlValidation[4]?.isvalid && arrControlValidation[4]?.apply">
                        <p>Enter estimated net fees</p>
                      </div>
                      <div id="errormessage" class="form-control-feedback error-color" *ngIf="!validNetfees">
                        <p>{{netFeesMessage}}</p>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 pr-2 pt-3">
                      <label><span class="label">Realization % </span> <span
                          class="label mandatory input-height">*</span></label>
                      <input id="txtrealization" name="realization" class="input-height form-control rounded-0 "
                        type="text" placeholder="00%" maxlength="3" pattern="^(([0-9]\d?)(\.\d{1,2})?|100(\.00?)?)$"
                        [(ngModel)]="defaultEngAssignment.realization" autocomplete="off"
                        (keypress)="numberOnly($event)">
                      <div class="form-control-feedback error-color"
                        *ngIf="!arrControlValidation[3]?.isvalid && arrControlValidation[3]?.apply">
                        <p>Enter realization</p>
                      </div>
                      <div id="errormessage" class="form-control-feedback error-color" *ngIf="!validRealization">
                        <p>{{RealizationMessage}}</p>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 pr-2 pt-3">
                      <label for="EstimatedGM">Estimated gross margin % <span class="mandatory"
                          *ngIf="((defaultEngAssignment || {}).selectedsolutionCode || {}).isGrossMarginRequire==true">*</span></label>
                      <input id="txtGrossMargin" name="txtGrossMargin" class="input-height form-control rounded-0 "
                        maxlength="3" type="text" placeholder="00%"
                        [(ngModel)]="defaultEngAssignment.estimatedGrossMargin" autocomplete="off"
                        (keypress)="numberOnly($event)" (blur)="resetEstimatedGrossMarginFlag()" />
                      <div class="form-control-feedback error-color"
                        *ngIf="!arrControlValidation[11]?.isvalid && arrControlValidation[11]?.apply">
                        <p>Enter estimated gross margin</p>
                      </div>
                      <div id="errormessage" class="form-control-feedback error-color"
                        *ngIf="!validEstimatedGrossMargin">
                        <p>{{estimatedGrossMarginMessage}}</p>
                      </div>
                    </div>
                  </div>
                  <!--Read only finance data for continuance carry forwarded assignment with budget approved yes-->
                  <div class="row m-0" *ngIf="defaultEngAssignment.isAssignmentFinanceDataEditable === 'false'">
                    <div class="col-xl-3 col-md-6 pr-2 pt-3">
                      <label><span class="label">Contingent </span> <span class="label mandatory">*</span></label>
                      <div *ngIf="defaultEngAssignment.isContingent">
                        <strong>Yes</strong>
                      </div>
                      <div *ngIf="!defaultEngAssignment.isContingent">
                        <strong>No</strong>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 pr-2 pt-3">
                      <label><span class="label">Estimated net fees </span> <span
                          class="label mandatory">*</span></label>
                      <div>
                        <strong>{{defaultEngAssignment.netFeesInCurrencyFormat}}</strong>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 pr-2 pt-3">
                      <label><span class="label">Realization % </span> <span
                          class="label mandatory input-height">*</span></label>
                      <div>
                        <strong>{{defaultEngAssignment.realization}}%</strong>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 pr-2 pt-3">
                      <label for="EstimatedGM">Estimated gross margin % <span class="mandatory"
                          *ngIf="((defaultEngAssignment || {}).selectedsolutionCode || {}).isGrossMarginRequire==true">*</span></label>
                      <div>
                        <strong>{{defaultEngAssignment.estimatedGrossMargin}}%</strong>
                      </div>
                    </div>
                  </div>
                  <!-- start Finance fields -->
                  <div class="row m-0">
                    <div class="col-xl-3 col-md-6 pr-2 pt-3">
                      <label><span class="label">Client billing contact name </span> <span class="label mandatory"
                          *ngIf="!isMemberFirm">*</span></label>
                      <input id="txtClientBillingName" name="txtClientBillingName"
                        class="input-height form-control rounded-0 " maxlength="50" type="text"
                        placeholder="(Default based on client)"
                        [(ngModel)]="defaultEngAssignment.clientBillingContactName" autocomplete="off"
                        [disabled]="isMemberFirm">
                      <div class="form-control-feedback error-color"
                        *ngIf="!isMemberFirm&&!arrControlValidation[8]?.isvalid && arrControlValidation[8]?.apply">
                        <p>Enter client billing name </p>
                      </div>
                      <div class="form-control-feedback error-color" *ngIf="!isValidClientBillingName">
                        <p>Enter valid client billing name </p>
                      </div>
                    </div>

                    <div class="col-xl-3 col-md-6 pr-2 pt-3">
                      <label><span class="label">Client billing contact title </span> <span
                          class="label mandatory input-height" *ngIf="!isMemberFirm">*</span></label>
                      <div class="selectdiv">
                        <select id="ddClientBillingTitle" name="clientBillingTitle"
                          class="dropdown rounded-0 form-control" #clientBillingTitle="ngModel"
                          [(ngModel)]="defaultEngAssignment.clientBillingContactTitleId" [disabled]="isMemberFirm">
                          <option value="0" disabled hidden>(Default based on client)</option>
                          <option *ngFor="let contact of ClientContactTypes" [value]="contact.id">
                            {{contact.typeValue}}
                          </option>
                        </select>
                        <div class="form-control-feedback error-color"
                          *ngIf="!isMemberFirm&&!arrControlValidation[9]?.isvalid && arrControlValidation[9]?.apply">
                          <p>Select client billing contact title</p>
                        </div>
                      </div>

                    </div>

                    <div class="col-xl-3 col-md-6 pr-2 pt-3">
                      <label><span class="label">Client billing email </span> <span class="label mandatory"
                          *ngIf="!isMemberFirm">*</span></label>
                      <input id="txtClientBillingEmail" name="txtClientBillingEmail"
                        class="input-height form-control rounded-0 " maxlength="80" type="text"
                        placeholder="(Default based on client)"
                        [(ngModel)]="defaultEngAssignment.clientBillingContactEmail" autocomplete="off"
                        [disabled]="isMemberFirm">
                      <div class="form-control-feedback error-color"
                        *ngIf="!isMemberFirm&&!arrControlValidation[10]?.isvalid && arrControlValidation[10]?.apply">
                        <p>Enter client billing email </p>
                      </div>
                      <div class="form-control-feedback error-color"
                        *ngIf=" !(!arrControlValidation[10]?.isvalid && arrControlValidation[10]?.apply) && !isValidClientBillingEmail">
                        <p>Enter valid client billing email </p>
                      </div>
                    </div>
                    <!-- Start Assignment EQCR field -->
                    <div class="col-xl-3 col-md-6 pr-2 pt-3">
                      <label><span class="label">Assignment EQCR
                        </span></label>
                      <div class="input-group mw-100">
                        <app-people-picker class="eng-assign-people-picker" [IsPickerForAssignment]=true
                          [roleShortName]="EQCRRoleName" [labelCtrlName]="EQCRlblCtrlName" [isLabelVisible]="false"
                          [InputCtrlIdIndex]="peoplepickerIndex" [PeoplePickerModel]="defaultEngAssignment.eqcrName"
                          [userEmail]="defaultEngAssignment.eqcrEmailId" [IsValidateUserWithADGroup]="validateEQCR">
                        </app-people-picker>

                        <div class="input-group-append input-height">

                          <button type="button" id="PPIcon_{{peoplepickerIndex}}" data-toggle="modal"
                            data-target="#modal-AssignmentEQCR" data-keyboard="false" data-backdrop="static"
                            class="mw-100 people-picker-button text-center"
                            (click)="refreshAssignmentEQCRPopup(i,'txtPeoplePicker_')">
                            <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="Assignment EQCR" />
                          </button>
                        </div>

                      </div>
                      <div class="form-control-feedback error-color" *ngIf="!isvalidEQCR">
                        <p>Assignment EQCR should be valid</p>
                      </div>
                      <div id="businessMessageForEQCRDiv" *ngIf="!isValidEQCRBusinessRule"
                        class="form-control-feedback error-color">
                        <p>EQCR cannot be EPPMD or CMS PPMD or AA.</p>
                      </div>
                    </div>
                    <!-- end Assignment EQCR field -->
                  </div>

                  <!-- button row start -->
                  <div class="row m-0">
                    <div class="col-xl-6 col-md-6 pr-2 pt-3">
                    </div>

                    <div class="col-xl-3 col-md-3 pr-2 pt-3">
                      <button id="btnCancelAssignment" *ngIf="arrEngagementAssignment.length > 0"
                        class="btn SecondaryButton w-100 text-center" type="button" (click)="cancelAssignment(i)">
                        Cancel
                      </button>
                    </div>
                    <div class="col-xl-3 col-md-3 pr-2 pt-3">
                      <button id="btnSaveAssignment" class="btn PrimaryButton rounded-0 w-100 input-height"
                        type="button" (click)="saveAssignment(i)">
                        <img src="../../../../assets/images/EDP/Engagement/save-24px.svg" alt="Save"
                          style="cursor: pointer; margin-right: 3px;">
                        <span
                          *ngIf="!defaultEngAssignment.isContinuanceCarryFrwdAssignment || !engagement.preRequestForContinuance">
                          Save assignment</span>
                        <span
                          *ngIf="defaultEngAssignment.isContinuanceCarryFrwdAssignment && engagement.preRequestForContinuance">
                          Submit assignment</span>
                      </button>
                    </div>
                    <!-- button row End -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="defaultAssignmentDiv" *ngIf="isVisibledefaultEditAssignmentDiv" class="mt-3 w-100">
            <div *ngIf="!disableEngagementAssignment" class="col-md-12 bg-white pl-2 pb-3">
              <div class="row m-0">
                <div class="col-xl-3 col-md-6 pr-2 pt-3">
                  <label><span class="label">Assignment name </span> <span class="label mandatory">*</span></label>
                  <input id="txtAssignmentName" name="assignmentName" class="input-height form-control rounded-0 "
                    type="text" maxlength="40" placeholder="Assignment name" pattern="^[A-Za-z0-9]?$"
                    (keyup)="blockScriptTags($event,false)" [(ngModel)]="defaultEngAssignment.assignmentName"
                    autocomplete="off" (change)="invalidCharacterDetectionAssignmentName()">
                  <div id="EngagementNameHint" class="span-hint mt-1 mb-0" *ngIf="!(!arrControlValidation[0]?.isvalid && arrControlValidation[0]?.apply) &&
                                !(duplicateAssignmentName || isAssignmentNameExistForClient) &&
                                validAssignmentName && !(invalidvaluesAN != '' && invalidvaluesAN != null)">
                    <p>Allowable: . - _ & ' , ( )</p>
                  </div>
                  <div class="form-control-feedback error-color"
                    *ngIf="invalidvaluesAN != '' && invalidvaluesAN != null ">
                    <p>'{{invalidvaluesAN}}' <span *ngIf="invalidvaluesAN.length === 1">is not allowed.</span><span
                        *ngIf="invalidvaluesAN.length > 1">are not allowed.</span></p>
                  </div>
                  <div class="form-control-feedback error-color"
                    *ngIf="!arrControlValidation[0]?.isvalid && arrControlValidation[0]?.apply">
                    <p>Enter assignment name </p>
                  </div>
                  <div class="form-control-feedback error-color"
                    *ngIf="duplicateAssignmentName || isAssignmentNameExistForClient">
                    <p>Duplicate assignment name </p>
                  </div>
                  <div class="form-control-feedback error-color" *ngIf="!validAssignmentName">
                    <p>Invalid assignment name </p>
                  </div>
                </div>

                <div class="col-xl-3 col-md-6 pr-2 pt-3">
                  <label><span class="label">Solution code </span> <span class="label mandatory">*</span></label>
                  <div class="selectdiv ">
                    <select id="drpsolutionCodeId" name="solutionCodeId" class="dropdown rounded-0"
                      [disabled]="(arrEngagementAssignment.length==0) || (defaultEngAssignment.isPrimaryAssignment)"
                      #solutionCodeId="ngModel" [(ngModel)]="defaultEngAssignment.solutionCodeId"
                      (change)="onEngagementAssignmentsSolutionCodeChange()">
                      <option value="null" disabled hidden>Select solution code</option>
                      <option *ngFor="let solutionCode of defaultEngAssignment.solutionCodeListData"
                        [value]=solutionCode?.childSolutionCodeId>
                        {{solutionCode.solutionCode}}-{{solutionCode.solutionCodeDescription}}</option>
                    </select>
                  </div>
                  <div class="form-control-feedback error-color"
                    *ngIf="!arrControlValidation[1]?.isvalid && arrControlValidation[1]?.apply">
                    <p>Select solution code</p>
                  </div>
                </div>

                <div class="col-xl-3 col-md-6 pr-2 pt-3">
                  <label><span class="label">CMS PPMD </span> <span class="label mandatory">*</span></label>
                  <div class="input-group mw-100">
                    <app-people-picker class="eng-assign-people-picker" [IsPickerForAssignment]=true
                      [roleShortName]="epRoleName" [labelCtrlName]="cmslblCtrlName" [isLabelVisible]="false"
                      [InputCtrlIdIndex]="peoplepickerIndex" [PeoplePickerModel]="cmsDisplayUserName"
                      [userEmail]="cmsDisplayUserEmail" [IsValidateUserWithADGroup]="validateCMSEP"
                      [isValidateUser]="isvalidCMSEP" (assignUser)="assignUserDetailsForCMSEP($event)"
                      [disabled]="isMemberFirm">
                    </app-people-picker>

                    <div class="input-group-append input-height">
                      <button type="button" id="PPIcon_{{peoplepickerIndex}}" data-toggle="modal"
                        data-target="#modal-CMSEP" data-keyboard="false" data-backdrop="static"
                        class="mw-100 people-picker-button text-center"
                        (click)="refreshCMSEPPopup(i,'txtPeoplePicker_')" [disabled]="isMemberFirm">
                        <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="CMS PPMD" />
                      </button>
                    </div>

                  </div>
                  <div class="form-control-feedback error-color"
                    *ngIf="!arrControlValidation[6]?.isvalid && arrControlValidation[6]?.apply">
                    <p>Select CMS PPMD</p>
                  </div>
                  <div class="form-control-feedback error-color" *ngIf="!isvalidcmsEPPMDName">
                    <p>CMS PPMD should be valid</p>
                  </div>
                </div>
                <div class="col-xl-3 col-md-6 pr-2 pt-3">
                  <label><span class="label">Assignment administrator </span> <span
                      class="label mandatory">*</span></label>
                  <div class="input-group mw-100">
                    <app-people-picker class="eng-assign-people-picker" [roleShortName]="assignAdminRoleName"
                      [labelCtrlName]="assignAdminlblCtrlName" [isLabelVisible]="false"
                      [InputCtrlIdIndex]="peoplepickerIndex" [IsPickerForAssignment]=true
                      [PeoplePickerModel]="adminDisplayUserName" [userEmail]="adminDisplayUserEmail"
                      [IsValidateUserWithADGroup]="validateCMSAA" [isValidateUser]="isvalidAA"
                      (assignUser)="assignUserDetailsForAA($event)">
                    </app-people-picker>

                    <div class="input-group-append input-height">
                      <button type="button" id="PPIcon_{{peoplepickerIndex}}" data-toggle="modal"
                        data-target="#modal-AssignmentAdministrator" data-keyboard="false" data-backdrop="static"
                        class="mw-100 people-picker-button text-center"
                        (click)="refreshCMSAssignmentAdminPopup(i,'txtPeoplePicker_')" [disabled]="isMemberFirm">
                        <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="Assignment administrator" />
                      </button>
                    </div>
                  </div>
                  <div id="mandatoryMessageForAdministratorsDiv" class="form-control-feedback error-color"
                    *ngIf="!arrControlValidation[7]?.isvalid && arrControlValidation[7]?.apply">
                    <p>Select assignment administrator</p>
                  </div>

                  <div id="businessMessageForAdministratorsDiv" style="display: none;"
                    class="form-control-feedback error-color">
                    <p>CMS PPMD and AA cannot be the same.</p>
                  </div>
                  <div class="form-control-feedback error-color" *ngIf="!isvalidAassignmentAdministrator">
                    <p>Assignment administrator should be valid</p>
                  </div>
                </div>
              </div>
              <div class="row m-0">
                <div class="col-xl-3 col-md-6 pr-2 pt-3">
                  <label><span class="label">Engagement type </span> <span class="label mandatory">*</span></label>
                  <div class="selectdiv ">
                    <select id="drpengagementTypeId" name="engagementTypeId" class="dropdown rounded-0 form-control"
                      [(ngModel)]="defaultEngAssignment.engagementTypeId" [disabled]="isMemberFirm">
                      <option value="0" disabled hidden>Select engagement type</option>
                      <option *ngFor="let EngTypeItem of defaultEngAssignment.engagementTypeData"
                        [value]=EngTypeItem.key>
                        {{EngTypeItem.value}}</option>
                    </select>
                  </div>
                  <div class="form-control-feedback error-color"
                    *ngIf="!arrControlValidation[2]?.isvalid && arrControlValidation[2]?.apply">
                    <p>Select engagement type</p>
                  </div>
                </div>
                <div class="col-xl-3 col-md-6 pr-2 pt-3 periodnew">
                  <label for="EstimatedGM">Estimated start date <span class="mandatory"
                      *ngIf="((defaultEngAssignment || {}).selectedsolutionCode || {}).isEngagementStartDateRequired==true">*</span></label>
                  <app-calendercontrol id="EstimatedStartDate" [txtDate]="StartDateCtrlId" class="eng-period-date">
                  </app-calendercontrol>
                  <div class="form-control-feedback error-color"
                    *ngIf="!arrControlValidation[12]?.isvalid && arrControlValidation[11]?.apply">
                    <p>Enter estimated start date</p>
                  </div>
                  <div id="errormessage" class="form-control-feedback error-color" *ngIf="DateCompareResult > 0">
                    <p>Start date cannot be after the end date</p>
                  </div>
                  <div id="errormessage" class="form-control-feedback error-color" *ngIf="!isvalidESD">
                    <p>Start date should be valid</p>
                  </div>
                  <div id="errormessage" class="form-control-feedback error-color" *ngIf="startDateCompareResult > 0">
                    <p>Start cannot be in the past</p>
                  </div>
                </div>
                <div class="col-xl-3 col-md-6 pr-2 pt-3 periodnew">
                  <label for="EstimatedGM">Estimated end date <span class="mandatory"
                      *ngIf="((defaultEngAssignment || {}).selectedsolutionCode || {}).isEngagementEndDateRequired==true">*</span></label>
                  <app-calendercontrol id="EstimatedEndDate" [txtDate]="EndDateCtrlId" class="eng-period-date">
                  </app-calendercontrol>
                  <div class="form-control-feedback error-color"
                    *ngIf="!arrControlValidation[13]?.isvalid && arrControlValidation[11]?.apply">
                    <p>Enter estimated end date</p>
                  </div>
                  <div id="errormessage" class="form-control-feedback error-color" *ngIf="DateCompareResult == 0">
                    <p>Start date and end date can not be equal</p>
                  </div>
                  <div id="errormessage" class="form-control-feedback error-color" *ngIf="!isvalidEED">
                    <p>End date should be valid</p>
                  </div>
                </div>
                <div class="col-xl-3 col-md-6 pr-2 pt-3">
                  <label><span class="label">Closest GT office </span> </label>
                  <div class="selectdiv">
                    <select id="ddClosestGTOffice" class="dropdown rounded-0 form-control" name="closestGTOffice"
                      #closestGTOffice="ngModel" [(ngModel)]="defaultEngAssignment.officeLocationCodeId">
                      <option value="0" disabled hidden>Select closest GT office</option>
                      <option *ngFor="let location of ClientLocations" [value]="location.id">
                        {{location.typeValue}}
                      </option>
                    </select>
                  </div>

                </div>

              </div>
              <!--Budget approved row for continuance engagement-->
              <div class="row m-0" *ngIf="isContinuanceEngagement && engagement.preRequestForContinuance &&
            defaultEngAssignment.isContinuanceCarryFrwdAssignment">
                <div class="col-xl-12 col-md-12 pt-3">
                  <p>
                    The section allows updates to economics for the current engagement.
                    Select “Yes” to update the economics associated with this code request(s) to the draft budget
                    economics or select “No” to create the assignment(s) based on the prior economics.
                    Updates to economics after the request has been submitted are made via Share Request in Centralized
                    Billing.
                    <span class="error-color"> *</span>
                  </p>
                  <span class="mt-1 mb-2">
                    <label class="custom-radio">
                      <span class="mb-0 pos-top ">
                        Yes
                      </span>
                      <input type="radio" name="BudgetApprovedRadio" value=true
                        [(ngModel)]="defaultEngAssignment.isAssignmentFinanceDataEditable"
                        id='engagement-assignments-budgetApprovedRadio-radio' (change)="onBudgetApprovedChange()">
                      <span class="checkmark"></span>
                    </label>
                    <label class="custom-radio ml-3">
                      <span class="mb-0 pos-top ">
                        No
                      </span>
                      <input type="radio" name="BudgetApprovedRadio" value=false
                        [(ngModel)]="defaultEngAssignment.isAssignmentFinanceDataEditable"
                        id='engagement-assignments-budgetApprovedRadio-radio' (change)="onBudgetApprovedChange()">
                      <span class="checkmark"></span>
                    </label>
                  </span>
                </div>
                <div class="form-control-feedback error-color" *ngIf="isAssignmentFinanceDataEditableBlank">
                  <p>Select budget approved</p>
                </div>
              </div>
              <!-- start Finance fields -->
              <div class="row m-0" *ngIf="defaultEngAssignment.isAssignmentFinanceDataEditable === 'true'">
                <div class="col-xl-3 col-md-6 pr-2 pt-3">
                  <label><span class="label">Contingent </span> <span class="label mandatory">*</span></label>
                  <div class="selectdiv">
                    <select id="drpisContingent" name="isContingent" class="dropdown rounded-0 form-control"
                      [(ngModel)]="defaultEngAssignment.isContingent" [disabled]="isMemberFirm">
                      <option value="null" disabled hidden>Yes or No</option>
                      <option *ngFor="let item of defaultEngAssignment.iscontingentData" [value]=item.key>{{item.value}}
                      </option>
                    </select>
                  </div>
                  <div class="form-control-feedback error-color"
                    *ngIf="!arrControlValidation[5]?.isvalid && arrControlValidation[5]?.apply">
                    <p>Select contingent</p>
                  </div>
                </div>
                <div class="col-xl-3 col-md-6 pr-2 pt-3">
                  <label><span class="label">Estimated net fees </span> <span class="label mandatory">*</span></label>
                  <input id="txtnetFees" name="netFees" class="input-height form-control rounded-0 " maxlength="9"
                    type="text" placeholder="$1,000,000" pattern="^[0-9]+(\.[0-9]{1,2})?$"
                    [(ngModel)]="defaultEngAssignment.netFees" autocomplete="off" (keypress)="numberOnly($event)"
                    [disabled]="isMemberFirm">
                  <div class="form-control-feedback error-color"
                    *ngIf="!arrControlValidation[4]?.isvalid && arrControlValidation[4]?.apply">
                    <p>Enter estimated net fees</p>
                  </div>
                  <div id="errormessage" class="form-control-feedback error-color" *ngIf="!validNetfees">
                    <p>{{netFeesMessage}}</p>
                  </div>
                </div>
                <div class="col-xl-3 col-md-6 pr-2 pt-3">
                  <label><span class="label">Realization % </span> <span
                      class="label mandatory input-height">*</span></label>
                  <input id="txtrealization" name="realization" class="input-height form-control rounded-0 " type="text"
                    placeholder="00%" maxlength="3" pattern="^(([0-9]\d?)(\.\d{1,2})?|100(\.00?)?)$"
                    [(ngModel)]="defaultEngAssignment.realization" autocomplete="off" (keypress)="numberOnly($event)"
                    [disabled]="isMemberFirm">
                  <div class="form-control-feedback error-color"
                    *ngIf="!arrControlValidation[3]?.isvalid && arrControlValidation[3]?.apply">
                    <p>Enter realization</p>
                  </div>
                  <div id="errormessage" class="form-control-feedback error-color" *ngIf="!validRealization">
                    <p>{{RealizationMessage}}</p>
                  </div>
                </div>
                <div class="col-xl-3 col-md-6 pr-2 pt-3">
                  <label for="EstimatedGM">Estimated gross margin % <span class="mandatory"
                      *ngIf="((defaultEngAssignment || {}).selectedsolutionCode || {}).isGrossMarginRequire==true">*</span></label>
                  <input id="txtGrossMargin" name="txtGrossMargin" class="input-height form-control rounded-0 "
                    maxlength="3" type="text" placeholder="00%" [(ngModel)]="defaultEngAssignment.estimatedGrossMargin"
                    autocomplete="off" (keypress)="numberOnly($event)" (blur)="resetEstimatedGrossMarginFlag()" />
                  <div class="form-control-feedback error-color"
                    *ngIf="!arrControlValidation[11]?.isvalid && arrControlValidation[11]?.apply">
                    <p>Enter estimated gross margin</p>
                  </div>
                  <div id="errormessage" class="form-control-feedback error-color" *ngIf="!validEstimatedGrossMargin">
                    <p>{{estimatedGrossMarginMessage}}</p>
                  </div>
                </div>
              </div>
              <!--Read only finance data for continuance carry forwarded assignment with budget approved yes-->
              <div class="row m-0" *ngIf="defaultEngAssignment.isAssignmentFinanceDataEditable === 'false'">
                <div class="col-xl-3 col-md-6 pr-2 pt-3">
                  <label><span class="label">Contingent </span> <span class="label mandatory">*</span></label>
                  <div *ngIf="defaultEngAssignment.isContingent">
                    <strong>Yes</strong>
                  </div>
                  <div *ngIf="!defaultEngAssignment.isContingent">
                    <strong>No</strong>
                  </div>
                </div>
                <div class="col-xl-3 col-md-6 pr-2 pt-3">
                  <label><span class="label">Estimated net fees </span> <span class="label mandatory">*</span></label>
                  <div>
                    <strong>{{defaultEngAssignment.netFeesInCurrencyFormat}}</strong>
                  </div>
                </div>
                <div class="col-xl-3 col-md-6 pr-2 pt-3">
                  <label><span class="label">Realization % </span> <span
                      class="label mandatory input-height">*</span></label>
                  <div>
                    <strong>{{defaultEngAssignment.realization}}%</strong>
                  </div>
                </div>
                <div class="col-xl-3 col-md-6 pr-2 pt-3">
                  <label for="EstimatedGM">Estimated gross margin % <span class="mandatory"
                      *ngIf="((defaultEngAssignment || {}).selectedsolutionCode || {}).isGrossMarginRequire==true">*</span></label>
                  <div>
                    <strong>{{defaultEngAssignment.estimatedGrossMargin}}%</strong>
                  </div>
                </div>
              </div>
              <!-- start Finance fields -->
              <div class="row m-0">
                <div class="col-xl-3 col-md-6 pr-2 pt-3">
                  <label><span class="label">Client billing contact name </span> <span class="label mandatory"
                      *ngIf="!isMemberFirm">*</span></label>
                  <input id="txtClientBillingName" name="txtClientBillingName"
                    class="input-height form-control rounded-0 " maxlength="50" type="text"
                    placeholder="(Default based on client)" [(ngModel)]="defaultEngAssignment.clientBillingContactName"
                    autocomplete="off" [disabled]="isMemberFirm">
                  <div class="form-control-feedback error-color"
                    *ngIf="!isMemberFirm&&!arrControlValidation[8]?.isvalid && arrControlValidation[8]?.apply">
                    <p>Enter client billing name </p>
                  </div>
                  <div class="form-control-feedback error-color" *ngIf="!isValidClientBillingName">
                    <p>Enter valid client billing name </p>
                  </div>
                </div>

                <div class="col-xl-3 col-md-6 pr-2 pt-3">
                  <label><span class="label">Client billing contact title </span> <span
                      class="label mandatory input-height" *ngIf="!isMemberFirm">*</span></label>
                  <div class="selectdiv">
                    <select id="ddClientBillingTitle" name="clientBillingTitle" class="dropdown rounded-0 form-control"
                      #clientBillingTitle="ngModel" [(ngModel)]="defaultEngAssignment.clientBillingContactTitleId"
                      [disabled]="isMemberFirm">
                      <option value="0" disabled hidden>(Default based on client)</option>
                      <option *ngFor="let contact of ClientContactTypes" [value]="contact.id">
                        {{contact.typeValue}}
                      </option>
                    </select>
                    <div class="form-control-feedback error-color"
                      *ngIf="!isMemberFirm&&!arrControlValidation[9]?.isvalid && arrControlValidation[9]?.apply">
                      <p>Select client billing contact title</p>
                    </div>
                  </div>

                </div>

                <div class="col-xl-3 col-md-6 pr-2 pt-3">
                  <label><span class="label">Client billing email </span> <span *ngIf="!isMemberFirm"
                      class="label mandatory">*</span></label>
                  <input id="txtClientBillingEmail" name="txtClientBillingEmail"
                    class="input-height form-control rounded-0 " maxlength="80" type="text"
                    placeholder="(Default based on client)" [(ngModel)]="defaultEngAssignment.clientBillingContactEmail"
                    autocomplete="off" [disabled]="isMemberFirm">
                  <div class="form-control-feedback error-color"
                    *ngIf="!isMemberFirm&&!arrControlValidation[10]?.isvalid && arrControlValidation[10]?.apply">
                    <p>Enter client billing email </p>
                  </div>
                  <div class="form-control-feedback error-color"
                    *ngIf=" !(!arrControlValidation[10]?.isvalid && arrControlValidation[10]?.apply) && !isValidClientBillingEmail">
                    <p>Enter valid client billing email </p>
                  </div>
                </div>
                <!-- Start Assignment EQCR field -->
                <div class="col-xl-3 col-md-6 pr-2 pt-3">
                  <label><span class="label">Assignment EQCR
                    </span></label>
                  <div class="input-group mw-100">
                    <app-people-picker class="eng-assign-people-picker" [IsPickerForAssignment]=true
                      [roleShortName]="EQCRRoleName" [labelCtrlName]="EQCRlblCtrlName" [isLabelVisible]="false"
                      [InputCtrlIdIndex]="peoplepickerIndex" [PeoplePickerModel]="defaultEngAssignment.eqcrName"
                      [userEmail]="defaultEngAssignment.eqcrEmailId" [IsValidateUserWithADGroup]="validateEQCR">
                    </app-people-picker>

                    <div class="input-group-append input-height">

                      <button type="button" id="PPIcon_{{peoplepickerIndex}}" data-toggle="modal"
                        data-target="#modal-AssignmentEQCR" data-keyboard="false" data-backdrop="static"
                        class="mw-100 people-picker-button text-center"
                        (click)="refreshAssignmentEQCRPopup(i,'txtPeoplePicker_')">
                        <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="Assignment EQCR" />
                      </button>
                    </div>

                  </div>
                  <div class="form-control-feedback error-color" *ngIf="!isvalidEQCR">
                    <p>Assignment EQCR should be valid</p>
                  </div>
                  <div id="businessMessageForEQCRDiv" *ngIf="!isValidEQCRBusinessRule"
                    class="form-control-feedback error-color">
                    <p>EQCR cannot be EPPMD or CMS PPMD or AA.</p>
                  </div>
                </div>
                <!-- end Assignment EQCR field -->
              </div>

              <!-- button row start -->
              <div class="row m-0">
                <div class="col-xl-6 col-md-6 pr-2 pt-3">
                </div>

                <div class="col-xl-3 col-md-3 pr-2 pt-3">
                  <button id="btnCancelAssignment" *ngIf="arrEngagementAssignment.length > 0"
                    class="btn SecondaryButton w-100 text-center" type="button" (click)="cancelAssignment()">
                    Cancel
                  </button>
                </div>
                <div class="col-xl-3 col-md-3 pr-2 pt-3">
                  <button id="btnSaveAssignment" class="btn PrimaryButton rounded-0 w-100 input-height" type="button"
                    (click)="saveAssignment()">
                    <img src="../../../../assets/images/EDP/Engagement/save-24px.svg" alt="Save"
                      style="cursor: pointer; margin-right: 3px;">
                    <span
                      *ngIf="!defaultEngAssignment.isContinuanceCarryFrwdAssignment || !engagement.preRequestForContinuance">Save
                      assignment</span>
                    <span
                      *ngIf="defaultEngAssignment.isContinuanceCarryFrwdAssignment && engagement.preRequestForContinuance">Submit
                      assignment</span>
                  </button>
                </div>
                <!-- button row End -->
              </div>
            </div>
          </div>

          <div id="addNewAssignmentDIV" *ngIf="isVisibleAddNewAssignmentButton" class="w-100">
            <div class="row mx-0 py-4 bg-white">
              <div class="col-md-12">
                <a id="addNewAssignment" class="link-text-blue"
                  (click)="onModalPopupByOpportunityId(oneViewOpportunityId,true)"
                  [class.nonClickableLink]="isMemberFirm">
                  <img src="../../../../assets/images/EDP/Engagement/add_box.svg"
                    alt="Add a new assignment to the engagement">
                  <span class="d-inline-block pl-2">Add a new assignment to the engagement</span>
                </a>
              </div>
            </div>
          </div>

          <div class="pt-4 pb-2 w-100"><span class="checkmark"></span>
            <hr />
          </div>
        </div>

        <!-- End Engagement Assignment -->

        <!-- Start TeamMemberDiv -->
        <div id="TeamMemberDiv" class="row ml-0">
          <div class="col-md-12">
            <h6 class="ml-0 form-heading-text">Team members</h6>

            <div *ngIf="isContinuanceEngagement && isCurrentEngagementIsIndus && carryForwadINDUSConsent "
              class="mt-2 edp-font-14">
              Consent for the use of INDUS during continuance has carried forward from the prior engagement.
            </div>

            <div class="row ml-0 my-4 p-3 bg-white">

              <h6 class="col-md-6 mt-2 pl-1 form-sub-heading-text">Collaboration space</h6>

              <!-- assignmentTeamMemberList is empty then show below placeholder -->
              <div class="row m-0 mt-2">

                <div class="col-xl-3 col-md-4 px-0">
                  <span class="d-inline-block edp-font-14">
                    <img src="../../../assets/images/EDP/Engagement/supervised_user_circle.svg" alt='Superwise user' />
                    <strong class="collabration-site-name ml-2">{{ lblCollaborationSiteName }}</strong></span>
                </div>
                <div class="col-xl-6 col-md-5">
                  <span class="mr-3">|</span>
                  <a class="link-text-blue" (click)="expandAllTeamDetails()">
                    <span class="edp-font-14">Manage team</span>
                  </a>
                </div>
                <div class="col-xl-3 col-md-3 text-right pr-0">
                  <span class="label edp-font-14 mr-2">GT team: {{engTeamaMembeListrWithoutAssignment.length}}&nbsp;
                    &nbsp;&nbsp;Client team: {{clientTeamMembersList.length}}</span>
                </div>
              </div>

              <div class="w-100 mt-2" id="assignmentTeamMemberList"
                *ngIf="arrEngagementAssignment.length > 0 || clientTeamMembersList.length >0 ">
                <div class="row m-0 pt-1" *ngFor="let assignmentItem of arrEngagementAssignment; let i=index">
                  <div class="col-xl-3 col-md-4 pl-3 alert-pre">
                    <span class="d-inline-block ml-3 edp-font-14">{{assignmentItem.assignmentName}}</span>
                  </div>
                  <div class="col-xl-4 col-md-4 pl-3">
                    <div class="ml-3">
                      <span>CMS PPMD:</span>
                      <span for="input-id" class="edp-font-14 pl-1">{{assignmentItem.cmsEPPMDName}}</span>
                    </div>
                  </div>
                  <div class="col-xl-5 col-md-4">Assignment administrator:&nbsp;
                    <span for="input-id" class="edp-font-14">{{assignmentItem.assignmentAdministrator}}</span>
                  </div>

                </div>
                <!-- end for loop assignmentTeamMemberList with index i-->

                <!-- start each assignment team details -->
                <div class="m-2 p-3 bg-edp-body teamdetailsAll" id="teamdetails_{{i}}" style="display: none;">
                  <div class="mt-3 font-weight-bold" *ngIf="engTeamaMembeListrWithoutAssignment.length > 0">Team members
                  </div>
                  <table class="table table-borderless" *ngIf="engTeamaMembeListrWithoutAssignment.length > 0"
                    aria-describedby="assignmentTeamDetails">
                    <thead style="border-bottom: 1px solid #c7c7c7; color: #707070; font-weight: normal!important;">
                      <th width="31%" scope="col" style="font-weight: normal!important; padding-left: 0px;">Name</th>
                      <th width="14%" scope="col" *ngIf="isKeyTeamMemberFeatureFlag && isAudit"
                        style="font-weight: normal!important;">Key</th>
                      <th width="14%" scope="col" style="font-weight: normal!important;">Role</th>
                      <th width="16.5%" scope="col" style="font-weight: normal!important;">Site permissions</th>
                      <th width="2%" class="pr-0 txt-last" scope="col" style="font-weight: normal!important;">
                        <img src="assets/images/EDP/Engagement/cancel_close.svg" alt="close assignment"
                          style="vertical-align: bottom;" class="cursor-pointer" (click)="collapseAllTeamDetails()">
                      </th>

                    </thead>
                    <tbody>
                      <div style="display:contents;"
                        *ngFor="let item of engTeamaMembeListrWithoutAssignment; let j=index">
                        <tr style=" color: #707070;">
                          <td class="" scope="row" style="padding-left: 0px;">
                            <span class="label">{{item.memberName}}</span>
                          </td>
                          <td *ngIf="isKeyTeamMemberFeatureFlag && isAudit" class="" scope="row">
                            <a *ngIf="item.isKeyTeamMember" (click)="OnKeyTeamMemberChecked(j, false)"
                              [ngClass]="{'eng-div-disabled' : (engTeamaMembeListrWithoutAssignment[j].teamMemberRole===getTeamMemberRoleIdByCode('EP')) }">
                              <img class="" src="assets/images/EDP/Engagement/key-button.svg" alt="Key team member"
                                class="middle-align">
                            </a>

                            <a *ngIf="!item.isKeyTeamMember" (click)="OnKeyTeamMemberChecked(j, true)"
                              [ngClass]="{'eng-div-disabled' : (engTeamaMembeListrWithoutAssignment[j].teamMemberRole===getTeamMemberRoleIdByCode('EP')) }">
                              <img class="" src="assets/images/EDP/Engagement/key-locked.svg" alt="Non key team member"
                                class="middle-align">
                            </a>
                          </td>
                          <td class="">
                            <div class="form-group mb-0">
                              <span *ngFor="let role of applicationmasterList">
                                <span
                                  *ngIf="(engTeamaMembeListrWithoutAssignment[j].teamMemberRole===getTeamMemberRoleIdByCode('EP')) && role.code=='EP'">
                                  Engagement PPMD
                                </span>
                              </span>
                              <span *ngFor="let role of applicationmasterList">
                                <span
                                  *ngIf="(engTeamaMembeListrWithoutAssignment[j].teamMemberRole===getTeamMemberRoleIdByCode('PR')) && role.code=='PR'">
                                  Eng qual control reviewer (EQCR)
                                </span>
                              </span>
                              <!-- <span *ngFor="let role of applicationmasterList">
                                <span
                                  *ngIf="(engTeamaMembeListrWithoutAssignment[j].keyTeamMemberRoleId===getTeamMemberRoleIdByCode('A')) && role.code=='A'">
                                  Assignment administrator
                                </span>
                              </span>
                              <span *ngFor="let role of applicationmasterList">
                                <span
                                  *ngIf="(engTeamaMembeListrWithoutAssignment[j].keyTeamMemberRoleId===getTeamMemberRoleIdByCode('CMSEP')) && role.code=='CMSEP'">
                                  CMS PPMD
                                </span>
                              </span> -->
                              <div class="selectdiv "
                                *ngIf="isUserAgentIE < 0 && engTeamaMembeListrWithoutAssignment[j].teamMemberRole!==getTeamMemberRoleIdByCode('EP') 
                              && engTeamaMembeListrWithoutAssignment[j].teamMemberRole!==getTeamMemberRoleIdByCode('PR')">
                                <select *ngIf="isAudit; else isTeamMember1" class="dropdown rounded-0 form-control"
                                  name="keyTeamMemberRole{{j}}" id="keyTeamMemberRole_Row_{{j}}"
                                  [(ngModel)]="engTeamaMembeListrWithoutAssignment[j].keyTeamMemberRoleId"
                                  (change)="OnRoleChangeForExistingMember(engTeamaMembeListrWithoutAssignment[j].keyTeamMemberRoleId,j)">
                                  <option value="null" disabled hidden>Select team member role</option>
                                  <option *ngFor="let role of keyteamMemberRoleFromAppMstList"
                                    [value]="role.applicationMasterId">
                                    {{role.value}}
                                  </option>
                                </select>
                                <ng-template #isTeamMember1>
                                  <select class="dropdown rounded-0 form-control" name="teamMemberRole{{j}}"
                                    id="teamMemberRole_Row_{{j}}"
                                    [(ngModel)]="engTeamaMembeListrWithoutAssignment[j].teamMemberRole"
                                    (change)="OnRoleChangeForExistingMember(engTeamaMembeListrWithoutAssignment[j].teamMemberRole,j)">
                                    <option value="null" disabled hidden>Select team member role</option>
                                    <option *ngFor="let role of teamMemberRoleFromAppMstList"
                                      [value]="role.applicationMasterId">
                                      {{role.value}}
                                    </option>
                                  </select>
                                </ng-template>

                              </div>
                              <div class="selectdiv "
                                *ngIf="isUserAgentIE > 0 && engTeamaMembeListrWithoutAssignment[j].teamMemberRole!==getTeamMemberRoleIdByCode('EP') ">
                                <select class="dropdown rounded-0 form-control" name="teamMemberRole{{j}}"
                                  id="teamMemberRole_Row_{{j}}"
                                  [(ngModel)]="engTeamaMembeListrWithoutAssignment[j].teamMemberRole"
                                  (change)="OnRoleChangeForExistingMember(engTeamaMembeListrWithoutAssignment[j].teamMemberRole,j)">
                                  <option value="null" disabled hidden>Select team member role</option>
                                  <option *ngFor="let role of applicationmasterListIE"
                                    [value]="role.applicationMasterId">
                                    {{role.value}}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </td>
                          <td class="">
                            <div class="form-group mb-0">
                              <div class="selectdiv ">
                                <select class="dropdown rounded-0 form-control" name="teamMemberPermissions{{j}}"
                                  id="teamMemberPermissions_Row_{{j}}"
                                  [(ngModel)]="engTeamaMembeListrWithoutAssignment[j].teamMemberPermissionId">
                                  <option value="null" selected disabled hidden>Select permission</option>
                                  <option *ngFor="let permission of teamMemberPermissions" [value]="permission.id">
                                    {{permission.typeValue}}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </td>
                          <td class="pr-1 txt-last">
                            <a *ngIf="engTeamaMembeListrWithoutAssignment[j].teamMemberRole!==getTeamMemberRoleIdByCode('EP') &&
                        engTeamaMembeListrWithoutAssignment[j].teamMemberRole!==getTeamMemberRoleIdByCode('PR') "
                              id="removeTeamMemberLink_{{j}}" (click)="removeAssignmentTeamMember(j)">
                              <img class="" src="assets/images/EDP/Engagement/remove_circle_outline.svg" alt="remove"
                                class="middle-align">
                            </a>
                          </td>
                        </tr>
                        <tr *ngIf="isKeyTeamMemberFeatureFlag && isAudit" id="errorLabelkeyTeamMemberRole{{j}}"
                          class="d-none">
                          <td></td>
                          <td></td>
                          <td style="vertical-align: text-top; padding: 0px 0px 0px 12px;">
                            <span class="label error-color">Select team member role</span>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr *ngIf="engTeamaMembeListrWithoutAssignment[j].keyTeamMemberRoleId==getKeyTeamMemberRoleIdByCode('OTH') 
                        && engTeamaMembeListrWithoutAssignment[j].isKeyTeamMember==true">
                          <td></td>
                          <td></td>
                          <td colspan="2">
                            <div>
                              <textarea name="txtKeyTeamMemberDescription_{{j}}" id="txtKeyTeamMemberDescription_{{j}}"
                                [(ngModel)]="engTeamaMembeListrWithoutAssignment[j].description"
                                class="form-control rounded-0 h-30 edp-font-14  w-97" maxlength="100"
                                style="resize: none;"></textarea>
                              <a class="link-text-blue float-right" style="text-decoration: underline;"
                                (click)="SaveKeyTeamMemberDescription(j)" hidden>
                                <span class="edp-font-14">Save</span>
                              </a>

                            </div>
                          </td>
                          <td></td>
                        </tr>
                      </div>
                    </tbody>
                  </table>

                  <div class="mt-4 font-weight-bold">Client users</div>
                  <table class="table table-borderless assignmentTeamDetails" aria-describedby="assignmentTeamDetails">
                    <thead>
                      <th scope="col" width="10%">First name</th>
                      <th scope="col" width="8%">Last name </th>
                      <th scope="col" width="13%">Email </th>
                      <th scope="col" width="10%">Title</th>
                      <th scope="col" width="14.89%">Site permissions </th>
                      <th scope="col" width="2%" class="txt-last"></th>
                    </thead>
                    <tbody class="bg-edp-body edp-font-13">
                      <tr *ngFor="let member of clientTeamMembersList; let j=index">
                        <td class="td-sep">
                          <div class="hdnteams" style="display: none;">
                            <input type="hidden" name="hdnTeamCollaborationId" id="hdnTeamCollaborationId"
                              class="form-control" value="{{member.collaborationSiteId}}">
                            <input type="hidden" name="memberName" id="memberName" class="form-control"
                              value="{{member.firstName}}">
                            <input type="hidden" name="clientMemberId" id="clientMemberId" class="form-control"
                              value="{{member.EmailId}}">
                          </div>
                          <span class="label alert-pre">{{member.firstName}}</span>
                        </td>
                        <td class="td-sep"> <span class="label alert-pre">{{member.lastName}}</span></td>

                        <td class="td-sep"> <span class="label alert-pre">{{member.emailId}}</span></td>

                        <td class="td-sep">
                          <span class="label alert-pre ">{{member.title}}</span>
                        </td>
                        <td class="td-sep">
                          <div class="form-group mb-0 h-28"
                            [ngClass]="{'eng-div-disabled' : ( engagement?.status !== EngagementStatus.EngNotStarted && EditMode && !isSiteProvisioned) }">
                            <div class="selectdiv">
                              <select class="dropdown rounded-0 form-control h-30"
                                name="clientTeamMemberPermissions_Row_{{j}}" id="clientTeamMemberPermissions_Row_{{j}}"
                                [(ngModel)]="member.permissionId">
                                <option value="0" selected disabled hidden>Select permission</option>
                                <option *ngFor="let permission of clientMemberPermissionModelList"
                                  [value]="permission.id">
                                  {{permission.typeValue}}
                                </option>
                              </select>
                            </div>
                          </div>
                        </td>

                        <td class="txt-last pr-1">
                          <a id="removeClientTeamMemberLink_{{j}}" *ngIf="!isEngagementClosed"
                            [ngClass]="{'eng-div-disabled' : ( engagement?.status !== EngagementStatus.EngNotStarted && EditMode && !isSiteProvisioned) }"
                            (click)="removeCollabClientTeamMember(j)">
                            <img class="" src="assets/images/EDP/Engagement/remove_circle_outline.svg" alt="remove"
                              class="middle-align">
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!--end teamdetails_-->
              </div>
              <!--end assignmentTeamMemberList[0].teamMemberList.length > 0-->
            </div>
            <!-- start divAddNewTeamMember -->
            <div id="divAddNewTeamMember"
              *ngIf="engTeamaMembeListrWithoutAssignment.length > 0 && teamMemberList.length > 0" class="row ml-0">
              <div id="tblTeamMember" aria-describedby="Team members" class="w-100 mb-2">
                <div class="col-xl-11 col-md-11 mt-3 font-weight-bold">Add team members</div>
                <div *ngFor="let teamMember of teamMemberList;let i = index" id="clientUserListContainer"
                  class="teammemberDataRow row mx-0 pb-3 bg-white" style="justify-content: space-around;">
                  <div class="col-xl-4 col-md-11 mt-3">
                    <div class="input-group mx-100">
                      <app-people-picker class="eng-people-picker" [roleShortName]="teamMemberRoleName"
                        [labelCtrlName]="teamMemberlblCtrlName" [isLabelVisible]="false" [InputCtrlIdIndex]="i"
                        [PeoplePickerModel]="teamMember.memberName" [userEmail]="teamMember.memberEmailId"
                        [IsValidateUserWithADGroup]="validateTM[i]" [memberFirmDetails]="memberFirmData">
                      </app-people-picker>

                      <div class="input-group-append input-height">
                        <button type="button" id="PPIcon_{{peoplepickerIndex}}" data-toggle="modal"
                          data-target="#modal-TeamMember" data-keyboard="false" data-backdrop="static"
                          class="mw-100 people-picker-button text-center"
                          (click)="refreshTeamMemberPopup(i,'txtPeoplePicker_')">
                          <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="Team Member" />
                        </button>
                      </div>
                    </div>
                    <div>
                      <span id="errorLabelMemberName{{i}}" class="label error-color "
                        *ngIf="this.arrValidationForNewTeamMember[i].memberName==null ||this.arrValidationForNewTeamMember[i].memberName==''"
                        style="display:none;">Select team member name</span>
                      <span id="userExist{{i}}" class="label error-color"
                        *ngIf="(this.arrValidationForNewTeamMember[i].isNewUserDuplicate || this.arrValidationForNewTeamMember[i].isUserExist ) && this.arrValidationForNewTeamMember[i].memberName !=null && this.arrValidationForNewTeamMember[i].memberName != ''">User
                        already exists!</span>
                      <span class="label error-color"
                        *ngIf="!this.arrValidationForNewTeamMember[i].ishdnValidInputName && this.arrValidationForNewTeamMember[i].memberName !=null && this.arrValidationForNewTeamMember[i].memberName != '' ">
                        Team member should be valid</span>
                    </div>
                  </div>
                  <span class="devider px-0 text-center hide-me" *ngIf="isKeyTeamMemberFeatureFlag && isAudit"></span>

                  <div class="col-xl-1 col-md-2 mt-4" *ngIf="isKeyTeamMemberFeatureFlag && isAudit">
                    <span>Key</span>
                    <label class="custom-check-key edp-font-12">
                      <input type='checkbox' name='chkKeyTeamMember_{{i}}' id='chkKeyTeamMember_{{i}}'
                        [(ngModel)]="teamMember.isKeyTeamMember" (change)="OnKeyTeamMemberChkboxChecked($event,i)">
                      <span class="checkmark-custom-key"></span>
                    </label>
                  </div>

                  <span class="devider px-0 text-center hide-me"></span>

                  <div class="col-xl-3 col-md-5 mt-3">
                    <div *ngIf="isUserAgentIE < 0" class="selectdiv">
                      <select *ngIf="isAudit; else isTeamMember" id="drpKeyTeamMemberRole_{{i}}" required
                        name="KeyTeamMemberRole_{{i}}" class="form-control dropdown rounded-0"
                        (change)="OnKeyRoleChange($event,i)">
                        <option value="null">Select role</option>
                        <option id="drpKeyTeamMembersOptions_{{i}}" *ngFor="let role of keyteamMemberRoleFromAppMstList"
                          [value]=role.applicationMasterId>
                          {{role.value}}
                        </option>
                      </select>
                      <ng-template #isTeamMember>
                        <select id="drpTeamMemberRole_{{i}}" required name="TeamMemberRole_{{i}}"
                          class="form-control dropdown rounded-0" (change)="OnRoleChange($event,i)">
                          <option value="null" disabled hidden>Select role</option>
                          <option id="drpTeamMembersOptions_{{i}}" *ngFor="let role of teamMemberRoleFromAppMstList"
                            [value]=role.applicationMasterId selected>
                            {{role.value}}
                          </option>
                        </select>
                      </ng-template>
                    </div>
                    <div *ngIf="isUserAgentIE > 0" class="selectdiv">
                      <select id="drpTeamMemberRole_{{i}}" required name="TeamMemberRole_{{i}}"
                        class="form-control dropdown rounded-0" (change)="OnRoleChange($event,i)">
                        <option value="" disabled hidden>Select role</option>
                        <option id="drpTeamMembersOptions_{{i}}" *ngFor="let role of applicationmasterListIE"
                          [value]=role.applicationMasterId>
                          {{role.value}}
                        </option>
                      </select>
                    </div>
                    <div>
                      <span id="errorLabelMemberRole{{i}}" class="label error-color d-none">
                        Select team member role</span>
                    </div>
                  </div>

                  <span class="devider px-0 text-center hide-me"></span>

                  <div class="col-xl-3 col-md-6 mt-3 mr-4">
                    <div class="selectdiv ">
                      <select id="drpTeamMemberPermissions_{{i}}" name="TeamMemberPermission_{{i}}"
                        class="form-control dropdown rounded-0">
                        <option value="-1">Select permissions</option>
                        <option id="drpTeamMemberPermissionsOptions_{{i}}"
                          *ngFor="let permission of teamMemberPermissions" [value]=permission.id>
                          {{permission.typeValue}}
                        </option>
                      </select>
                    </div>
                    <div>
                      <span id="errorLabelMemberPermission{{i}}" class="label error-color d-none">
                        Select team member permission</span>
                    </div>
                  </div>
                  <div class="pl-0 delRow">
                    <a id="removeLink_{{i}}" (click)="removeTeamMember(i,teamMemberRoleName)"
                      style="line-height: 38px;">
                      <img src="assets/images/EDP/Engagement/remove_circle_outline.svg" alt="Remove Team Member">
                    </a>
                  </div>
                  <div class="col-xl-5"></div>
                  <div class="keyDescription" id="keyTeamMemberDescription_{{i}}" style="display: none;">
                    <span>Description</span>
                    <textarea name="txtKeyDescription_{{i}}" id="txtKeyDescription_{{i}}"
                      [(ngModel)]="teamMember.description" class="form-control rounded-0 h-30 edp-font-12  w-97"
                      maxlength="100" style="resize: none;"></textarea>
                  </div>

                </div>
              </div>
            </div>
            <!-- end divAddNewTeamMember -->
            <!-- <div *ngIf="engTeamaMembeListrWithoutAssignment.length > 0 &&  teamMemberList.length>0" class="row ml-0">
            <div class="col-md-8"></div>
            <div class="col-md-4 text-right pr-0">
              <button id="btnUpdateTeam" type="button" class="btn my-3 w-100"
                (click)="UpdateTeamMemberInExistingAssignment()">
                <img src="/assets/images/EDP/Engagement/perm_identity.svg" alt="Update team(s)"
                  style="cursor: pointer; margin-right: 3px;">
                Update team(s)</button>
            </div>
          </div> -->
            <!-- start Add client TeamMember -->
            <div class="divAddNewTeamMember pt-2 pb-2"
              *ngIf="engTeamaMembeListrWithoutAssignment.length > 0 && newClientTeamMemberList.length > 0"
              id="clientTeamdetails">
              <div class="col-xl-11 col-md-11 mt-3 font-weight-bold">Add client users</div>
              <div class="teammemberDataRow row mx-0 p-0 bg-white">
                <table class="table table-borderless">
                  <tbody class="edp-font-13 pt-2">
                    <hr class="d-none" id="divNewClientTeamMember" *ngIf="newClientTeamMemberList.length > 0">
                    <tr class="pt-2 pb-2" *ngFor="let clientTeamMember of newClientTeamMemberList;let t = index">
                      <td class="td-sep">
                        <div class=" pl-2">
                          <input type="text" name="txtFirstName_{{t}}" id="txtFirstName_{{t}}" placeholder="First name"
                            class="form-control rounded-0 h-30 edp-font-12 w-97"
                            [(ngModel)]="newClientTeamMemberList[t].firstName">
                          <div>
                            <span id="errorLabelFirstName{{t}}" class="label error-color edp-font-12"
                              style="display:none;">First name is required</span>
                          </div>
                        </div>
                        <span class="devider px-0 text-center hide-me"></span>
                      </td>
                      <td class="td-sep2">
                        <div class="">
                          <input type="text" name="txtLastName_{{t}}" id="txtLastName_{{t}}" placeholder="Last name"
                            class="form-control rounded-0 h-30 edp-font-12  w-97"
                            [(ngModel)]="newClientTeamMemberList[t].lastName">
                          <div>
                            <span id="errorLabelLastName{{t}}" class="label error-color edp-font-12"
                              style="display:none;">Last name is required</span>
                          </div>

                        </div>
                        <span class="devider px-0 text-center hide-me"></span>
                      </td>
                      <td class="td-sep2">
                        <div class="">
                          <input type="text" autocomplete="no-fill" (cut)="$event.preventDefault()"
                            (copy)="$event.preventDefault()" (paste)="$event.preventDefault()"
                            name="txtClientEmail_{{t}}" id="txtClientEmail_{{t}}" placeholder="Email"
                            class="form-control rounded-0 h-30 edp-font-12  w-97"
                            [(ngModel)]="newClientTeamMemberList[t].emailId" maxlength="50"
                            (blur)="checkSpaceChartBeginInTableRow($event,t,'txtClientEmail_');ValidateClientEmailField(t);">
                          <div>
                            <span id="errorLabelEmail{{t}}" style="display:none;" class="label error-color edp-font-12">
                              Client email is required </span>
                          </div>
                          <div class="label error-color edp-font-12"
                            *ngIf="!(arrValidationForNewClientTeamMember[t].emailId == null || arrValidationForNewClientTeamMember[t].emailId=='') && arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberEmail">
                            Client email should be in valid format.
                          </div>
                          <div class="label error-color edp-font-12"
                            *ngIf="!(arrValidationForNewClientTeamMember[t].emailId == null || arrValidationForNewClientTeamMember[t].emailId=='') && arrValidationForNewClientTeamMember[t].isNewUserDuplicate">
                            <p>Email already exists! Please enter unique email </p>
                          </div>
                          <div id="userExistClient{{t}}" class="label error-color edp-font-12"
                            *ngIf="arrValidationForNewClientTeamMember[t].cmsDataMatchClient ">User already exists!
                          </div>
                          <br>
                          <div class="">
                            <input type="email" autocomplete="no-fill" (cut)="$event.preventDefault()"
                              (copy)="$event.preventDefault()" (paste)="$event.preventDefault()"
                              name="txtconfirmClientEmail_{{t}}" id="txtconfirmClientEmail_{{t}}"
                              placeholder="Confirm Email" (blur)="ValidateConfirmClientEmailField(t)"
                              class="form-control rounded-0 h-30 edp-font-12  w-97"
                              [(ngModel)]="newClientTeamMemberList[t].clientEmailId" maxlength="50">
                          </div>
                          <div>
                            <span id="errorLabelConfirmEmail{{t}}" style="display:none;"
                              class="label error-color edp-font-12">
                              <p>Confirm client email is required</p>
                            </span>
                          </div>
                          <div class="label error-color edp-font-12"
                            *ngIf="!(arrValidationForNewClientTeamMember[t].clientEmailId == null || arrValidationForNewClientTeamMember[t].clientEmailId=='') && arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberConfirmEmail">
                            Confirm client email should be in valid format.
                          </div>
                          <div>
                            <span id="errorLabelConfirmEmailMatch{{t}}" style="display:none;"
                              class="label error-color edp-font-12">
                              <p>Emails do not match.</p>
                            </span>
                          </div>
                        </div>
                      </td>
                      <td class="td-sep2">
                        <div class="">
                          <input type="text" name="txtClientTitle_{{t}}" id="txtClientTitle_{{t}}" placeholder="Title"
                            class="form-control rounded-0 h-30 edp-font-12 w-97"
                            [(ngModel)]="newClientTeamMemberList[t].title" maxlength="50"
                            (blur)="checkSpaceChartBegin($event, t)">
                        </div>
                      </td>
                      <td class="td-sep2">
                        <div class="h-28">
                          <div class="selectdiv  ">
                            <select id="drpClientTeamMemberPermissions_{{t}}" name="ClientTeamMemberPermission_{{t}}"
                              class="form-control dropdown rounded-0 edp-font-12 h-30"
                              [(ngModel)]="newClientTeamMemberList[t].permissionId">
                              <option value="0" disabled hidden>Select permissions</option>
                              <option id="drpTeamMemberPermissionsOptions_{{t}}"
                                *ngFor="let permission of clientMemberPermissionModelList" [value]=permission.id>
                                {{permission.typeValue}}
                              </option>
                            </select>
                          </div>
                          <div>
                            <span id="errorLabelClientTeamMemberPermission{{t}}" style="display:none;"
                              class="label error-color edp-font-12">
                              Select permission</span>
                          </div>
                        </div>
                      </td>
                      <td class="txt-last align-top txt-last-top">
                        <div>
                          <a id="removeLinkGTUser_{{t}}" (click)="removeNewCollabClientTeamMember(t)">
                            <img src="assets/images/EDP/Engagement/remove_circle_outline.svg" alt="remove"
                              class="middle-align">
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- end div Add client TeamMember -->

            <!-- start Team members button -->


            <div
              *ngIf="engTeamaMembeListrWithoutAssignment.length > 0 &&  (newClientTeamMemberList.length>0 || teamMemberList.length>0)"
              class="row ml-0">
              <div class="col-md-8"></div>
              <div class="col-md-4 text-right pr-0">
                <button id="btnUpdateTeam" type="button" class="btn my-3 w-100" (click)="btnUpdateTeamBtnClick()">
                  <img src="/assets/images/EDP/Engagement/perm_identity.svg" alt="Update team(s)"
                    style="cursor: pointer; margin-right: 3px;">
                  Update team(s)</button>
              </div>
            </div>

            <div *ngIf="engTeamaMembeListrWithoutAssignment.length > 0 " class="row mx-0 py-2">
              <div class="col-md-6 mw-100 pl-0">
                <div class="px-2 bg-white">
                  <button class="btn AddMemberButton" type="button" id="addGTTeamMember" (click)="addTeamMember()">
                    <img src="assets/images/EDP/Engagement/add_box-blue.svg" alt="Add new team member(s)">
                    <span class="d-inline-block pl-2"> Add team member </span>
                  </button>
                </div>
              </div>
              <div class="col-md-6 pl-2 mw-100 pr-0 d-flex flex-column">
                <div class="px-2 bg-white" [class.nonClickableLink]="isMemberFirm">
                  <button class="btn AddMemberButton" type="button" id="addClientTeamMember"
                    (click)="addClientTeamMember()">
                    <img src="assets/images/EDP/Engagement/add_box-blue.svg" alt="Add new team member(s)">
                    <span class="d-inline-block pl-2"> Add client user </span>
                  </button>
                </div>
                <p></p>
                <span id="clientErrorMessage" class="ErrorFont"></span>
              </div>
            </div>
            <!-- end Team members button -->
          </div>
        </div>
        <!--end TeamMemberDiv-->
        <!--Start Convene Project div-->
        <div class="row m-0"
          *ngIf="isConveneIntegrationFlagEnabled && isContinuanceEngagement && engagement.status == EngagementStatus.EngNotStarted">
          <div class="p-4 px-3 w-100">
            <hr>
          </div>
          <div class="col-md-12">
            <h6 class="ml-0 form-heading-text mb-3">
              Convene project
            </h6>
            <div>
              <div>
                <p class="mb-2">
                  Would you like to clone a related, existing Convene project?
                </p>
                <div class="CloneConveneProject">
                  <label class="custom-radio mr-5">Yes
                    <input type="radio" name="CloneConveneProject"
                      [checked]="baseConeveneProjectInitiationModel.isCloneProject == true"
                      (change)="CloneConveneProjectSetup('true')">
                    <span class="checkmark radio-signing-type-clone"></span>
                  </label>
                  <label class="custom-radio">No
                    <input type="radio" name="CloneConveneProject"
                      [checked]="baseConeveneProjectInitiationModel.isCloneProject == false"
                      (change)="CloneConveneProjectSetup('false')">
                    <span class="checkmark radio-signing-type-new"></span>
                  </label>
                </div>
              </div>
              <div *ngIf="baseConeveneProjectInitiationModel.isCloneProject">
                <label><span class="label">Select project</span></label>
                <div class="selectdiv w-50">
                  <select id="drpconveneProjects" name="conveneProjectId" class="dropdown rounded-0"
                    [disabled]="conveneProjectDetailsResponse == null" #conveneProjectId="ngModel"
                    [(ngModel)]="baseConeveneProjectInitiationModel.cloneProjectid">
                    <option value="null" disabled hidden>Select convene project</option>
                    <option *ngFor="let project of conveneProjectDetailsResponse" [value]=project.projectId>
                      {{project.projectName}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-12 px-0 select-slim" class="error-color"
                *ngIf="baseConeveneProjectInitiationModel.isCloneProject && conveneProjectDetailsResponse == null">
                Please select "No" there are no convene projects available.
              </div>
              <div *ngIf="baseConeveneProjectInitiationModel.isCloneProject  && conveneProjectDetailsResponse != null">
                <h6 class="ml-0 form-heading-text mt-4 mb-0">
                  Status
                </h6>
                <p class="mb-2">
                  Set the status of all requests in this new, cloned project:
                </p>
                <div class="StatusConveneProject">
                  <label class="custom-radio mr-5">Draft
                    <input type="radio" name="StatusConveneProject"
                      [checked]="baseConeveneProjectInitiationModel.statusOfTheProject == 'Draft'"
                      (change)="StatusConveneProjectSetup('Draft')">
                    <span class="checkmark radio-signing-type-draft"></span>
                  </label>
                  <label class="custom-radio">Open
                    <input type="radio" name="StatusConveneProject"
                      [checked]="baseConeveneProjectInitiationModel.statusOfTheProject == 'Open'"
                      (change)="StatusConveneProjectSetup('Open')">
                    <span class="checkmark radio-signing-type-open"></span>
                  </label>
                </div>
              </div>
              <div *ngIf="baseConeveneProjectInitiationModel.isCloneProject  && conveneProjectDetailsResponse != null">
                <h6 class="ml-0 form-heading-text mt-4 mb-0">
                  Due dates
                </h6>
                <p class="mb-2">
                  How would you like to roll forward the current due dates?
                </p>
                <div class="DueDatesConveneProject">
                  <label class="custom-radio mr-5">Remove all due dates.
                    <input type="radio" name="DueDatesConveneProject"
                      [checked]="baseConeveneProjectInitiationModel.dueDates == 'Remove'"
                      (change)="DueDatesConveneProjectSetup('Remove')">
                    <span class="checkmark radio-signing-type-clone"></span>
                  </label><br>
                  <label class="custom-radio">Keep due dates as currently assigned.
                    <input type="radio" name="DueDatesConveneProject"
                      [checked]="baseConeveneProjectInitiationModel.dueDates == 'Keep'"
                      (change)="DueDatesConveneProjectSetup('Keep')">
                    <span class="checkmark radio-signing-type-new"></span>
                  </label><br>
                  <label class="custom-radio">Roll forward due dates to the next year.
                    <input type="radio" name="DueDatesConveneProject"
                      [checked]="baseConeveneProjectInitiationModel.dueDates == 'Rollforward'"
                      (change)="DueDatesConveneProjectSetup('Rollforward')">
                    <span class="checkmark radio-signing-type-new"></span>
                  </label>
                </div>

              </div>

              <div *ngIf="baseConeveneProjectInitiationModel.isCloneProject  && conveneProjectDetailsResponse != null">
                <h6 class="ml-0 form-heading-text mt-4 mb-0">
                  Users
                </h6>
                <p class="mb-2">
                  Would you like to include existing users?
                </p>
                <div class="UsersConveneProject">
                  <label class="custom-radio  mr-5">Yes
                    <input type="radio" name="UsersConveneProject"
                      [checked]="baseConeveneProjectInitiationModel.isUsers == true"
                      (change)="UsersConveneProjectSetup('true')">
                    <span class="checkmark radio-signing-type-new"></span>
                  </label>&nbsp;&nbsp;&nbsp;
                  <label class="custom-radio">No
                    <input type="radio" name="UsersConveneProject"
                      [checked]="baseConeveneProjectInitiationModel.isUsers == false"
                      (change)="UsersConveneProjectSetup('false')">
                    <span class="checkmark radio-signing-type-clone"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--End Convene Project div-->

        <!-- Start Template Div-->
        <div class="p-2 px-3">
          <hr>
        </div>

        <div class="row m-0">
          <div class="col-md-12">
            <h6 class="ml-0 form-heading-text">
              Governing Agreements
            </h6>
            <div class="row m-0 p-0">

              <p class="mb-2" *ngIf="!isCLMFeatureEnabled || isMemberFirm || isMemberFirmEngagement">
                If applicable, select the previously executed universal/evergreen EL or MSA you will be using for this
                engagement from the items below, or if you will be using an agreement that is not listed, select the
                “Document exists elsewhere” option and input the relevant details. If a new MSA or engagement letter
                (universal/evergreen or stand-alone) is required for this engagement, select “Create new engagement
                letter
                or MSA” option.
              </p>
              <div class="row pl-0 pr-0 mr-0 ml-0">
                <div class="col-md-12 px-0 mb-2"
                  *ngIf="isCLMFeatureEnabled && !isMemberFirm && !isMemberFirmEngagement">
                  <p class="mb-3">
                    Select or upload an existing governing agreement, create a new governing agreement, or proceed
                    without selecting or creating a governing agreement at this time.
                  </p>
                  <div *ngIf="clmGoverningAgreementUIState == 'PAGE_LOAD'" class="agreement-box mb-3 text-center">
                    <div class="m-auto">To view existing agreements, you must complete the basic engagement information
                      above.</div>
                  </div>
                  <div *ngIf="clmGoverningAgreementUIState == 'CLM_LOADING'" class="clm-box mb-3">
                    <div class="loader mt-4"></div>
                    <div class="mt-5 pt-3">Searching for existing agreements...</div>
                  </div>
                  <div #clmGoverningAgreementDiv></div>
                  <p class="mb-0" *ngIf="!isCLMFeatureEnabled || isMemberFirm">Or, choose from the following options:
                  </p>
                  <!-- <div *ngIf="governingAgreementResponse?.HTML!=undefined"[innerHtml]="governingAgreementResponse?.HTML"></div> -->
                </div>
                <div class="col-md-12 px-0" *ngIf="!isCLMFeatureEnabled || isMemberFirm ||isMemberFirmEngagement ">
                  <div class="edp-font-13 my-1" *ngFor="let documents of clientLevelDocuments">
                    <label class="custom-radio isMemberFirm">
                      <span class="mb-0 pos-top ">
                        <span class="font-weight-bold">{{documents.documentSubTypeValue}}</span> - Created:
                        {{documents.effectiveDate | date: "MM/dd/yyyy"}} -
                        Expires: {{documents.expirationDate | date: "MM/dd/yyyy"}}
                      </span>
                      <input type="radio" name="DocumentTypes"
                        value="{{documents.documentSubTypeCode}}:{{documents.clientLevelDocumentId}}"
                        [(ngModel)]="SelectedDocumentSubTypeValue" id='engagement-DocumentTypes-radio'
                        (change)="onDocumentSelectionChange()"
                        [attr.disabled]="isMemberFirm === true ? 'disabled' : null">
                      <span class="checkmark"></span>
                      <span class="mr-3" *ngIf="documents.inProgressEngagementCount == 0 && (documents.documentSubTypeCode !== CustomMSACode || 
                    (documents.documentSubTypeCode == CustomMSACode && documents.engLevelCustonMSA ) )">
                        <app-governing-agreement-actions class="eng-people-picker"
                          id="governing-agreement_{{MappedValue}}_{{documents.clientLevelDocumentId}}"
                          [clientLevelDocument]="documents" [parentComponentName]="MappedValue"
                          (UpdatedClientLevelDocList)="updateClientLevelDocumentList($event)"
                          (SelectedGoverningAgreementDetails)="SetGoverningAgreementSelection($event)"
                          [isMemberFirm]="isMemberFirm">
                        </app-governing-agreement-actions>
                      </span>
                    </label>
                    <div id="agreementDescription_{{MappedValue}}_{{documents.clientLevelDocumentId}}">
                      <p class="ml-4 mb-0 alert-pre">
                        Description: {{documents.documentDescription}} *
                      </p>
                      <div class="ml-4 mb-0">
                        <span class="">GT agreement owner: </span>
                        <span class="link-text-blue contactUser cursor-pointer font-weight-bold"
                          id="GTAgreementOwner_{{documents?.clientLevelDocumentId}}"
                          (click)="displayContactPopover(documents?.gtAgreementOwnerEmail, 'GTAgreementOwner_'+ documents?.clientLevelDocumentId)">
                          {{documents.gtAgreementOwnerName}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 px-0 edp-font-13 my-1"
                  *ngIf="!isCLMFeatureEnabled || isMemberFirm ||isMemberFirmEngagement ">
                  <label class="custom-radio isMemberFirm">
                    <span class="mb-0 pos-top ">
                      {{ DocumentExistsElsewhere }}
                    </span>
                    <input type="radio" name="DocumentTypes" value="{{DocumentExistsElsewhere}}:0"
                      [(ngModel)]="SelectedDocumentSubTypeValue" id='engagement-DocumentTypes-radio'
                      (change)="onDocumentSelectionChange()"
                      [attr.disabled]="(isMemberFirm )=== true ? 'disabled' : null">
                    <span class="checkmark"></span>
                  </label>
                </div>
                <!--start of add EL-MSA section-->
                <div class="multi-user-eng col-12 px-0 pb-1 docExistsElsewhereContentDiv" style="display: none;">
                  <div class="row  ml-0 ">
                    <div class="col-md-4 col-lg-3">
                      <label>Type <span class="mandatory" *ngIf="isDocPresentElsewhere"> *</span></label>
                      <div class="selectdiv" [ngClass]="{'eng-div-disabled' : !isDocPresentElsewhere }">
                        <select (change)="onSubDocumentTypeChange()" class="dropdown rounded-0 " name="documentSubType"
                          id="eng-documentSubType" [(ngModel)]="clientLevelDocument.documentSubTypeId">
                          <option [value]="undefined" selected disabled hidden>Select type</option>
                          <option *ngFor="let type of subDocumentTypes" [value]="type.applicationMasterId">{{ type.value
                            }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-12 px-0 select-slim" class="error-color" *ngIf="isInvalidSubDocType">
                        Type field is required
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3" [ngClass]="{'eng-div-disabled': !isDocPresentElsewhere}">
                      <label for="msaEffectiveDate">Effective date<span class="mandatory" *ngIf="isDocPresentElsewhere">
                          *</span></label>
                      <app-calendercontrol id="msaEffectiveDate" class="effective-date"
                        [txtDate]="msaEffectiveDateCntrlId">
                      </app-calendercontrol>
                      <div class="col-md-12 px-0 select-slim" class="error-color" *ngIf="isEffectiveDateEmpty">
                        Effective date field is required
                      </div>
                      <div class="col-md-12 px-0 select-slim" class="error-color" *ngIf="!isvalidEFD">
                        Effective date should be valid
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3" [ngClass]="{'eng-div-disabled': !isDocPresentElsewhere}">
                      <label for="msaExpirationDate">Expiration date </label>
                      <app-calendercontrol id="msaExpirationDate" [txtDate]="msaExpirationDateCntrlId"
                        class="expiration-date"></app-calendercontrol>
                      <div *ngIf="isInvalidMSAExpirationDate || isInvalidMSAEffectiveDate" class="error-color">
                        Expiration date should be current/future date and greater than effective date
                      </div>
                      <div class="col-md-12 px-0 select-slim" class="error-color" *ngIf="!isvalidEXD">
                        Expiration date should be valid
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6" [ngClass]="{'eng-div-disabled': !isDocPresentElsewhere}">
                      <label><span class="label">GT agreement owner
                        </span></label>
                      <div class="input-group mw-100">
                        <app-people-picker class="eng-assign-people-picker" [IsPickerForAssignment]=false
                          [roleShortName]="GTAgreementOwnerRoleName" [labelCtrlName]="GTAgreementOwnerlblCtrlName"
                          [isLabelVisible]="false" [InputCtrlIdIndex]="peoplepickerIndex"
                          [PeoplePickerModel]="clientLevelDocument.gtAgreementOwnerName"
                          [userEmail]="clientLevelDocument.gtAgreementOwnerEmail"
                          [IsValidateUserWithADGroup]="validateGTAgreementOwner">
                        </app-people-picker>

                        <div class="input-group-append input-height">

                          <button type="button" id="PPIcon_{{peoplepickerIndex}}" data-toggle="modal"
                            data-target="#modal-GTAgreementOwner" data-keyboard="false" data-backdrop="static"
                            class="mw-100 people-picker-button text-center"
                            (click)="refreshGTAgreementOwnerPopup('txtPeoplePicker_')">
                            <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="GT agreement owner" />
                          </button>
                        </div>

                      </div>
                      <div class="form-control-feedback error-color" *ngIf="!isvalidGTAgreementOwner">
                        <p>GT agreement owner should be valid</p>
                      </div>
                    </div>
                    <div class="col-md-12 mt-3" [ngClass]="{'eng-div-disabled': !isDocPresentElsewhere}">
                      <label>Description </label>
                      <input class="form-control rounded-0 border input-height bg-white" id="txtDocumentDescription"
                        name="txtDocumentDescription" placeholder="Enter description" type="text"
                        [(ngModel)]="clientLevelDocument.documentDescription" maxlength="200" autocomplete="off">
                      <div
                        *ngIf="clientLevelDocument.documentDescription && clientLevelDocument.documentDescription.length > 200"
                        class="error-color">
                        Description character length exceeded(Allowed 200 chars)
                      </div>
                      <p class="mt-3 font-bold">
                        Upload the existing agreement in the supplemental document section on either the Questionnaire
                        page or the Review page within Engage.
                      </p>
                    </div>
                  </div>
                </div>
                <!--end of add EL-MSA section-->
                <div class="col-md-12 px-0 edp-font-13 my-1"
                  *ngIf="!isCLMFeatureEnabled || isMemberFirm ||isMemberFirmEngagement">
                  <label class="custom-radio isMemberFirm">
                    <span class="mb-0 pos-top ">
                      {{ DocumentNoOptionExists}}
                    </span>
                    <input type="radio" name="DocumentTypes" value="{{DocumentNoOptionExists}}:0"
                      [(ngModel)]="SelectedDocumentSubTypeValue" id='engagement-DocumentTypes-radio'
                      (change)="onDocumentSelectionChange()" [checked]="isMemberFirm"
                      [attr.disabled]="(isMemberFirm  )=== true ? 'disabled' : null">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="px-3">
          <hr>
        </div>
        <div class="row ml-1">

          <div class="col-12 col-xl-6 my-2 ">
            <h6 class="ml-0 form-heading-text mb-3">Engagement template</h6>
            <!-- <span class="mandatory">*</span> -->
            <label>Select the appropriate template for this engagement. If an appropriate template does not appear
              below, select a different governing agreement option above. </label>
            <div class=" w-97-sm selectdiv ">
              <select id="engAgreementType" [class.nonClickableDropDown]="isMemberFirm"
                [(ngModel)]="engagement.agreementType"
                [attr.disabled]="(isMemberFirm === true || existingGACheck) ? 'disabled' : null" name="agreementType"
                #engAgreementType="ngModel" (click)="isCLMFeatureEnabled && CLMRadioButtonValidation($event)"
                (ngModelChange)="TemplateChange(engagement.agreementType)" class="w-97">
                <option [value]="undefined" selected disabled hidden>Select...</option>
                <option *ngFor="let temp of SelectedTemplateList" [value]=temp.templatePackageId>
                  {{temp.templateName}}
                </option>
              </select>
            </div>
            <div class="error-color assignmentAdminMandatoryCheck"
              *ngIf="!arrValidationForEngagement[4]?.isvalid && arrValidationForEngagement[4]?.apply">
              <p>Document template is a required field </p>
            </div>
            <div class="error-color assignmentAdminMandatoryCheck"
              *ngIf="!isTemplateMappingFound && (SelectedDocumentSubTypeValue && SelectedDocumentSubTypeValue!='undefined:0')">
              <p>Document template mapping not found.</p>
            </div>
            <div class="error-color assignmentAdminMandatoryCheck" *ngIf="existingGACheck">
              <p>A governing agreement must be selected when indicating that the engagement will use an existing
                governing agreement from the list.</p>
            </div>
          </div>
        </div>

      </div>



      <div class="row p-2">
        <div id="EngandTeamMeberErrorDiv" class="alert alert-danger hide col-md-5">
          <span id="ErrormsgForAtLeastOne"></span>
        </div>
      </div>

      <div class="row m-0 pt-4">
        <div class="col-md-12 px-0">
          <app-alerts></app-alerts>
        </div>
      </div>

      <div class="row mx-0 my-3">
        <div *ngIf="dataModificationDetails.editStatus" class="alert-warning col-md-12">
          <span class="msg-icon">
            <img src="assets/images/EDP/Workspace/report_problem_warning.svg" alt="Inprogress session">
          </span>
          <span class="d-inline-block align-middle msg-text">
            This engagement is currently being edited by {{reviewInProgressEditEngagementMembers}} Your changes may not
            be
            saved
          </span>
        </div>
      </div>

      <div class="row m-0 text-right" *ngIf="EditMode==false">
        <div class="col-xl-3 hide-me">&nbsp;</div>
        <div class="col-xl-3 col-md-4 pl-0">
          <button class="btn SecondaryButton w-100 text-center" type="button" (click)="setCancelDialog();">
            <span class="d-inline-block align-middle"> Cancel</span>
          </button>
        </div>
        <div class="col-xl-3 col-md-4" *ngIf="EditMode==false">
          <button class="btn SecondaryButton w-100 text-center" type="button" (click)="saveEngagement()">
            <span>
              <img class="d-inline-block mr-1 align-top" src="../../../assets/images/icons/save.svg"
                alt="Save Changes" />
            </span>
            <span class="d-inline-block align-middle"> Save changes </span>
          </button>
          <span id="eng-save-success" class="text-right pt-1 d-none error-color">Data successfully saved.</span>
        </div>
        <div class="col-xl-3 col-md-4 pr-0">
          <button class="btn PrimaryButton w-100 text-center" id="btnNext" type="button"
            (click)="engagementNextClick()">
            Create engagement
            <img class="d-inline-block ml-1" src="../../../assets/images/icons/arrow_forward.svg"
              style="cursor: pointer; margin-bottom: 3px; width: 18px; height: 18px;" alt="Next" />
          </button>
        </div>
      </div>
      <div class="row m-0 text-right" *ngIf="EditMode">
        <div class="float-left col-xl-3 hide-me" *ngIf="!isContinuanceEngagement"></div>
        <div class="float-left col-xl-3 hide-me" *ngIf="isContinuanceEngagement">
          <button class="btn SecondaryButton w-100 text-center" type="button"
            (click)="workspaceActionButtonClick(AbandonedTitle, false)" id="teamMemberbtnAbandon"
            [disabled]="(!isContinuanceButtonEnabled) || (isSiteProvisioned)">
            <img class="abandonButtonIcon" alt="Abandon" style="width: 18px;height:18px;">
            <span class="d-inline-block ml-2 mr-2 align-middle">Abandon engagement</span>
            <img class="approveBtnIcon" alt="Tooltip" style="width: 18px;height:18px;" data-toggle="tooltip"
              data-html="true"
              title='<div class=&quot;customTooltip&quot;><b>Abandon Engagement</b> <br/> <p>Marks all documents as "read-only" and schedules the engagement for permanent deletion in 90 days.  This cannot be undone.</p></div>'>
          </button>
          <div class="form-control-feedback error-color" *ngIf="isTMAbandon">
            <p>Engagement abandoned</p>
          </div>
        </div>
        <div class="col-xl-3 col-md-3 pl-0"></div>
        <div class="col-xl-3 col-md-4">
          <button class="btn SecondaryButton w-100 text-center" type="button" (click)="setCancelDialog();">
            <span class="d-inline-block align-middle"> Cancel</span>
          </button>
        </div>
        <div class="col-xl-3 col-md-4 pr-0">
          <button class="btn PrimaryButton w-100 text-center" id="btnNext" type="button"
            (click)="engagementNextClick()">
            Update engagement
            <img class="d-inline-block ml-1" src="../../../assets/images/icons/arrow_forward.svg"
              style="cursor: pointer; margin-bottom: 3px; width: 18px; height: 18px;" alt="Next" />
          </button>
        </div>
      </div>
    </form>

  </div>

</div>

<ng-template #AccessDenied>
  <div>
    <app-alerts></app-alerts>
  </div>
</ng-template>

<div id="modal-AssignmentAdministrator" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title">Select assignment administrator </span>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="deselectAdmin();">
          <span class="close-text mr-1">Close </span>
          <span class="modal-close-btn">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span id="AssignmentAdminErrorMessage" class="ErrorFont"></span>

        <div class="input-group nav-searchbox-length pb-2">
          <input type="text" id="searchAssAdminName"
            (keydown.enter)="searchUsers('searchAssAdminName','tblAssAdminAD');"
            class="form-control py-1 rounded-0 nav-seachBox" placeholder="Search for assignment administrator"
            autocomplete="off">
          <span class="input-group-append nav-seachBox">
            <div class="input-group-text" (click)="searchUsers('searchAssAdminName','tblAssAdminAD');">
              <img src="../../../assets/images/EDP/Filters/search.svg" alt="Search" class="nav-searchIcon" />
            </div>
          </span>
        </div>
        <span class="span-hint mt-1">Hint: Last name, first name, or email.</span>

        <div class="tableScroll mt-2">
          <table id="tblAssAdminAD" aria-describedby="Assignment Admin" class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email </th>
                <th scope="col">Job Title</th>
                <th scope="col">Phone</th>
                <th scope="col">Line of Business</th>
                <th scope="col">Service Line</th>
                <th scope="col">Location</th>
              </tr>
            </thead>
            <tbody>
              <tr (click)='selectAdmin(i,$event)' id="trAssAdmins" name="trAssAdmins" style="cursor: pointer;"
                *ngFor="let member of membersListArray let i=index">
                <td> <span id='spAdmindisplayName{{i}}'>{{member.displayName}}</span>
                </td>
                <td> <span id='spAdminmail{{i}}'>{{member.mail}}</span>
                </td>
                <td>
                  <span id='spjobtitle{{i}}'>{{member.jobTitle}}</span>
                </td>
                <td> <span id='spAdminbusinessPhones{{i}}'>{{member.businessPhones}}</span>
                </td>
                <td> <span id='splineOfBusiness{{i}}'>{{member.lineOfBusiness}}</span>
                </td>
                <td> <span id='spserviceLine{{i}}'>{{member.serviceLine}}</span>
                </td>
                <td> <span id='splocation{{i}}'>{{member.location}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="SecondaryButton" style="width: 150px; text-align: center;" data-dismiss="modal"
          (click)="deselectAdmin();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
          </span>
          <span class="d-inline-block align-middle">Cancel</span>
        </button>
        <button type="button" class="PrimaryButton" style="width: 150px; text-align: center;" (click)="setAdmin();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
          </span>
          <span class="d-inline-block align-middle">OK</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div id="modal-TeamMember" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title">Select team member</span>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="deselectTeamMember();">
          <span class="close-text mr-1">Close </span>
          <span class="modal-close-btn">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span id="TeamMemberErrorMessage" class="ErrorFont"></span>
        <div class="input-group nav-searchbox-length pb-2">
          <input type="text" id="searchTeamMemberName"
            (keydown.enter)="searchUsers('searchTeamMemberName','tblMemberAD');"
            class="form-control py-1 rounded-0 nav-seachBox" placeholder="Search for team member" autocomplete="off">
          <span class="input-group-append nav-seachBox">
            <div class="input-group-text" (click)="searchUsers('searchTeamMemberName','tblMemberAD');">

              <img src="../../../assets/images/EDP/Filters/search.svg" alt="Search" class="nav-searchIcon" />
            </div>
          </span>
        </div>
        <span class="span-hint mt-1">Hint: Last name, first name, or email.</span>
        <div class="tableScroll mt-2">
          <table id="tblMemberAD" class="table  table-hover" aria-describedby="Members">
            <thead>
              <tr>
                <th scope="col"> Name</th>
                <th scope="col"> Email </th>
                <th scope="col">Job Title</th>
                <th scope="col">Phone</th>
                <th scope="col">Line of Business</th>
                <th scope="col">Service Line</th>
                <th scope="col">Location</th>
              </tr>
            </thead>
            <tbody>
              <tr id="trTeamMembers" name="trTeamMembers"
                *ngFor="let member of membersListArrayForTeamMembers let i=index" (click)='selectTeamMember(i,member)'>
                <td id="TeamMemberList{{i}}">
                  <span id='spMemberdisplayName{{i}}'>{{member.displayName}}</span>
                </td>
                <td id="TeamMemberList{{i}}"> <span id='spMembermail{{i}}'>{{member.mail}}</span>
                </td>
                <td>
                  <span id='spjobtitle{{i}}'>{{member.jobTitle}}</span>
                </td>
                <td id="TeamMemberList{{i}}"> <span id='spMemberbusinessPhones{{i}}'>{{member.businessPhones}}</span>
                </td>
                <td> <span id='splineOfBusiness{{i}}'>{{member.lineOfBusiness}}</span>
                </td>
                <td> <span id='spserviceLine{{i}}'>{{member.serviceLine}}</span>
                </td>
                <td> <span id='splocation{{i}}'>{{member.location}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="SecondaryButton" style="width: 150px; text-align: center;" data-dismiss="modal"
          (click)="deselectTeamMember();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
          </span>
          <span class="d-inline-block align-middle">Cancel</span>
        </button>
        <button type="button" class="PrimaryButton" style="width: 150px; text-align: center;" data-dismiss="modal"
          (click)="setTeamMember();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
          </span>
          <span class="d-inline-block align-middle">OK</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div id="modal-EP" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title">Select engagement PPMD</span>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="deselectEP();">
          <span class="close-text mr-1">Close </span>
          <span class="modal-close-btn">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span id="EPErrorMessage" class="ErrorFont"></span>

        <div class="input-group nav-searchbox-length pb-2">
          <input type="text" id="searchEPName" (keydown.enter)="searchUsers('searchEPName','tblEPAD');"
            class="form-control py-1 rounded-0 nav-seachBox" placeholder="Search for engagement PPMD"
            autocomplete="off">
          <span class="input-group-append nav-seachBox">
            <div class="input-group-text" (click)="searchUsers('searchEPName','tblEPAD');">

              <img src="../../../assets/images/EDP/Filters/search.svg" alt="Search" class="nav-searchIcon" />
            </div>
          </span>
        </div>
        <div *ngIf="isPPMDUserError" class="error-color">
          <span> User is not part of role</span>
        </div>
        <span class="span-hint mt-1">Hint: Last name, first name, or email.</span>
        <div class="tableScroll mt-2">
          <table id="tblEPAD" class="table  table-hover" aria-describedby="EP">
            <thead>
              <tr>
                <th scope="col"> Name</th>
                <th scope="col"> Email </th>
                <th scope="col">Job Title</th>
                <th scope="col">Phone</th>
                <th scope="col">Line of Business</th>
                <th scope="col">Service Line</th>
                <th scope="col">Location</th>
              </tr>
            </thead>
            <tbody>
              <tr (click)='selectEP(i,"EP",$event)' id="trEPs" name="trEPs"
                *ngFor="let member of membersListArrayForEP let i=index">
                <td>
                  <span id='spEPdisplayName{{i}}'>{{member.displayName}}</span>
                </td>
                <td> <span id='spEPmail{{i}}'>{{member.mail}}</span>
                </td>
                <td>
                  <span id='spjobtitle{{i}}'>{{member.jobTitle}}</span>
                </td>
                <td> <span id='spEPbusinessPhones{{i}}'>{{member.businessPhones}}</span>
                </td>
                <td> <span id='splineOfBusiness{{i}}'>{{member.lineOfBusiness}}</span>
                </td>
                <td> <span id='spserviceLine{{i}}'>{{member.serviceLine}}</span>
                </td>
                <td> <span id='splocation{{i}}'>{{member.location}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="SecondaryButton mr-2" data-dismiss="modal"
          style="width: 150px; text-align: center;" (click)="deselectEP();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
          </span>
          <span class="d-inline-block align-middle">Cancel</span>
        </button>
        <button type="button" class="PrimaryButton" style="width: 150px; text-align: center; " (click)="setEP();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
          </span>
          <span class="d-inline-block align-middle">OK</span>
        </button>
      </div>
    </div>
  </div>
</div>
<div id="modal-CMSEP" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title">Select CMS PPMD</span>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="deselectEP();">
          <span class="close-text mr-1">Close </span>
          <span class="modal-close-btn">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span id="EPErrorMessage" class="ErrorFont"></span>

        <div class="input-group nav-searchbox-length pb-2">
          <input type="text" id="searchCMSEPName" (keydown.enter)="searchUsers('searchCMSEPName','tblEPAD');"
            class="form-control py-1 rounded-0 nav-seachBox" placeholder="Search for CMS PPMD" autocomplete="off">
          <span class="input-group-append nav-seachBox">
            <div class="input-group-text" (click)="searchUsers('searchCMSEPName','tblEPAD');">

              <img src="../../../assets/images/EDP/Filters/search.svg" alt="Search" class="nav-searchIcon" />
            </div>
          </span>
        </div>
        <span class="span-hint mt-1">Hint: Last name, first name, or email.</span>
        <div class="tableScroll mt-2">
          <table id="tblEPADCMS" class="table  table-hover" aria-describedby="CMSEP">
            <thead>
              <tr>
                <th scope="col"> Name</th>
                <th scope="col"> Email </th>
                <th scope="col">Job Title</th>
                <th scope="col">Phone</th>
                <th scope="col">Line of Business</th>
                <th scope="col">Service Line</th>
                <th scope="col">Location</th>
              </tr>
            </thead>
            <tbody>
              <tr (click)='selectEP(i,"CMSEP",$event)' id="trCMSEPs" name="trCMSEPs"
                *ngFor="let member of membersListArrayForEP let i=index">
                <td>
                  <span id='spEPdisplayName{{i}}'>{{member.displayName}}</span>
                </td>
                <td> <span id='spEPmail{{i}}'>{{member.mail}}</span>
                </td>
                <td>
                  <span id='spjobtitle{{i}}'>{{member.jobTitle}}</span>
                </td>
                <td> <span id='spEPbusinessPhones{{i}}'>{{member.businessPhones}}</span>
                </td>
                <td> <span id='splineOfBusiness{{i}}'>{{member.lineOfBusiness}}</span>
                </td>
                <td> <span id='spserviceLine{{i}}'>{{member.serviceLine}}</span>
                </td>
                <td> <span id='splocation{{i}}'>{{member.location}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="SecondaryButton mr-2" data-dismiss="modal"
          style="width: 150px; text-align: center;" (click)="deselectEP();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
          </span>
          <span class="d-inline-block align-middle">Cancel</span>
        </button>
        <button type="button" class="PrimaryButton" style="width: 150px; text-align: center; " (click)="setEP();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
          </span>
          <span class="d-inline-block align-middle">OK</span>
        </button>
      </div>
    </div>
  </div>
</div>
<app-contact-popup [contactEmailId]="selectContactEmailId"></app-contact-popup>

<!--Start EQCR POPUP-->
<div id="modal-AssignmentEQCR" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title">Select assignment EQCR </span>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="deselectEQCR();">
          <span class="close-text mr-1">Close </span>
          <span class="modal-close-btn">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span id="EQCRErrorMessage" class="ErrorFont"></span>

        <div class="input-group nav-searchbox-length pb-2">
          <input type="text" id="searchEQCRName" (keydown.enter)="searchUsers('searchEQCRName','tblEQCR');"
            class="form-control py-1 rounded-0 nav-seachBox" placeholder="Search for Assignment EQCR"
            autocomplete="off">
          <span class="input-group-append nav-seachBox">
            <div class="input-group-text" (click)="searchUsers('searchEQCRName','tblEQCR');">

              <img src="../../../assets/images/EDP/Filters/search.svg" alt="Search" class="nav-searchIcon" />
            </div>
          </span>
        </div>
        <span class="span-hint mt-1">Hint: Last name, first name, or email.</span>
        <div class="tableScroll mt-2">
          <table id="tblEQCR" class="table  table-hover" aria-describedby="EQCR">
            <thead>
              <tr>
                <th scope="col"> Name</th>
                <th scope="col"> Email </th>
                <th scope="col">Job Title</th>
                <th scope="col">Phone</th>
                <th scope="col">Line of Business</th>
                <th scope="col">Service Line</th>
                <th scope="col">Location</th>
              </tr>
            </thead>
            <tbody>
              <tr (click)='selectEQCR(i,$event)' id="trEQCR" name="trEQCR"
                *ngFor="let member of membersListArrayForEP let i=index">
                <td>
                  <span id='spEQCRdisplayName{{i}}'>{{member.displayName}}</span>
                </td>
                <td> <span id='spEQCRmail{{i}}'>{{member.mail}}</span>
                </td>
                <td>
                  <span id='spjobtitle{{i}}'>{{member.jobTitle}}</span>
                </td>
                <td> <span id='spEQCRbusinessPhones{{i}}'>{{member.businessPhones}}</span>
                </td>
                <td> <span id='splineOfBusiness{{i}}'>{{member.lineOfBusiness}}</span>
                </td>
                <td> <span id='spserviceLine{{i}}'>{{member.serviceLine}}</span>
                </td>
                <td> <span id='splocation{{i}}'>{{member.location}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="SecondaryButton mr-2" data-dismiss="modal"
          style="width: 150px; text-align: center;" (click)="deselectEQCR();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
          </span>
          <span class="d-inline-block align-middle">Cancel</span>
        </button>
        <button type="button" class="PrimaryButton" style="width: 150px; text-align: center; " (click)="setEQCR();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
          </span>
          <span class="d-inline-block align-middle">OK</span>
        </button>
      </div>
    </div>
  </div>
</div>
<!--End EQCR POPUP-->

<!--Start GT Agreemwnt Owner POPUP-->
<div id="modal-GTAgreementOwner" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title">Select GT agreement owner </span>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
          (click)="deselectGTAgreementOwner();">
          <span class="close-text mr-1">Close </span>
          <span class="modal-close-btn">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span id="GTAgreementOwnerErrorMessage" class="ErrorFont"></span>

        <div class="input-group nav-searchbox-length pb-2">
          <input type="text" id="searchGTAgreementOwnerName"
            (keydown.enter)="searchUsers('searchGTAgreementOwnerName','tblGTAgreementOwner');"
            class="form-control py-1 rounded-0 nav-seachBox" placeholder="Search for GT Agreement Owner"
            autocomplete="off">
          <span class="input-group-append nav-seachBox">
            <div class="input-group-text" (click)="searchUsers('searchGTAgreementOwnerName','tblGTAgreementOwner');">

              <img src="../../../assets/images/EDP/Filters/search.svg" alt="Search" class="nav-searchIcon" />
            </div>
          </span>
        </div>
        <span class="span-hint mt-1">Hint: Last name, first name, or email.</span>
        <div class="tableScroll mt-2">
          <table id="tblGTAgreementOwner" class="table  table-hover" aria-describedby="GTAgreementOwner">
            <thead>
              <tr>
                <th scope="col"> Name</th>
                <th scope="col"> Email </th>
                <th scope="col">Job Title</th>
                <th scope="col">Phone</th>
                <th scope="col">Line of Business</th>
                <th scope="col">Service Line</th>
                <th scope="col">Location</th>
              </tr>
            </thead>
            <tbody>
              <tr (click)='selectGTAgreementOwner(i,$event)' id="trGTAgreementOwner" name="trGTAgreementOwner"
                *ngFor="let member of membersListArrayForEP let i=index">
                <td>
                  <span id='spGTAgreementOwnerdisplayName{{i}}'>{{member.displayName}}</span>
                </td>
                <td> <span id='spGTAgreementOwnermail{{i}}'>{{member.mail}}</span>
                </td>
                <td>
                  <span id='spjobtitle{{i}}'>{{member.jobTitle}}</span>
                </td>
                <td> <span id='spGTAgreementOwnerbusinessPhones{{i}}'>{{member.businessPhones}}</span>
                </td>
                <td> <span id='splineOfBusiness{{i}}'>{{member.lineOfBusiness}}</span>
                </td>
                <td> <span id='spserviceLine{{i}}'>{{member.serviceLine}}</span>
                </td>
                <td> <span id='splocation{{i}}'>{{member.location}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="SecondaryButton mr-2" data-dismiss="modal"
          style="width: 150px; text-align: center;" (click)="deselectGTAgreementOwner();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
          </span>
          <span class="d-inline-block align-middle">Cancel</span>
        </button>
        <button type="button" class="PrimaryButton" style="width: 150px; text-align: center; "
          (click)="setGTAgreementOwner();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
          </span>
          <span class="d-inline-block align-middle">OK</span>
        </button>
      </div>
    </div>
  </div>
</div>
<!--End GT Agreemwnt Owner POPUP-->

<!--Start of Client Opportunity Popup Old-->
<div id="modal-Opportunity" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true"
  data-backdrop="static" data-keyboard="false" *ngIf="!clientSearchFeatureFlagEnabled">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body" style="padding:30px;border-radius: 10px;">
        <div class="row m-0">
          <div class="col-md-11">
            <div class="row pop-up-pageTitle"> Link opportunity and opportunity services from oneView</div>
            <div class="row">
              <span style="font-size: 15px; margin-top: 5px;">{{selectedclientName}} opportunities</span>
            </div>
            <div class="row">
              <span>Primary solution code: <span class="font-weight-bold">{{selectedsolutionCodeWithDesc}}</span>
              </span>
            </div><br>

            <div class="row">
              <span
                *ngIf="EditMode == false && (engagement.opportunityId == '' || engagement.opportunityId == undefined)">
                The following opportunities have been captured in oneView that match your primary solution code.
                Please review the list below and select the correct one to associate. Related solutions will be
                highlighted.
              </span>
              <span
                *ngIf="EditMode == true || (EditMode == false && engagement.opportunityId !== '' && engagement.opportunityId != undefined)">
                The following opportunity services have been captured in oneView that match your primary solution code.
                Please review the list below and select the correct one(s) to associate.
                Related solutions will be highlighted.
              </span>
            </div><br>
          </div>
          <div class="col-md-1 p-0">
            <button type="button" class="close edp-font-14" data-dismiss="modal" aria-hidden="true"
              (click)="CancelOpportunityPopUp();">
              <span class="close-text mr-1">Close </span>
              <span class="modal-close-btn">&times;</span>
            </button>
          </div>
        </div>
        <div class="row m-0 font-weight-bold">
          <span
            *ngIf="EditMode == false && (engagement.opportunityId == '' || engagement.opportunityId == undefined)">Select
            the opportunity to associate with the engagement. You can select related solutions to be added as additional
            engagement assignment(s).</span>
          <span
            *ngIf="EditMode == true || (EditMode == false && engagement.opportunityId !== '' && engagement.opportunityId != undefined)">Select
            the related opportunity service(s) to associate with the engagement primary solution code.</span>
        </div>

        <div class="row m-0 p-0 col-md-12">
          <div class="col-md-2 r-n-res-checkboxNew px-0 ">
            <img src="assets/images/EDP/Workspace/blue_lagend.png" alt="chk_UnrelatedService" width="20" height="20"
              class="img-responsive" style="vertical-align: middle; float: left; margin-right: 5px; margin-top: 4px;">
            <label class="custom-check-no-pointer edp-font-12" for="chk_RelatedService">Related service offering</label>
          </div>

          <!-- <div class="col-md-2 r-n-res-checkbox px-0 ">
            <img src="assets/images/EDP/Workspace/grey1_lagend.png" alt="chk_UnrelatedService" width="20" height="20"
              class="img-responsive" style="vertical-align: middle; float: left; margin-right: 5px; margin-top: 4px;">
            <label class="custom-check-no-pointer edp-font-12" for="chk_UnrelatedService">Unrelated service</label>
          </div> -->

          <div class="col-md-2 r-n-res-checkboxNew px-0 ">
            <img src="assets/images/EDP/Workspace/icon-unrelated-service.svg" alt="chk_UnrelatedService" width="20"
              height="20" class="img-responsive"
              style="vertical-align: middle; float: left; margin-right: 5px; margin-top: 4px;">
            <label class="custom-check-no-pointer edp-font-12" for="chk_UnrelatedService">Ineligible service
              offering</label>
          </div>

          <div class="col-md-2 r-n-res-checkboxNew px-0 ">
            <img src="assets/images/EDP/Workspace/report_problem_warning.svg" alt="Warning" width="20" height="20"
              class="img-responsive" style="vertical-align: middle; float: left; margin-right: 5px; margin-top: 4px;">
            <label class="custom-check-no-pointer edp-font-12" for="chk_CurrentlyAssigned">Linked to another
              assignment</label>
          </div>

          <div class="col-md-2 r-n-res-checkboxNew px-0 ">
            <img _ngcontent-lfg-c58="" alt="add" src="assets/images/EDP/Dashboard/redo-24px-cont.svg" width="20"
              height="20" class="img-responsive"
              style="vertical-align: middle; float: left; margin-right: 5px; margin-top: 4px;">
            <label class="custom-check-no-pointer edp-font-12" for="chk_Continuance">Continuance</label>
          </div>
        </div>

        <div class="tableScroll m-0 row mt-1">
          <table id="tblEPAD" class="table  table-hover" aria-describedby="EP">
            <!-- <thead>
              <tr>
                <th scope="col" style="width: 14%;">Opportunity name</th>
                <th scope="col" style="width: 10%;">Sol. code</th>
                <th scope="col" style="width: 22%;">Description</th>
                <th scope="col" style="width: 12%;">Estimated net fees</th>
                <th scope="col" style="width: 12%;">Key decision maker</th>
                <th scope="col" style="width: 30%;">Pursuit team</th>
              </tr>
            </thead> -->
            <tbody>
              <tr *ngIf="noOpportunityMsg.length > 0 && 
                          this.oneViewClientOpportunityViewModelWrapper.isOpportunityServiceMatchWithPrimaryNSecondary"
                style="text-align: center; background: #F8EEE9;cursor: default;">
                <td colspan="6">
                  <span [ngClass]="{'no-opportunity-msg-div-default': (noOpportunityMsg.length == 0),
                                                            'no-opportunity-msg-div': (noOpportunityMsg.length > 0)}"
                    class="font-weight-bold">
                    {{noOpportunityMsg}}</span>
                </td>
              </tr>
              <tr *ngIf="!this.oneViewClientOpportunityViewModelWrapper.isOpportunityServiceMatchWithPrimaryNSecondary"
                style="text-align: center;">
                <td colspan="6" style="padding-top: 120px">
                  <span class="font-weight-bold">
                    {{noOpportunityMsg}}</span>
                </td>
              </tr>
              <tr id="clientOpportunity" name="clientOpportunity"
                *ngFor="let opportunity of clientOpportunityList let i=index">
                <td colspan="6">
                  <div class="row col-md-12">
                    <img *ngIf="opportunity.isAnnuityOpportunity" _ngcontent-lfg-c58="" alt="Annuity"
                      src="assets/images/EDP/Dashboard/redo-24px-cont.svg" width="20" height="20" class="img-responsive"
                      style="vertical-align: middle; float: left; margin-right: 5px;">
                    <span id='opportunityName{{i}}' style="font-size: 15px;cursor:none;"><strong><span
                          *ngIf="isContinuanceEngagement == false && opportunity.accountName != undefined && opportunity.accountName != ''">{{opportunity.accountName}}
                          -</span> {{opportunity.opportunityName}}</strong></span>
                  </div>

                  <div class="row pb-3 mb-2 col-md-12 pl-2">
                    <span id='totalEstimatedFees{{i}}' class="filter-partition"
                      style="width: 18.8%;word-break:break-all;">
                      Total estimated fees:&nbsp;{{opportunity.opportunityEstimatedvalue}}</span>
                    <span id='expectedStartDate{{i}}' class="pl-2 filter-partition" style="width: 20.4%;">Expected start
                      date:
                      {{datePipe.transform(opportunity.expectedStartDate,'MM/dd/yyyy')}}</span>
                    <span id='keyDicisionMaker{{i}}' class="pl-2 filter-partition" style="width: 28%;">oneView key
                      decision maker:
                      {{opportunity.keyDicisionMaker}}
                      <img alt="add" src="../../../../assets/images/Client/tooltip.svg"
                        style="height: 16px; width: 16px;margin-left:6px;" data-toggle="tooltip"
                        data-custom-class="tooltipIndicators" data-html="true"
                        data-title="The key decision maker is maintained in oneView and does not impact engagement creation." />
                    </span>
                    <span id='pursuitTeam{{i}}' class="pl-2" style="width: 32%;">Pursuit team:
                      {{opportunity.pursuitTeam}}</span>
                  </div>

                  <div class="row pl-4 col-md-12">
                    <table id="tblOpportunity" class="table  table-hover" aria-describedby="opportunity">
                      <thead>
                        <div>
                          <th style="width:10%">Sol. code</th>
                          <th style="width:24%">Description</th>
                          <th style="width:26%">oneView estimated net fees
                            <img alt="add" src="../../../../assets/images/Client/tooltip.svg"
                              style="height: 16px; width: 16px;margin-left:6px;" data-toggle="tooltip"
                              data-custom-class="tooltipIndicators" data-html="true"
                              data-title="This value for estimated net fees from oneView can be updated in the assignment setup in Engage." />
                          </th>
                        </div>
                        <th style="width:12%">&nbsp;</th>
                        <th style="width:12%">&nbsp;</th>
                        <th style="width:30%">&nbsp;</th>
                      </thead>
                      <tbody>
                        <tr id="clientOpportunityService" name="clientOpportunityService"
                          *ngFor="let service of opportunity.opportunityServiceList let j=index">
                          <div id="{{service.opportunityServiceId}}"
                            [ngClass]="{'PartOfSecondary-background': service.isPartOfSecondary, 'service-bold': service.isPrimary}">
                            <td style="width:10%">
                              <label class="custom-check edp-font-12"
                                [ngClass]="{'related-service': (!service.isEnabled && service.isPartOfSecondary), 'unrelated-service':(!service.interchangeableSolutionCodeFlag && !service.isPartOfSecondary),'cursor-default-important':service.isAssigned==true}"
                                for="chkSolutionCode{{i}}_{{j}}" id='solutionCode{{i}}_{{j}}'>{{service.solutionCode}}
                                <input type='checkbox' name='chkSolutionCode{{i}}_{{j}}' id='chkSolutionCode{{i}}_{{j}}'
                                  value='{{service.isChecked}}' [(ngModel)]='service.isChecked'
                                  [disabled]="service.isAssigned==true||(!service.isEnabled)"
                                  (click)='OpportunityServiceClick(opportunity.opportunityId, service.opportunityServiceId, service.isChecked, 0, 0, true)'>
                                <span
                                  *ngIf="!service.isContinuanceCarryFrwdAssignment || !isPageLoadOnInitiatingContinuanceEngagement"
                                  class="checkmark-custom"></span>
                              </label>
                            </td>
                            <td style="width:24%">
                              <img *ngIf="service.isAssigned && !service.isContinuanceCarryFrwdAssignment"
                                src="assets/images/EDP/Workspace/report_problem_warning.svg" alt="Warning" width="20"
                                height="20" class="img-responsive"
                                style="vertical-align: middle; float: left; margin-right: 5px; margin-top: 4px;">
                              <img
                                *ngIf="service.isContinuanceCarryFrwdAssignment && isPageLoadOnInitiatingContinuanceEngagement"
                                src="assets/images/EDP/Dashboard/redo-24px-cont.svg" alt="Continuance" width="20"
                                height="20" class="img-responsive"
                                style="vertical-align: middle; float: left; margin-right: 5px; margin-top: 4px;">
                              <span id='solutionCodeDesc{{i}}_{{j}}'
                                style="vertical-align: sub;">{{service.solutionDescription}}</span>
                            </td>
                            <td class="paddingRight" style="width:12%;text-align: right;">
                              <span id='estimatedNetFees{{i}}_{{j}}'
                                style="vertical-align: sub;">{{(service.isContinuanceCarryFrwdAssignment &&
                                isPageLoadOnInitiatingContinuanceEngagement) ? 'N/A' :
                                service.priceAmtFormattedValue}}</span>
                            </td>
                          </div>
                          <td style="width:12%">&nbsp;</td>
                          <td style="width:12%">&nbsp;</td>
                          <td style="width:30%">&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="continuanceEngagementCount > 0" class="mt-2 py-1 font-weight-bold"
          style="text-align: center; background: #FEF0E9;cursor: default; color: #F26315">
          The selected solution code is currently associated with a continuance engagement.
          Please check your&nbsp;
          <a style="text-decoration: underline; cursor: pointer;" routerLink="{{continuanceDashBoardUrl}}"
            target="_self"> continuance dashboard </a>&nbsp;before proceeding.
        </div>
      </div>
      <div class="modal-footer ml-3 mr-3">
        <div class="row m-0 p-0"
          *ngIf="!isPageLoadOnInitiatingContinuanceEngagement || clientOpportunityList.length == 0">
          <label class="custom-check edp-font-12" for="chkServiceNotlisted">
            <span
              *ngIf="EditMode == false && (engagement.opportunityId == '' || engagement.opportunityId == undefined)">The
              opportunity service is not listed. A new opportunity will be created in
              oneView if no existing opportunity match is selected.</span>
            <span
              *ngIf="EditMode == true || (EditMode == false && engagement.opportunityId !== '' && engagement.opportunityId != undefined)">An
              available opportunity service is not listed.
              A new opportunity service will be created in oneView if no available opportunity service is
              selected.</span>
            <input type='checkbox' name='chkServiceNotlisted' id='chkServiceNotlisted'
              value='{{oneViewClientOpportunityViewModelWrapper.isOpportunityServicelisted}}'
              [(ngModel)]='oneViewClientOpportunityViewModelWrapper.isOpportunityServicelisted'
              (click)='OpportunityServiceNotListed(oneViewClientOpportunityViewModelWrapper.isOpportunityServicelisted);'>
            <span class="checkmark-custom"></span>
          </label>
        </div>
        <div class="row m-0 p-0 label error-color edp-font-12" *ngIf="validateOpportunityService && 
            !oneViewClientOpportunityViewModelWrapper.isOpportunitySelected &&
            !oneViewClientOpportunityViewModelWrapper.isOpportunityServicelisted">
          <p *ngIf="clientOpportunityList.length > 0" style="font-size: 14px;">
            <span >{{opportunityServiceErrorMessage}} <b>{{opportunityServiceListErrorMessage}}</b></span>
            <!-- <span *ngIf="EditMode==false">Please select an opportunity service or check the box "The opportunity service
              is not listed."</span>
            <span *ngIf="EditMode==true">Please select opportunity service or click on check box <b>The opportunity
                service is not listed.</b></span> -->
          </p>
          <p *ngIf="clientOpportunityList.length == 0" style="font-size: 14px;">Click on check box "The opportunity
            service is not listed."</p>
        </div>
        <div class="m-0 p-0 float-right">
          <button type="button" class="SecondaryButton mr-2" data-dismiss="modal" id="btnCancelOpportunity"
            style="width: 150px; text-align: center;" (click)="CancelOpportunityPopUp();">
            <span class="d-inline-block mr-1 align-top">
              <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
            </span>
            <span class="d-inline-block align-middle">Cancel</span>
          </button>
          <button type="button" class="PrimaryButton" style="width: 150px; text-align: center; " id="btnOkOpportunityOV"
            (click)="OkOpportunityPopUp();">
            <span class="d-inline-block mr-1 align-top">
              <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
            </span>
            <span class="d-inline-block align-middle">OK</span>
          </button>
        </div>
      </div>
    </div>
  </div>

</div>

<!--Start oneView client Search Opportunity POPUP-->
<div id="modal-Opportunity" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true"
  data-backdrop="static" data-keyboard="false" *ngIf="clientSearchFeatureFlagEnabled">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body pb-1" style="padding:30px;border-radius: 10px;">
        <div class="row m-0">
          <div class="col-md-11 pl-4">
            <div class="row pop-up-pageTitle"> Select opportunity services</div>
            <div class="row d-flex">
              <div class="col-md-4 pl-0">
                <span style="font-size: 15px; margin-top: 5px;">{{opportunityClientName}} </span>
              </div>
              <div class="col-md-4">
                <span style="font-size: 15px; margin-top: 5px;">{{opportunityName}}</span>
              </div>
              <div class="col-md-4">
                <span style="font-size: 15px; margin-top: 5px;">{{selectedsolutionCodeWithDesc}}</span>
              </div>
            </div>
          </div>
          
          <div class="col-md-1 p-0">
            <button type="button" class="close edp-font-14" data-dismiss="modal" aria-hidden="true"
              (click)="CancelOpportunityPopUp();">
              <span class="close-text mr-1">Close </span>
              <span class="modal-close-btn">&times;</span>
            </button>
          </div>
        </div>
        <div class="row mt-2 m-0 pl-2 font-weight-bold">
          <span
            *ngIf="EditMode == false && (engagement.opportunityId == '' || engagement.opportunityId == undefined)">Select
            the opportunity to associate with the engagement. You can select related solutions to be added as additional
            engagement assignment(s).</span>
          <span
            *ngIf="EditMode == true || (EditMode == false && engagement.opportunityId !== '' && engagement.opportunityId != undefined)">Select
            the opportunity service(s) to associate with this engagement.</span>
        </div>

        <div class="row pl-2 m-0 p-0 col-md-12">
          <div class="col-md-3 r-n-res-checkboxNew px-0 ">
            <img src="assets/images/EDP/Workspace/blue_lagend.png" alt="chk_UnrelatedService" width="20" height="20"
              class="img-responsive" style="vertical-align: middle; float: left; margin-right: 5px; margin-top: 4px;">
            <label class="custom-check-no-pointer edp-font-12" for="chk_RelatedService">Related service offering</label>
          </div>

          <!-- <div class="col-md-2 r-n-res-checkbox px-0 ">
            <img src="assets/images/EDP/Workspace/grey1_lagend.png" alt="chk_UnrelatedService" width="20" height="20"
              class="img-responsive" style="vertical-align: middle; float: left; margin-right: 5px; margin-top: 4px;">
            <label class="custom-check-no-pointer edp-font-12" for="chk_UnrelatedService">Unrelated service</label>
          </div> -->

          <div class="col-md-3 r-n-res-checkboxNew px-0 ">
            <img src="assets/images/EDP/Workspace/icon-unrelated-service.svg" alt="chk_UnrelatedService" width="20"
              height="20" class="img-responsive"
              style="vertical-align: middle; float: left; margin-right: 5px; margin-top: 4px;">
            <label class="custom-check-no-pointer edp-font-12" for="chk_UnrelatedService">Ineligible service
              offering</label>
          </div>

          <div class="col-md-3 r-n-res-checkboxNew px-0 ">
            <img src="assets/images/EDP/Workspace/report_problem_warning.svg" alt="Warning" width="20" height="20"
              class="img-responsive" style="vertical-align: middle; float: left; margin-right: 5px; margin-top: 4px;">
            <label class="custom-check-no-pointer edp-font-12" for="chk_CurrentlyAssigned">Linked to another
              assignment</label>
          </div>

          <div class="col-md-2 r-n-res-checkboxNew px-0 ">
            <img _ngcontent-lfg-c58="" alt="add" src="assets/images/EDP/Dashboard/redo-24px-cont.svg" width="20"
              height="20" class="img-responsive"
              style="vertical-align: middle; float: left; margin-right: 5px; margin-top: 4px;">
            <label class="custom-check-no-pointer edp-font-12" for="chk_Continuance">Continuance</label>
          </div>
        </div>

        <div class="m-0 row mt-1 table-popup">
          <table id="tblEPAD" class="table mb-0" aria-describedby="EP">
            <!-- <thead>
              <tr>
                <th scope="col" style="width: 14%;">Opportunity name</th>
                <th scope="col" style="width: 10%;">Sol. code</th>
                <th scope="col" style="width: 22%;">Description</th>
                <th scope="col" style="width: 12%;">Estimated net fees</th>
                <th scope="col" style="width: 12%;">Key decision maker</th>
                <th scope="col" style="width: 30%;">Pursuit team</th>
              </tr>
            </thead> -->
            <tbody>

              <tr id="clientOpportunity" name="clientOpportunity"
                *ngFor="let opportunity of clientOpportunityList let i=index">
                <td colspan="6">
                  <div class="row col-md-12 table-scroll">
                    <table id="tblOpportunity" class="table mb-0 table-border" aria-describedby="opportunity">
                      <thead>
                        <tr>
                          <th>Solution code</th>
                          <th>Solution description</th>
                          <th>Service amount
                            <img alt="add" src="../../../../assets/images/Client/tooltip.svg" style="height: 16px;"
                              data-toggle="tooltip" data-custom-class="tooltipIndicators" data-html="true"
                              data-title="This value for estimated net fees from oneView can be updated in the assignment setup in Engage." />
                          </th>
                        </tr>

                      </thead>
                      <tbody>
                        <tr id="clientOpportunityService" name="clientOpportunityService"
                          *ngFor="let service of opportunity.opportunityServiceList let j=index">

                          <td id="{{service.opportunityServiceId}}"
                            [ngClass]="{'PartOfSecondary-background': service.isPartOfSecondary, 'service-bold': service.isPrimary}">
                            <label class="custom-check edp-font-12"
                              [ngClass]="{'related-service': (!service.isEnabled && service.isPartOfSecondary), 'unrelated-service':(!service.interchangeableSolutionCodeFlag && !service.isPartOfSecondary),'cursor-default-important':service.isAssigned==true}"
                              for="chkSolutionCode{{i}}_{{j}}" id='solutionCode{{i}}_{{j}}'>{{service.solutionCode}}
                              <input type='checkbox' name='chkSolutionCode{{i}}_{{j}}' id='chkSolutionCode{{i}}_{{j}}'
                                value='{{service.isChecked}}' [(ngModel)]='service.isChecked'
                                [disabled]="service.isAssigned==true||(!service.isEnabled)"
                                (click)='OpportunityServiceClick(opportunity.opportunityId, service.opportunityServiceId, service.isChecked, 0, 0, true)'>
                              <span
                                *ngIf="!service.isContinuanceCarryFrwdAssignment || !isPageLoadOnInitiatingContinuanceEngagement"
                                class="checkmark-custom"></span>
                            </label>
                          </td>
                          <td id="{{service.opportunityServiceId}}"
                            [ngClass]="{'PartOfSecondary-background': service.isPartOfSecondary, 'service-bold': service.isPrimary}">
                            <img *ngIf="service.isAssigned && !service.isContinuanceCarryFrwdAssignment"
                              src="assets/images/EDP/Workspace/report_problem_warning.svg" alt="Warning" width="20"
                              height="20" class="img-responsive"
                              style="vertical-align: middle; float: left; margin-right: 5px; margin-top: 4px;">
                            <img
                              *ngIf="service.isContinuanceCarryFrwdAssignment && isPageLoadOnInitiatingContinuanceEngagement"
                              src="assets/images/EDP/Dashboard/redo-24px-cont.svg" alt="Continuance" width="20"
                              height="20" class="img-responsive"
                              style="vertical-align: middle; float: left; margin-right: 5px; margin-top: 4px;">
                            <span id='solutionCodeDesc{{i}}_{{j}}'
                              style="vertical-align: sub;">{{service.solutionDescription}}</span>
                          </td>
                          <td id="{{service.opportunityServiceId}}"
                            [ngClass]="{'PartOfSecondary-background': service.isPartOfSecondary, 'service-bold': service.isPrimary}">
                            <span id='estimatedNetFees{{i}}_{{j}}'
                              style="vertical-align: sub;">{{(service.isContinuanceCarryFrwdAssignment &&
                              isPageLoadOnInitiatingContinuanceEngagement) ? 'N/A' :
                              service.priceAmtFormattedValue}}</span>
                          </td>


                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer pl-0  pr-5 d-block ">
        <div *ngIf="isAddAssignmentClicked" style="padding-left: 2.2rem;">
          <div class="row m-0 p-0">
          <!-- <div class="row m-0 p-0"
            *ngIf="!isPageLoadOnInitiatingContinuanceEngagement || clientOpportunityList.length == 0"> -->
            <label class="custom-check edp-font-12" for="chkServiceNotlisted">
              <span
                *ngIf="EditMode == false && (engagement.opportunityId == '' || engagement.opportunityId == undefined)">The
                opportunity service is not listed. A new opportunity will be created in
                oneView if no existing opportunity match is selected.</span>
              <span
                *ngIf="EditMode == true || (EditMode == false && engagement.opportunityId !== '' && engagement.opportunityId != undefined)">An
                available opportunity service is not listed.
                A new opportunity service will be created in oneView if no available opportunity service is
                selected.</span>
              <input type='checkbox' name='chkServiceNotlisted' id='chkServiceNotlisted'
                value='{{oneViewClientOpportunityViewModelWrapper.isOpportunityServicelisted}}'
                [(ngModel)]='oneViewClientOpportunityViewModelWrapper.isOpportunityServicelisted'
                (click)='OpportunityServiceNotListed(oneViewClientOpportunityViewModelWrapper.isOpportunityServicelisted);'>
              <span class="checkmark-custom"></span>
            </label>
          </div>
        </div>

        <div class="row m-0 p-0 label error-color edp-font-12" *ngIf="validateOpportunityService && 
            !oneViewClientOpportunityViewModelWrapper.isOpportunitySelected &&
            !oneViewClientOpportunityViewModelWrapper.isOpportunityServicelisted && isAddAssignmentClicked">
          <p *ngIf="clientOpportunityList.length > 0" style="font-size: 14px; padding-left:4.3rem !important ;">
            <!-- <span *ngIf="EditMode==false">{{opportunityServiceErrorMessage}} {{opportunityServiceListErrorMessage}}</span> -->
            <span >{{opportunityServiceErrorMessage}} <b>{{opportunityServiceListErrorMessage}}</b></span>
          </p>
          <p *ngIf="clientOpportunityList.length == 0" style="font-size: 14px;">Click on check box "The opportunity
            service is not listed."</p>
        </div>
        <div class="row m-0 p-0 label error-color edp-font-12"
          *ngIf="oneViewClientOpportunityViewModelWrapper.isOpportunitySelected "
          style="font-size: 14px;padding-left: 2.6rem !important;">
          <span>{{opportunityServiceLinkErrorMessage}}</span>
        </div><br />
        <div class="m-0 p-0 float-right">
          <button type="button" class="SecondaryButton mr-2" data-dismiss="modal" id="btnCancelOpportunityNew"
            style="width: 150px; text-align: center;" (click)="CancelOpportunityPopUp();">
            <span class="d-inline-block mr-1 align-top">
              <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
            </span>
            <span class="d-inline-block align-middle">Cancel</span>
          </button>
          <button type="button" class="PrimaryButton" style="width: 150px; text-align: center; "
            id="btnOkOpportunityNew" (click)="OkOpportunityPopUp();">
            <span class="d-inline-block mr-1 align-top">
              <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
            </span>
            <span class="d-inline-block align-middle">OK</span>
          </button>
        </div>
      </div>
    </div>
  </div>

</div>
<!--Start client Opportunity POPUP-->