import { Component, OnInit } from '@angular/core';
import { listviewService } from 'src/app/services/listviewService';
import { listviewModel, ClientUniqueDataModel, ClientStatusModal, ClientIndustryTypeModal, ClientStructEntityModal, ClientProgramModal } from 'src/app/model/listviewModel';
import 'src/assets/Style/excel-bootstrap-table-filter-bundle.js';
import 'src/assets/js/jquery.flyout.js';
import 'src/assets/js/bootstrap-table.min.js'
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { NavbarService } from 'src/app/services/navbarServices';
import { Alert } from 'src/app/model/Alert';
import { AlertsService } from 'src/app/services/alerts.service';
import { AppConfigService } from 'src/app/services/appConfigService';
import { PagerService } from 'src/app/services/pager.service';
import { ImageModel, HeaderModel } from 'src/app/model/EngagementListModel';
import { ClientCardViewFilterModel, ClientListViewFilterModel } from 'src/app/model/client-filter-model';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { UserPreferenceData } from 'src/app/model/UserPreferenceModel';
import { EngagementService } from 'src/app/services/EngagementService';
import { KeyValue } from 'src/app/model/KeyValue';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { SharedService } from 'src/app/services/shared.service';

declare var $;
declare function applyListView(fnCallBack);
declare function ShowAllClients();
declare function ShowAllServiceLine();
declare function ShowAllClientPrograms();
declare function ShowAllCompanies();

declare function CheckUncheckAll(CheckAllClients, CheckClient);

@Component({
  selector: 'app-listview',
  templateUrl: './listview.component.html',
  styleUrls: ['./listview.component.css'],
  providers: [listviewService]
})

export class ListviewComponent implements OnInit {


  selectedTabName: string;
  applyFilterOnceAfterDataLoad: boolean;
  page: number[] = [];
  IwantToHeading: string;
  lastIwantToHeading: string;
  myClientslistViewList: listviewModel[] = [];
  myClientsCardList: listviewModel[] = [];
  listViewnoOfrecordsPerPage: number = 20;
  cardViewnoOfrecordsPerPage: number = 20;
  listViewCurrentPageNumber: number = 1;
  cardViewCurrentPageNumber: number = 1;
  noOfrecordsPerPageList: number[];
  isloggedInUserAdmin: string;
  orderByColumn: string;
  orderByType: string;
  cardVieworderByColumn: string;
  cardVieworderByType: string;
  selectedClientId: number;
  selectedKRPClientId: number;
  selectedEngagementClientId: number;
  isRedirectedFromUrl: string;
  lastRedirectedFromCreateClient: string;
  clientId: string;
  userObject: any;
  popupRecordCount: number;
  alertClient: Alert;
  iShortcutCount: number;
  clientCardCount: number = 0;
  clientsCount: number = 0;
  listCount: number = null;
  cardCount: number = null;
  DataMessage: string;
  ClientCodeCardMappedValue: string;
  ClientCodeListMappedValue: string;
  listDataMessage: string;
  newImageModel: ImageModel = new ImageModel();
  headerModel: HeaderModel = new HeaderModel();
  headerImageModelList: ImageModel[] = [];
  headerModelList: HeaderModel[] = [];
  clientUniqueDataList: ClientUniqueDataModel;
  clientIndustryTypeList: ClientIndustryTypeModal[] = [];
  clientStructEntityList: ClientStructEntityModal[] = [];
  clientProgramList: ClientProgramModal[] = [];
  clientStatusList: ClientStatusModal[] = [];

  clientsId: number = 0;
  clientIndustryId: number = 0;
  structEntityId: number = 0;
  clientProgramId: number = 0;
  clientStatusId: number = 0;

  currentClientId: number = 0;
  currentClientIndustryId: number = 0;
  currentStructEntityId: number = 0;
  currentClientProgramId: number = 0;
  currentClientStatusId: number = 0;
  listViewCount: number = 0;

  currentListClientId: number = 0;
  currentListClientIndustryId: number = 0;
  currentListStructEntityId: number = 0;
  currentListClientProgramId: number = 0;
  currentListClientStatusId: number = 0;

  currentSortClientId: number = 0;
  currentSortClientIndustryId: number = 0;
  currentSortStructEntityId: number = 0;
  currentSortClientProgramId: number = 0;
  currentSortClientStatusId: number = 0;
  currentSortLastActivityDateId: number = 0;
  sortOrderByType: string = 'DESC';

  clientCardViewFilterModel: ClientCardViewFilterModel;
  clientListViewFilterModel: ClientListViewFilterModel;

  isListFlag: boolean = false;
  userPreferenceData:UserPreferenceData[];
  clientUserPreference:UserPreferenceData = null
  dashBoardViewtypeName:string='Card';
  clientSource : string;
  keyValueList : KeyValue[];
  clientFilterModel : KeyValue[];
  isCardViewApplyResetButtonClicked : boolean = false;
  isListViewApplyResetButtonClicked : boolean = false;
  isCardViewSortApplyResetButtonClicked : boolean = false;

  clientNameSource: string = "clientName";
  industrySource: string = "industry";
  clientStatusSource: string = "clientStatus";
  clientProgramSource: string = "clientProgram";
  structEntitySource: string = "structEntity";
  clientIds =[];
  industryIds =[];
  clientStatusIds =[];
  clientProgramIds= [];
  structEntityIds= [];
  optionLabelClientName: string  = "key";
  optionValueClientName: string  = "value";
  optionLabelIndustryName: string  = "industryTypeId";
  optionValueIndustryName: string  = "industryTypeName";
  optionLabelClientStatus: string  = "clientStatusId";
  optionValueClientStatus: string  = "clientStatusName";
  optionLabelProgramList: string  = "clientProgramId";
  optionValueProgramList: string  = "clientProgramName";
  optionLabelStructEntity: string  = "structEntityId";
  optionValueStructEntity: string  = "structEntityName";
  optionHeaderClientName = "Select client";
  optionHeaderIndustryName = "Select industry";
  optionHeaderClientStatusName = "Select client status";
  optionHeaderClientProgramName = "Select client program";
  optionHeaderStructName = "Select struct/entity";

  constructor(private _configService: ConfigurationService,private engService: EngagementService,private _myclientslist: listviewService, private route: Router,
    private loadingService: LoadingScreenService, private dataService: DataService,
    private navbarService: NavbarService,
    private enviornment: AppConfigService, 
    private _alertService: AlertsService, 
    private pagerService: PagerService,
    private sharedService: SharedService) {
    this.popupRecordCount = enviornment.config.DATA.SHOW_CLOSE_BUTTON_RECORD_LIMIT;
    this._alertService.remove(this.alertClient);
    const IShortcutCount = localStorage.getItem('iShortcutCount');
    this.iShortcutCount = JSON.parse(IShortcutCount);
    const jsonString = localStorage.getItem('currentUser');
    this.DataMessage = 'Loading...';
    this.listDataMessage = 'Loading...';
    this.userObject = JSON.parse(jsonString);
    var userPreferenceString = localStorage.getItem('userpreferencelist');
    this.userPreferenceData = JSON.parse(userPreferenceString);
//     console.log(this.userPreferenceData);
    if(this.userPreferenceData.length > 0){
    this.userPreferenceData.forEach(value => {
      if(value.dashBoardName === "MyClients"){
        this.clientUserPreference = value;
      }
    });
  }
    this.applyFilterOnceAfterDataLoad = false;
    this.ClientCodeCardMappedValue = 'ClientCodeCardMappedValue';
    this.ClientCodeListMappedValue = 'ClientCodeListMappedValue';
  }
  componentRef: any;

  fnCallBack: any;
  // -- Pager service -- */
  // array of all items to be paged
  private allItems: any[];
  // pager object
  pager: any = {};
  cardViewPager: any = {};
  // paged items
  pagedItems: any[];
  // -- Pager service End-- */

  ngOnInit() {
    this.clientCardViewFilterModel = new ClientCardViewFilterModel([], [], [], [], [], false, false, false, true, this.userObject.userName);
    this.clientListViewFilterModel = new ClientListViewFilterModel([], [], [], [], [], false, false, false, true, this.userObject.userName);
    if(this.userPreferenceData.length > 0 && this.clientUserPreference != null){
      this.clientCardViewFilterModel = JSON.parse(this.clientUserPreference.cardViewFilterList);
      this.sortOrderByType = JSON.parse(this.clientUserPreference.cardViewSortingList).orderByType;
      if(this.sortOrderByType == undefined || this.sortOrderByType == null){
        this.sortOrderByType = 'DESC';
      }
      this.clientListViewFilterModel = JSON.parse(this.clientUserPreference.listViewFilterList);
      this.orderByColumn =  JSON.parse(this.clientUserPreference.listViewSortingList).orderByColumn;
      this.cardViewnoOfrecordsPerPage = this.clientUserPreference.cardViewPageNumber;
      if(this.cardViewnoOfrecordsPerPage == undefined || this.cardViewnoOfrecordsPerPage == null){
        this.cardViewnoOfrecordsPerPage = 20;
      }
      this.listViewnoOfrecordsPerPage = this.clientUserPreference.listViewPageNumber;
      if(this.listViewnoOfrecordsPerPage == undefined || this.listViewnoOfrecordsPerPage == null){
        this.listViewnoOfrecordsPerPage = 20;
      }
      this.orderByType = JSON.parse(this.clientUserPreference.listViewSortingList).orderByType;
      this.dashBoardViewtypeName = this.clientUserPreference.dashboardViewType;
      if(this.dashBoardViewtypeName == undefined || this.dashBoardViewtypeName == null){
        this.dashBoardViewtypeName = 'Card';
      }
      
      }
      
    this.setPageRecordRange();
    this.setDefaultInitialization();
    this.LoadListViewHeaders();
    this.IsLoggedinUserSystemAdmin();
    if(this.dashBoardViewtypeName === 'Card'){
      this.SetUserPreferenceCardDropDownListValues();
    }
    if(this.dashBoardViewtypeName === 'List'){
      this.SetUserPreferenceListDropDownListValues();
    }
    this.GetClientFilterSectionUniqueData();
        this.GetClientsUniqueDataForFilters();
  }

  SetUserPreferenceCardDropDownListValues(){
    $('.Eng-CardView-Div').show();
    $('.Eng-ListView-Div').hide();
    if(this.clientCardViewFilterModel.clientId.length > 0 || this.clientCardViewFilterModel.clientStatusId.length > 0 ||
       this.clientCardViewFilterModel.industryTypeId.length > 0 || this.clientCardViewFilterModel.programId.length > 0 || this.clientCardViewFilterModel.structEntityId.length > 0){
        $("#filterCardView").addClass("partialActive");
       }
    if(this.clientCardViewFilterModel.sortClientId || this.clientCardViewFilterModel.sortClientStatusId ||
        this.clientCardViewFilterModel.sortLastActivityDate || this.clientCardViewFilterModel.sortProgramId){
          $("#sortCardView").addClass("partialActive");
        }
    this.getMyAllClientsByUserCount('OnInit');
    
  }

  SetUserPreferenceListDropDownListValues(){
    $('.Eng-CardView-Div').hide();
    $('.Eng-ListView-Div').show();
    this.getMyAllClientsListByUserCount();
    if(this.clientListViewFilterModel.clientId.length > 0 || this.clientListViewFilterModel.clientStatusId.length > 0 ||
      this.clientListViewFilterModel.industryTypeId.length > 0 || this.clientListViewFilterModel.programId.length > 0 || this.clientListViewFilterModel.structEntityId.length > 0){
        $("#filterListView").addClass("partialActive");
      }

    
  }

  GetAllClientsByFilters() {   
    this.assignFilterValuestoModal();
    this.isCardViewApplyResetButtonClicked = true;
    this.InsertUpdateUserPreferenceData();
    this.cardViewCurrentPageNumber = 1;
    $("#filterCardView").removeClass("active");
    if(this.clientCardViewFilterModel.clientId.length > 0 || this.clientCardViewFilterModel.clientStatusId.length > 0 ||
      this.clientCardViewFilterModel.industryTypeId.length > 0 || this.clientCardViewFilterModel.programId.length > 0 || this.clientCardViewFilterModel.structEntityId.length > 0){
       $("#filterCardView").addClass("partialActive");
      }
    if(!(this.clientCardViewFilterModel.clientId.length > 0 || this.clientCardViewFilterModel.clientStatusId.length > 0 ||
        this.clientCardViewFilterModel.industryTypeId.length > 0 || this.clientCardViewFilterModel.programId.length > 0 || this.clientCardViewFilterModel.structEntityId.length > 0)){
         $("#filterCardView").removeClass("partialActive");
        }
    this.getMyAllClientsByUserCount('ApplyClick');
    $('#cardViewFilterSection').hide();
  }

  GetAllClientsListByFilters() {  
    this.assignFilterValuestoModal();
    this.isListViewApplyResetButtonClicked = true;
    this.InsertUpdateUserPreferenceData();
    this.listViewCurrentPageNumber = 1;
    $("#filterListView").removeClass("active");
    if(this.clientListViewFilterModel.clientId.length > 0 || this.clientListViewFilterModel.clientStatusId.length > 0 ||
      this.clientListViewFilterModel.industryTypeId.length > 0 || this.clientListViewFilterModel.programId.length > 0 || this.clientListViewFilterModel.structEntityId.length > 0){
        $("#filterListView").addClass("partialActive");
      }
      if(!(this.clientListViewFilterModel.clientId.length > 0 || this.clientListViewFilterModel.clientStatusId.length > 0 ||
        this.clientListViewFilterModel.industryTypeId.length > 0 || this.clientListViewFilterModel.programId.length > 0 || this.clientListViewFilterModel.structEntityId.length > 0)){
          $("#filterListView").removeClass("partialActive");
        }
    this.getMyAllClientsListByUserCount();
    $('#listViewFilterSection').hide();
  }
  
  GetAllSortedClientsByFilters() {
    this.isCardViewSortApplyResetButtonClicked = true;
    this.sortOrderByType = $('#ddListOrderByType').val();
    this.cardViewCurrentPageNumber = 1;
    this.InsertUpdateUserPreferenceData();
    this.getMyAllClientsByUserCount('ApplyClick');
    $("#sortCardView").removeClass("active");
    if(this.clientCardViewFilterModel.sortClientId || this.clientCardViewFilterModel.sortClientStatusId ||
      this.clientCardViewFilterModel.sortLastActivityDate || this.clientCardViewFilterModel.sortProgramId){
        $("#sortCardView").addClass("partialActive");
      }
    $('#cardViewSortSection').hide();
  }

  GetClientFilterSectionUniqueData() {
    this._myclientslist.GetAllClientsUniqueDataByUser(this.isloggedInUserAdmin).subscribe(
      result => {
        this.clientUniqueDataList = result;
        this.clientIndustryTypeList = this.clientUniqueDataList.clientIndustryTypeList;
        this.clientIndustryTypeList= this.addExtaValueAll("industry",this.clientIndustryTypeList);
        this.clientStructEntityList = this.clientUniqueDataList.clientStructEntityList;
        this.clientStructEntityList= this.addExtaValueAll("structEntity",this.clientStructEntityList);
        this.clientProgramList = this.clientUniqueDataList.clientProgramList;
        this.clientProgramList= this.addExtaValueAll("clientProgram",this.clientProgramList);
        this.clientStatusList = this.clientUniqueDataList.clientStatusList;
        this.clientStatusList= this.addExtaValueAll("clientStatus",this.clientStatusList);
      },
      error => {
      }
    );
  }
  GetClientsUniqueDataForFilters() {
    this.clientSource = 'ClientsForClient' + '_' + this.userObject.userName + '_' + this.isloggedInUserAdmin;
        this._configService.GetListBySource(this.clientSource).subscribe(result => {
        this.keyValueList = result;
        this.clientFilterModel = this.keyValueList;    
        var keyValueList = new KeyValue();
        keyValueList.key = '-1';
        keyValueList.value = 'All';
        if (this.clientFilterModel != null && this.clientFilterModel.length > 0){
          this.clientFilterModel.unshift(keyValueList);
        }    
      },
        err => {
          console.log('Error ', err);
        });
  }

  ShowHideFilterPanel(SelectedView: string) {
    switch (SelectedView) {
      case 'Show':
        this.isCardViewApplyResetButtonClicked = false;
        $('#cardViewFilterSection').show();
        $('#cardViewSortSection').hide();
        $("#filterCardView").addClass("active");
        $("#sortCardView").removeClass("active");
        $("#showAllCardView").removeClass("active");
        $("#filterCardView").removeClass("partialActive");
        if(this.clientCardViewFilterModel.sortClientId || this.clientCardViewFilterModel.sortClientStatusId ||
          this.clientCardViewFilterModel.sortLastActivityDate || this.clientCardViewFilterModel.sortProgramId){
            $("#sortCardView").addClass("partialActive");
          }
        break;
      case 'Hide':
        this.isCardViewApplyResetButtonClicked = false;
        $('#cardViewFilterSection').hide();
        $("#filterCardView").removeClass("active");
        $("#sortCardView").removeClass("active");
        $("#showAllCardView").removeClass("active");
        if(this.clientCardViewFilterModel.clientId.length > 0 || this.clientCardViewFilterModel.clientStatusId.length > 0 ||
          this.clientCardViewFilterModel.industryTypeId.length > 0 || this.clientCardViewFilterModel.programId.length > 0 || this.clientCardViewFilterModel.structEntityId.length > 0){
           $("#filterCardView").addClass("partialActive");
          }

        break;
      case 'HideWithCancel':
        this.ClearCardViewFilterFields();
        $('#cardViewFilterSection').hide();
        this.getMyAllClientsByUserCount('OnInit');
        $("#filterCardView").removeClass("active");
        $("#sortCardView").removeClass("active");
        $("#showAllCardView").removeClass("active");
        break;
      case 'Reset':
        this.resetFilterValuesCheckBox();
        this.sharedService.subject.next('reset');
        this.isCardViewApplyResetButtonClicked = true;
          this.ResetCardViewFilterFields();
          if(!(this.clientCardViewFilterModel.clientId.length > 0 || this.clientCardViewFilterModel.clientStatusId.length > 0 ||
            this.clientCardViewFilterModel.industryTypeId.length > 0 || this.clientCardViewFilterModel.programId.length > 0 || this.clientCardViewFilterModel.structEntityId.length > 0)){
             $("#filterCardView").removeClass("partialActive");
            }
          this.InsertUpdateUserPreferenceData();       
          this.getMyAllClientsByUserCount('OnInit');          
          break;
      default:
        $('#cardViewFilterSection').hide();
        $("#filterCardView").removeClass("active");
        $("#sortCardView").removeClass("active");
        $("#showAllCardView").removeClass("active");
        break;
    }
  }

  ClearCardViewFilterFields() {
    this.clientCardViewFilterModel = new ClientCardViewFilterModel([], [], [], [], [], false, false, false, true, this.userObject.userName);

    $('#txtValueClientCodeCardMappedValue0').val("");
    $('#ddClientsForCardClientsList').val(0);
    $('input:hidden').val('');
    $('#ddClientIndustryTypeCardList').val(0);
    $('#ddClientStructEntityCardList').val(0);
    $('#ddClientProgramCardList').val(0);
    $('#ddClientStatusCardList').val(0);
    $('#cardViewFilterSection').hide();
  }
  ClearListViewFilterFields() {
    this.clientListViewFilterModel = new ClientListViewFilterModel([], [], [], [], [], false, false, false, true, this.userObject.userName);
    $('#txtValueClientCodeListMappedValue0').val("");
    $('#ddClientsForClientsList').val(0);
    $('input:hidden').val('');
    $('#ddClientIndustryTypeList').val(0);
    $('#ddClientStructEntityList').val(0);
    $('#ddClientProgramList').val(0);
    $('#ddClientStatusList').val(0);
    $('#listViewFilterSection').hide();
  }
  ClearCardViewSortFields() {
    this.clientCardViewFilterModel = new ClientCardViewFilterModel([], [], [], [], [], false, false, false, true, this.userObject.userName);

    $('#chkClientName').prop('checked', false);
    $('#chkClientProgram').prop('checked', false);
    $('#chkClientStatus').prop('checked', false);
    $('#chkLastActivityDate').prop('checked', true);
    $('#ddListOrderByType').val('DESC');
    $('#cardViewSortSection').hide();
  }

  ShowHideListFilterPanel(SelectedView: string) {
    switch (SelectedView) {
      case 'Show':
        this.isListViewApplyResetButtonClicked = false;
        $('#listViewFilterSection').show();
        $("#filterListView").addClass("active");
        $("#showAllListView").removeClass("active");
        break;
      case 'Hide':
        this.isListViewApplyResetButtonClicked = false;
        $('#listViewFilterSection').hide();
        $("#filterListView").removeClass("active");
        $("#showAllListView").removeClass("active");
        break;
      case 'HideWithCancel':
        $('#listViewFilterSection').hide();
        this.ClearListViewFilterFields();
        this.getMyAllClientsListByUserCount();
        $("#filterListView").removeClass("active");
        $("#showAllListView").removeClass("active");
        break;
      case 'Reset':
        this.resetFilterValuesCheckBox();
        this.sharedService.subject.next('reset');
        this.isListViewApplyResetButtonClicked = true;
        this.ResetListViewFilterFields();
        if(!(this.clientListViewFilterModel.clientId.length > 0 || this.clientListViewFilterModel.clientStatusId.length > 0 ||
          this.clientListViewFilterModel.industryTypeId.length > 0 || this.clientListViewFilterModel.programId.length > 0 ||
           this.clientListViewFilterModel.structEntityId.length > 0)){
            $("#filterListView").removeClass("partialActive");
          }
        this.InsertUpdateUserPreferenceData();
        this.getMyAllClientsListByUserCount();       
        break;
      default:
        $('#listViewFilterSection').hide();
        $("#filterListView").removeClass("active");
        $("#showAllListView").removeClass("active");
        break;
    }
  }

  ShowHideFilterSortPanel(SelectedView: string) {
    switch (SelectedView) {
      case 'Show':
        this.isCardViewSortApplyResetButtonClicked = false;
        $('#cardViewSortSection').show();
        $('#cardViewFilterSection').hide();
        $("#filterCardView").removeClass("active");
        $("#sortCardView").addClass("active");
        $("#showAllCardView").removeClass("active");
        $("#sortCardView").removeClass("partialActive");
        if(this.clientCardViewFilterModel.clientId.length > 0 || this.clientCardViewFilterModel.clientStatusId.length > 0 ||
          this.clientCardViewFilterModel.industryTypeId.length > 0 || this.clientCardViewFilterModel.programId.length > 0 || this.clientCardViewFilterModel.structEntityId.length > 0){
           $("#filterCardView").addClass("partialActive");
          }
        break;
      case 'Hide':
        this.isCardViewSortApplyResetButtonClicked = false;
        $('#cardViewSortSection').hide();
        $("#filterCardView").removeClass("active");
        $("#sortCardView").removeClass("active");
        $("#showAllCardView").removeClass("active");
        if(this.clientCardViewFilterModel.sortClientId || this.clientCardViewFilterModel.sortClientStatusId ||
          this.clientCardViewFilterModel.sortLastActivityDate || this.clientCardViewFilterModel.sortProgramId){
            $("#sortCardView").addClass("partialActive");
          }
        break;
      case 'HideWithCancel':
        $('#cardViewSortSection').hide();
        $('#ddListOrderByType').val('DESC');
        this.orderByColumn = 'LastActivityDate';
        this.sortOrderByType = 'DESC';
        this.cardVieworderByColumn = 'LastActivityDate';
        this.cardVieworderByType = 'DESC';
        this.ClearCardViewSortFields();
        this.getMyAllClientsByUserCount('OnInit');
        $("#filterCardView").removeClass("active");
        $("#sortCardView").removeClass("active");
        $("#showAllCardView").removeClass("active");
        break;
      case 'Reset':
        this.resetFilterValuesCheckBox();
        this.sharedService.subject.next('reset');
        this.isCardViewSortApplyResetButtonClicked = true;
          this.ResetDefaultInitialization();
          this.ResetCardViewSortFields(); 
          if(!(this.clientCardViewFilterModel.sortClientId || this.clientCardViewFilterModel.sortClientStatusId ||
            this.clientCardViewFilterModel.sortLastActivityDate || this.clientCardViewFilterModel.sortProgramId)){
              $("#sortCardView").removeClass("partialActive");
            }
          this.InsertUpdateUserPreferenceData();   
          this.getMyAllClientsByUserCount('OnInit');          
          break; 
      default:
        $('#cardViewSortSection').hide();
        break;
    }
  }

  ShowAllClientsWithNoFilters(paramView: string) {
    this.resetFilterValuesCheckBox();
    this.sharedService.subject.next('reset');
    this.sortOrderByType = 'DESC';
    this.cardViewCurrentPageNumber = 1;
    if (paramView == "CardView") {
      this.cardVieworderByColumn = 'LastActivityDate';
      this.ClearCardViewFilterFields();
      this.ClearCardViewSortFields();
      this.InsertUpdateUserPreferenceData();
      this.getMyAllClientsByUserCount('ShowAll');
      $("#filterCardView").removeClass("active");
      $("#sortCardView").removeClass("active");
      $("#showAllCardView").addClass("active");
      if(!(this.clientCardViewFilterModel.clientId.length > 0 || this.clientCardViewFilterModel.clientStatusId.length > 0 ||
        this.clientCardViewFilterModel.industryTypeId.length > 0 || this.clientCardViewFilterModel.programId.length > 0 || this.clientCardViewFilterModel.structEntityId.length > 0)){
         $("#filterCardView").removeClass("partialActive");
        }

        if(this.clientCardViewFilterModel.sortClientId || this.clientCardViewFilterModel.sortClientStatusId ||
          this.clientCardViewFilterModel.sortLastActivityDate || this.clientCardViewFilterModel.sortProgramId){
            $("#sortCardView").addClass("partialActive");
          }
    }
    if (paramView == "ListView") {
      this.setDefaultInitializationForShowAll();
      this.ClearListViewFilterFields();
      this.LoadListViewHeaders(); 
      this.InsertUpdateUserPreferenceData();
      this.getMyAllClientsListByUserCount();
      $("#showAllListView").addClass("active");
      $("#filterListView").removeClass("active");
      if(!(this.clientListViewFilterModel.clientId.length > 0 || this.clientListViewFilterModel.clientStatusId.length > 0 ||
        this.clientListViewFilterModel.industryTypeId.length > 0 || this.clientListViewFilterModel.programId.length > 0 ||
         this.clientListViewFilterModel.structEntityId.length > 0)){
          $("#filterListView").removeClass("partialActive");
        }
    }

  }

  IsLoggedinUserSystemAdmin() {
    //console.log('userObject ==>', this.userObject);
    if (this.userObject != null && this.userObject.role != null && this.userObject.role.length > 0) {
      for (let i = 0; i < this.userObject.role.length; i++) {
        if (this.userObject.role[i].shortRoleName === 'SA') {
          this.isloggedInUserAdmin = 'yes';
          break;
        }
      }
    }
  }
  setPageRecordRange() {
    this.noOfrecordsPerPageList = [];
    this.noOfrecordsPerPageList.push(10);
    this.noOfrecordsPerPageList.push(20);
    this.noOfrecordsPerPageList.push(40);
    this.noOfrecordsPerPageList.push(60);
    this.noOfrecordsPerPageList.push(80);
    this.noOfrecordsPerPageList.push(100);
  }
  getMyAllClientsByUserCount(isOnInitFlag: string) {
    this.loadingService.startLoading();
    this._myclientslist.getMyClientsCardByUserCount(this.isloggedInUserAdmin, this.clientCardViewFilterModel)
      .subscribe(
        result => {
          this.clientCardCount = result;
          if (isOnInitFlag == 'OnInit') {
            this.listCount = result;
          }
          if (this.clientCardCount > 0) {
            // initialize to page 1
            this.cardViewSetPage(this.cardViewCurrentPageNumber, this.cardViewnoOfrecordsPerPage);
          }
          else {
            this.cardCount = 0;
            this.myClientsCardList = null;
            if (this.cardCount === 0) {
              this.DataMessage = ' No Data Available';
              this.loadingService.stopLoading();
            }
          }
        },
        error => {
          this.loadingService.stopLoading();
        }
      );
  }

  getMyAllClientsListByUserCount() {
    this.loadingService.startLoading();
    this._myclientslist.getMyClientsListByUserCount(this.isloggedInUserAdmin, this.clientListViewFilterModel)
      .subscribe(
        result => {
          this.clientsCount = result;
          if (this.clientsCount > 0) {
            this.isListFlag = true;
            this.listViewSetPage(this.listViewCurrentPageNumber, this.listViewnoOfrecordsPerPage);
          }
          else {
            this.isListFlag = false;
            this.listCount = 0;
            this.myClientslistViewList = null;
            if (this.listCount === 0) {
              this.listDataMessage = ' No Data Available';
              this.loadingService.stopLoading();
            }
          }
        },
        error => {
          this.loadingService.stopLoading();
        }
      );
  }

  cardViewSetPage(pageNumber: number, recordsPerPage: number) {
    // get CardViewpager object from service
    this.cardViewCurrentPageNumber = pageNumber;
    const inputDrpValue = $('#cardViewdrprecordsPerPage option:selected').text();
    if (inputDrpValue === '') {
      this.cardViewnoOfrecordsPerPage = recordsPerPage;
    } else {
      this.cardViewnoOfrecordsPerPage = Number(inputDrpValue);
    }
    this.InsertUpdateUserPreferenceData();

    if (this.cardViewCurrentPageNumber != 0) {
      if (this.sortOrderByType != null && this.sortOrderByType != "") {
        this.cardVieworderByType = this.sortOrderByType;
      }
      this.getMyClientsCardView(this.clientCardViewFilterModel, this.cardViewCurrentPageNumber,
        this.cardViewnoOfrecordsPerPage,
        this.cardVieworderByColumn, this.cardVieworderByType);
      this.cardViewPager = this.pagerService.getPager(this.clientCardCount, this.cardViewCurrentPageNumber, this.cardViewnoOfrecordsPerPage);
      console.log('cardViewPager ==>', this.cardViewPager);
    } else {
      $('#cardViewPrevious').off('click');
      $('#cardViewFirst').off('click');
    }
  }

  listViewSetPage(pageNumber: number, recordsPerPage: number) {
    // get pager object from service
    this.listViewCurrentPageNumber = pageNumber;
    const inputDrpValue = $('#drprecordsPerPage option:selected').text();
    if (inputDrpValue === '') {
      this.listViewnoOfrecordsPerPage = recordsPerPage;
    } else {
      this.listViewnoOfrecordsPerPage = Number(inputDrpValue);
    }
    this.InsertUpdateUserPreferenceData();
    if (this.listViewCurrentPageNumber != 0) {

      this.getMyClientsListView(this.clientListViewFilterModel, this.listViewCurrentPageNumber,
        this.listViewnoOfrecordsPerPage, this.orderByColumn, this.orderByType);
      this.pager = this.pagerService.getPager(this.clientsCount, this.listViewCurrentPageNumber, this.listViewnoOfrecordsPerPage);

      console.log('pager ==>', this.pager);
    } else {
      $('#previous').off('click');
      $('#first').off('click');
    }
  }

  getMyClientsCardView(clientCardViewFilterModel: ClientCardViewFilterModel,
    pageNumber: number, listViewnoOfrecordsPerPage, orderBy, orderType) {
    this.loadingService.startLoading();
    this._myclientslist.getMyClientsCardView(clientCardViewFilterModel, pageNumber,
      listViewnoOfrecordsPerPage, this.isloggedInUserAdmin, orderBy,
      orderType).subscribe(
        result => {
          this.myClientsCardList = result;
          this.loadingService.stopLoading();
          this.cardCount = this.myClientsCardList.length;
          if (this.cardCount === 0) {
            this.DataMessage = ' No Data Available';
            this.loadingService.stopLoading();
          } else if (this.cardCount === null) {
            this.DataMessage = 'Loading...';
          } else { this.DataMessage = ''; }

          this.applyFilterOnceAfterDataLoad = true;
        },
        err => console.log('Error in loading the client list information.'));
  }

  getMyClientsListView(clientListViewFilterModel: ClientListViewFilterModel,
    pageNumber: number, listViewnoOfrecordsPerPage, orderBy, orderType) {
    this.loadingService.startLoading();
    this._myclientslist.getMyClientsListView(clientListViewFilterModel, pageNumber,
      listViewnoOfrecordsPerPage, this.isloggedInUserAdmin, orderBy,
      orderType).subscribe(
        result => {
          this.myClientslistViewList = result;
          this.loadingService.stopLoading();
          this.listCount = this.myClientslistViewList.length;
          if (this.listCount === 0) {
            this.listDataMessage = ' No Data Available';
          } else if (this.listCount === null) {
            this.DataMessage = 'Loading...';
          } else { this.listDataMessage = ''; }

          this.applyFilterOnceAfterDataLoad = true;
          this.setInitialsForListView();
        },
        err => console.log('Error in loading the client list information.'));
  }
  setInitialsForListView() {
    if (this.listCount === 0) {
      this.headerModelList[0].imageModelList[0].isVisible = true;
      this.headerModelList[0].imageModelList[1].isVisible = false;
      this.headerModelList[0].imageModelList[2].isVisible = false;
    }
  }
  HeaderSort(headerid: number) {
    this.clientListViewFilterModel.sortClientId = false;
    this.clientListViewFilterModel.sortProgramId = false;
    this.clientListViewFilterModel.sortClientStatusId = false;
    this.clientListViewFilterModel.sortLastActivityDate = false;
    for (let i = 0; i < this.headerModelList.length; i++) {
      if (this.headerModelList[i].headerId === headerid) {
        this.headerModelList[i].headerClick = true;
        if ((this.headerModelList[i].imageModelList[0].isVisible) || (this.headerModelList[i].imageModelList[2].isVisible)) {
          this.headerModelList[i].imageModelList[0].isVisible = false;
          this.headerModelList[i].imageModelList[1].isVisible = true;
          this.headerModelList[i].imageModelList[2].isVisible = false;
        } else if (this.headerModelList[i].imageModelList[1].isVisible) {
          this.headerModelList[i].imageModelList[0].isVisible = false;
          this.headerModelList[i].imageModelList[1].isVisible = false;
          this.headerModelList[i].imageModelList[2].isVisible = true;
        }
      } else {
        this.headerModelList[i].headerClick = false;
        for (let j = 0; j < this.headerModelList[i].imageModelList.length; j++) {
          this.headerModelList[i].imageModelList[0].isVisible = true;
          this.headerModelList[i].imageModelList[1].isVisible = false;
          this.headerModelList[i].imageModelList[2].isVisible = false;
        }
      }
    }

    let headerObj = this.headerModelList.find(c => c.headerClick === true);
    this.orderByColumn = headerObj.headerValue;
    for (let k = 0; k < headerObj.imageModelList.length; k++) {
      if (headerObj.imageModelList[k].isVisible === true) {
        this.orderByType = headerObj.imageModelList[k].imageValue;
        break;
      }
    }
    this.InsertUpdateUserPreferenceData();
    this.listViewSetPage(1, this.listViewnoOfrecordsPerPage);
  }

  LoadListViewHeaders() {
    this.headerModelList = [];
    this.headerImageModelList = [];  
    this.newImageModel = {
      imageName: 'DEFAULT',
      imageValue: 'ASC',
      imagePath: '../../../../assets/images/EDP/Engagement/unfold_more-24px.svg',
      isVisible: true
    };
    this.headerImageModelList.push(JSON.parse(JSON.stringify(this.newImageModel)));

    this.newImageModel = {
      imageName: 'Asending',
      imageValue: 'ASC',
      imagePath: '../../../../assets/images/EDP/Engagement/asc_order.png',
      isVisible: false
    };
    this.headerImageModelList.push(JSON.parse(JSON.stringify(this.newImageModel)));

    this.newImageModel = {
      imageName: 'Desending',
      imageValue: 'DESC',
      imagePath: '../../../../assets/images/EDP/Engagement/desc_order.png',
      isVisible: false
    };
    this.headerImageModelList.push(JSON.parse(JSON.stringify(this.newImageModel)));

    this.headerModel = {
      headerId: 1,
      headerName: 'Client name',
      headerValue: 'clientName',
      headerClick: true,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'clientName'){
      if(this.orderByType == 'ASC'){
      this.headerModelList[0].imageModelList[0].isVisible = false;
      this.headerModelList[0].imageModelList[1].isVisible = true;
      this.headerModelList[0].imageModelList[2].isVisible = false;
      }
      if(this.orderByType == 'DESC'){
        this.headerModelList[0].imageModelList[0].isVisible = false;
        this.headerModelList[0].imageModelList[1].isVisible = false;
        this.headerModelList[0].imageModelList[2].isVisible = true;
        }
      }

    this.headerModel = {
      headerId: 2,
      headerName: 'Client ID',
      headerValue: 'clientCode',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'clientCode'){
      if(this.orderByType == 'ASC'){
        this.headerModelList[1].imageModelList[0].isVisible = false;
        this.headerModelList[1].imageModelList[1].isVisible = true;
        this.headerModelList[1].imageModelList[2].isVisible = false;
        }
        if(this.orderByType == 'DESC'){
          this.headerModelList[1].imageModelList[0].isVisible = false;
          this.headerModelList[1].imageModelList[1].isVisible = false;
          this.headerModelList[1].imageModelList[2].isVisible = true;
          }
      }

    this.headerModel = {
      headerId: 3,
      headerName: 'Type',
      headerValue: 'publicPrivate',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'publicPrivate'){
      if(this.orderByType == 'ASC'){
        this.headerModelList[2].imageModelList[0].isVisible = false;
        this.headerModelList[2].imageModelList[1].isVisible = true;
        this.headerModelList[2].imageModelList[2].isVisible = false;
        }
        if(this.orderByType == 'DESC'){
          this.headerModelList[2].imageModelList[0].isVisible = false;
          this.headerModelList[2].imageModelList[1].isVisible = false;
          this.headerModelList[2].imageModelList[2].isVisible = true;
          }
      }

    this.headerModel = {
      headerId: 4,
      headerName: 'Program',
      headerValue: 'clientProgram',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'clientProgram'){
      if(this.orderByType == 'ASC'){
        this.headerModelList[3].imageModelList[0].isVisible = false;
        this.headerModelList[3].imageModelList[1].isVisible = true;
        this.headerModelList[3].imageModelList[2].isVisible = false;
        }
        if(this.orderByType == 'DESC'){
          this.headerModelList[3].imageModelList[0].isVisible = false;
          this.headerModelList[3].imageModelList[1].isVisible = false;
          this.headerModelList[3].imageModelList[2].isVisible = true;
          }
      }

    this.headerModel = {
      headerId: 5,
      headerName: 'Engagements',
      headerValue: 'activeEngagementCount',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn ==='activeEngagementCount'){
      if(this.orderByType == 'ASC'){
        this.headerModelList[4].imageModelList[0].isVisible = false;
        this.headerModelList[4].imageModelList[1].isVisible = true;
        this.headerModelList[4].imageModelList[2].isVisible = false;
        }
      if(this.orderByType == 'DESC'){
          this.headerModelList[4].imageModelList[0].isVisible = false;
          this.headerModelList[4].imageModelList[1].isVisible = false;
          this.headerModelList[4].imageModelList[2].isVisible = true;
        }
      }

    this.headerModel = {
      headerId: 6,
      headerName: 'Service lines',
      headerValue: 'serviceLineList',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'serviceLineList'){
    if(this.orderByType == 'ASC'){
      this.headerModelList[5].imageModelList[0].isVisible = false;
      this.headerModelList[5].imageModelList[1].isVisible = true;
      this.headerModelList[5].imageModelList[2].isVisible = false;
      }
      if(this.orderByType == 'DESC'){
        this.headerModelList[5].imageModelList[0].isVisible = false;
        this.headerModelList[5].imageModelList[1].isVisible = false;
        this.headerModelList[5].imageModelList[2].isVisible = true;
        }
      }

    this.headerModel = {
      headerId: 7,
      headerName: 'Last activity',
      headerValue: 'LastActivityDate',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'LastActivityDate'){
      if(this.orderByType == 'ASC'){
        this.headerModelList[6].imageModelList[0].isVisible = false;
        this.headerModelList[6].imageModelList[1].isVisible = true;
        this.headerModelList[6].imageModelList[2].isVisible = false;
        }
        if(this.orderByType == 'DESC'){
          this.headerModelList[6].imageModelList[0].isVisible = false;
          this.headerModelList[6].imageModelList[1].isVisible = false;
          this.headerModelList[6].imageModelList[2].isVisible = true;
          }
    }

    this.headerModel = {
      headerId: 8,
      headerName: 'Status',
      headerValue: 'ClientStatus',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'Status'){
      if(this.orderByType == 'ASC'){
        this.headerModelList[7].imageModelList[0].isVisible = false;
        this.headerModelList[7].imageModelList[1].isVisible = true;
        this.headerModelList[7].imageModelList[2].isVisible = false;
        }
        if(this.orderByType == 'DESC'){
          this.headerModelList[7].imageModelList[0].isVisible = false;
          this.headerModelList[7].imageModelList[1].isVisible = false;
          this.headerModelList[7].imageModelList[2].isVisible = true;
          }
      }

    this.headerModel = {
      headerId: 9,
      headerName: 'Actions',
      headerValue: '',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
   //  console.log('headerModelList ==>', this.headerModelList);
  }

  ToggleListAndCardView(SelectedView: string,) {
    $("#showAllCardView").removeClass("active");
    $("#showAllListView").removeClass("active");
    switch (SelectedView) {
      case 'Card':
        this.dashBoardViewtypeName = "Card";
        if(!this.isListViewApplyResetButtonClicked){
        $("#filterListView").removeClass("active");
        $("#filterListView").removeClass("partialActive");
          this.clientListViewFilterModel = JSON.parse(this.clientUserPreference.listViewFilterList);
        }
        this.InsertUpdateUserPreferenceData();
        //this.cardViewSetPage(this.cardViewCurrentPageNumber, this.cardViewnoOfrecordsPerPage);
        this.getMyAllClientsByUserCount('Toggle');
        this.SetDefaultCardView();
        if(this.clientCardViewFilterModel.clientId.length > 0 || this.clientCardViewFilterModel.clientStatusId.length > 0 ||
          this.clientCardViewFilterModel.industryTypeId.length > 0 || this.clientCardViewFilterModel.programId.length > 0 || this.clientCardViewFilterModel.structEntityId.length > 0){
           $("#filterCardView").addClass("partialActive");
          }
          if(this.clientCardViewFilterModel.sortClientId || this.clientCardViewFilterModel.sortClientStatusId ||
            this.clientCardViewFilterModel.sortLastActivityDate || this.clientCardViewFilterModel.sortProgramId){
              $("#sortCardView").addClass("partialActive");
            }
        $('#listViewFilterSection').hide();
        break;
      case 'List':
        this.dashBoardViewtypeName = "List";
        if(!this.isCardViewApplyResetButtonClicked){
        $("#filterCardView").removeClass("active");
        $("#filterCardView").removeClass("partialActive");
          this.clientCardViewFilterModel = JSON.parse(this.clientUserPreference.cardViewFilterList);
        }
        if(!this.isCardViewSortApplyResetButtonClicked)
        $("#sortCardView").removeClass("active");
        this.clientCardViewFilterModel = JSON.parse(this.clientUserPreference.cardViewFilterList);
        this.sortOrderByType = JSON.parse(this.clientUserPreference.cardViewSortingList).orderByType;
        if(this.sortOrderByType == undefined || this.sortOrderByType == null){
          this.sortOrderByType = 'DESC';
        }
        this.InsertUpdateUserPreferenceData();
        this.getMyAllClientsListByUserCount();
        if(this.clientListViewFilterModel.clientId.length > 0 || this.clientListViewFilterModel.clientStatusId.length > 0 ||
          this.clientListViewFilterModel.industryTypeId.length > 0 || this.clientListViewFilterModel.programId.length > 0 || this.clientListViewFilterModel.structEntityId.length > 0){
            $("#filterListView").addClass("partialActive");
          }
        $('.Eng-ListView-Div').show();
        $('.Eng-CardView-Div').hide();
        $('#cardViewFilterSection').hide();
        $('#cardViewSortSection').hide();
        break;
      default:
        this.SetDefaultCardView();
        break;
    }
  }
  SetDefaultCardView() {
    $('.cardViewContainer').show();
    $('.Eng-ListView-Div').hide();
    $('.Eng-CardView-Div').show();
  }

  setDefaultInitialization() {
    this.applyFilterOnceAfterDataLoad = false;
    this.isloggedInUserAdmin = 'no';
    this.orderByColumn = 'LastActivityDate';
    this.orderByType = 'DESC';
    if(this.userPreferenceData.length > 0 && this.clientUserPreference !=null){
      this.orderByColumn =  JSON.parse(this.clientUserPreference.listViewSortingList).orderByColumn;
      if(this.orderByColumn == undefined || this.orderByColumn == null){
        this.orderByColumn = 'LastActivityDate';
      }
      this.orderByType = JSON.parse(this.clientUserPreference.listViewSortingList).orderByType;
      if(this.orderByType == undefined || this.orderByType == null){
        this.orderByType = 'LastActivityDate';
      }
      }
    this.cardVieworderByColumn = 'LastActivityDate';
    this.cardVieworderByType = 'DESC';
  }
  InsertUpdateUserPreferenceData(){    
    var  cardViewFilterList : any;
    var  cardViewSortList : any;
    var  listViewFilterList : any;
    var  listViewSortList : any;
    if(this.userPreferenceData.length > 0  && this.clientUserPreference != null){
    var userPrefData : UserPreferenceData;
    
    this.clientUserPreference.userEmail = this.userObject.loggedInUserEmail;
    this.clientUserPreference.dashBoardName = 'MyClients';
    this.clientUserPreference.dashboardViewType = this.dashBoardViewtypeName;
     cardViewFilterList = this.clientCardViewFilterModel;
    cardViewSortList = this.clientCardViewFilterModel;
    listViewFilterList = this.clientListViewFilterModel;
    listViewSortList = this.clientListViewFilterModel;
    cardViewSortList.orderByType = $('#ddListOrderByType').val();

    listViewSortList.orderByColumn = this.orderByColumn;
    listViewSortList.orderByType = this.orderByType;
    this.clientUserPreference.cardViewFilterList = JSON.stringify(cardViewFilterList);
    this.clientUserPreference.cardViewSortingList = JSON.stringify(cardViewSortList);
    this.clientUserPreference.cardViewPageNumber = this.cardViewnoOfrecordsPerPage;
    this.clientUserPreference.listViewFilterList = JSON.stringify(listViewFilterList);
    this.clientUserPreference.listViewSortingList = JSON.stringify(listViewSortList);
    this.clientUserPreference.listViewPageNumber = this.listViewnoOfrecordsPerPage;
    userPrefData = this.clientUserPreference;        
    let index = this.userPreferenceData.findIndex(obj => obj.dashBoardName === "MyClients");
    this.userPreferenceData[index] = userPrefData;

    localStorage.setItem('userpreferencelist', JSON.stringify(this.userPreferenceData));


    this.engService.InsertUpdateUserPreferenceData(userPrefData).subscribe(result =>{
         // console.log(result);
    });
  }
  if((this.userPreferenceData.length == 0 || this.userPreferenceData.length > 0) && this.clientUserPreference == null ){
    var datatoinsert = new UserPreferenceData();
    cardViewFilterList = this.clientCardViewFilterModel;
    cardViewSortList = this.clientCardViewFilterModel;
    listViewFilterList = this.clientListViewFilterModel;
    listViewSortList = this.clientListViewFilterModel;
    cardViewSortList.orderByType = $('#ddListOrderByType').val();

    listViewSortList.orderByColumn = this.orderByColumn;
    listViewSortList.orderByType = this.orderByType;
    datatoinsert.userEmail = this.userObject.loggedInUserEmail;
    datatoinsert.dashBoardName = 'MyClients';
    datatoinsert.dashboardViewType = this.dashBoardViewtypeName;
    datatoinsert.cardViewPageNumber = this.cardViewnoOfrecordsPerPage;
    datatoinsert.listViewPageNumber = this.listViewnoOfrecordsPerPage;
    datatoinsert.cardViewFilterList = JSON.stringify(cardViewFilterList);
    datatoinsert.cardViewSortingList = JSON.stringify(cardViewSortList);
    datatoinsert.listViewFilterList = JSON.stringify(listViewFilterList);
    datatoinsert.listViewSortingList = JSON.stringify(listViewSortList);
    this.userPreferenceData.push(datatoinsert);
    localStorage.setItem('userpreferencelist', JSON.stringify(this.userPreferenceData));


    this.engService.InsertUpdateUserPreferenceData(datatoinsert).subscribe(result =>{
          console.log(result);
    });
  }
  }

  ngOnDestroy() {

  }

  
  
  ResetCardViewFilterFields() {
    this.clientCardViewFilterModel = new ClientCardViewFilterModel([], [], [], [], [], false, false, false, true, this.userObject.userName);
    this.cardViewCurrentPageNumber = 1;
    $('#txtValueClientCodeCardMappedValue0').val("");
    $('#ddClientsForCardClientsList').val(0);
    $('input:hidden').val('');
    $('#ddClientIndustryTypeCardList').val(0);
    $('#ddClientStructEntityCardList').val(0);
    $('#ddClientProgramCardList').val(0);
    $('#ddClientStatusCardList').val(0);  
  }

  
  ResetListViewFilterFields() {
    this.clientListViewFilterModel = new ClientListViewFilterModel([], [], [], [], [], false, false, false, true, this.userObject.userName);
    this.listViewCurrentPageNumber = 1;
    $('#txtValueClientCodeListMappedValue0').val("");
    $('#ddClientsForClientsList').val(0);
    $('input:hidden').val('');
    $('#ddClientIndustryTypeList').val(0);
    $('#ddClientStructEntityList').val(0);
    $('#ddClientProgramList').val(0);
    $('#ddClientStatusList').val(0);    
  }  

  ResetCardViewSortFields() {    
    this.clientCardViewFilterModel.sortClientId = false;
    this.clientCardViewFilterModel.sortProgramId = false;
    this.clientCardViewFilterModel.sortClientStatusId = false;
    this.clientCardViewFilterModel.sortLastActivityDate = true;    
    $('#chkClientName').prop('checked', false);
    $('#chkClientProgram').prop('checked', false);
    $('#chkClientStatus').prop('checked', false);
    $('#chkLastActivityDate').prop('checked', true);
    $('#ddListOrderByType').val('DESC');    
  }

  setDefaultInitializationForShowAll() {
    $('#ddListOrderByType').val('DESC');
    this.orderByColumn = 'LastActivityDate';
    this.sortOrderByType = 'DESC';
    this.cardVieworderByColumn = 'LastActivityDate';
    this.cardVieworderByType = 'DESC';
    this.listViewCurrentPageNumber = 1;
    this.listViewnoOfrecordsPerPage = this.clientUserPreference.listViewPageNumber;
  }

  ResetDefaultInitialization() {
    $('#ddListOrderByType').val('DESC');
    this.orderByColumn = 'LastActivityDate';
    this.sortOrderByType = 'DESC';
    this.cardVieworderByColumn = 'LastActivityDate';
    this.cardVieworderByType = 'DESC';                    
    this.cardViewCurrentPageNumber = 1;  
  }
  // onItemSelect(item: any) {
  //   console.log(item);
  // }
  // onSelectAll(items: any) {
  //   console.log(items);
  // }


  onItemValueChange(item:any){

    if(item != null && item != undefined){
      if(item.source == "clientName" && item.value != null){
        this.clientIds = item.value;
      }
      if(item.source == "industry"){
        this.industryIds= item.value;
      }
      if(item.source == "clientStatus"){
        this.clientStatusIds = item.value;
      }
      if(item.source == "clientProgram"){
        this.clientProgramIds = item.value;
      }
      if(item.source == "structEntity"){
        this.structEntityIds = item.value;
      }
    }
  }




  onChange(item: any){
    
  }

  assignFilterValuestoModal(){
    this.sharedService.subject.next('assign');
    this.resetFilterValuestoModal();
    this.assignFilterValuestoDataModal();
   
  }



  resetFilterValuestoModal(){
    if (this.dashBoardViewtypeName?.toLocaleLowerCase()  == "card"){
    this.clientCardViewFilterModel.clientId =[];
    this.clientCardViewFilterModel.programId =[];
    this.clientCardViewFilterModel.clientStatusId =[];
    this.clientCardViewFilterModel.industryTypeId =[];
    this.clientCardViewFilterModel.structEntityId =[];
    }else{
      this.clientListViewFilterModel.clientId =[];
    this.clientListViewFilterModel.programId =[];
    this.clientListViewFilterModel.clientStatusId =[];
    this.clientListViewFilterModel.industryTypeId =[];
    this.clientListViewFilterModel.structEntityId =[];
      
    }
  }

  assignFilterValuestoDataModal(){
    if (this.dashBoardViewtypeName?.toLocaleLowerCase()  == "card"){
    this.clientCardViewFilterModel.clientId =this.clientIds;
    this.clientCardViewFilterModel.programId = this.clientProgramIds;
    this.clientCardViewFilterModel.clientStatusId = this.clientStatusIds;
    this.clientCardViewFilterModel.industryTypeId = this.industryIds;
    this.clientCardViewFilterModel.structEntityId = this.structEntityIds;
    }else{
    this.clientListViewFilterModel.clientId = this.clientIds;
  this.clientListViewFilterModel.programId = this.clientProgramIds;
    this.clientListViewFilterModel.clientStatusId = this.clientStatusIds;
    this.clientListViewFilterModel.industryTypeId = this.industryIds;
    this.clientListViewFilterModel.structEntityId = this.structEntityIds;
    }
  }

  removeExtraValue( item: any){
    if (item != null && item.length > 0){
      if (item[0] == '-1'){
      item.shift();
    }
  }
    return item;
  }

  addExtaValueAll(source: string,item: any){
    if (item != null && item.length > 0){
      if (item[0] != '-1'){
        var keyValueList = new KeyValue();
        if (source == "industry"){
         var itemValue = {
          "industryTypeId": -1,
          "industryTypeName": 'All'
         } 
         item.unshift(itemValue);

        }

        if (source == "structEntity"){
          var itemStructValue = {
            "structEntityId": -1,
            "structEntityName": 'All'
           } 
           item.unshift(itemStructValue);
        }
        
        if (source == "clientProgram"){
          var itemProgramValue = {
            "clientProgramId": -1,
            "clientProgramName": 'All'
           } 
           item.unshift(itemProgramValue);
        }
        
        if (source == "clientStatus"){
          var itemStatusValue = {
            "clientStatusId": -1,
            "clientStatusName": 'All'
           } 
           item.unshift(itemStatusValue);
        }
        
      }
    
  }
  return item;
}

resetFilterValuesCheckBox(){
  this.clientIds =[];
  this.industryIds =[];
  this.clientStatusIds =[];
  this.clientProgramIds= [];
  this.structEntityIds= [];
}
}