
  <p-multiSelect 
    [options]="clientFilterModel" 
    [(ngModel)]="selectedValue" 
    optionLabel= {{optionValue}} 
    optionValue={{optionLable}} 
    placeholder={{optionHeader}}
    (onChange)="onItemChange($event)"
    autoWidth="false"
    [showClear]="true"
    [maxSelectedLabels]="1"
    resetFilterOnHide=true
    [style]="{ minWidth: '100px', width: '100%' }"
    [showToggleAll]="false"
    >
  
   
  </p-multiSelect>

  